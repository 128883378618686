import React, { useState, useEffect, useContext, Fragment } from "react";
import { AiFillHeart } from "react-icons/ai";
import axios from "axios";
import classes from "./LikeButton.module.scss";
import { LanguageContext } from "../../../Store/LanguageStore";
import handleError from "../../../lib/handleError";

const LikeButton = ({ id }) => {
  const [liked, setliked] = useState(false);
  const [language] = useContext(LanguageContext);

  const like = () => {
    if (liked) submitUnlike();
    else submitLike();
  };

  const submitLike = async () => {
    try {
      await axios.post("/user/routines/like", { routine_id: id });
      setliked(true);
    } catch (e) {
      if (!axios.isCancel(e)) {
        handleError(e, true);
      }
    }
  };

  const submitUnlike = async () => {
    try {
      await axios.post("/user/routines/unlike", { routine_id: id });
      setliked(false);
    } catch (e) {
      if (axios.isCancel(e)) {
        handleError(e, true);
      }
    }
  };

  let source;

  useEffect(() => {
    source = axios.CancelToken.source();

    const getLiked = async () => {
      try {
        let result = await axios.post(
          "/user/routines/isLiked",
          {
            routine_id: id,
          },
          { cancelToken: source.token }
        );
        setliked(result.data.liked);
      } catch (e) {
        if (!axios.isCancel(e)) {
          handleError(e);
        }
      }
    };

    getLiked();

    return () => {
      source.cancel();
    };
  }, []);

  return (
    <Fragment>
      {liked && (
        <span className={classes.likeButtonOn}>
          <span className={classes.inner}>
            <AiFillHeart className={classes.icon} />
            {language.remove_from_favourites}
          </span>
          <button className={classes.removeButton} onClick={like}>
            x
          </button>
        </span>
      )}

      {!liked && (
        <button className={classes.likeButtonOff} onClick={like}>
          <span className={classes.inner}>
            <AiFillHeart className={classes.icon} />
            {language.add_to_favourites}
          </span>
        </button>
      )}
    </Fragment>
  );
};

export default LikeButton;
