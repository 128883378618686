import React, { useContext } from "react";
import TextQuestion from "../../../Components/Questions/TextQuestion/TextQuestion";
import { LanguageContext } from "../../../Store/LanguageStore";
import PreviousAnswer from "../../../Components/PreviousAnswer/PreviousAnswer";

const WhoAmI = ({ domain, statment, submitAnswer, initialValue }) => {
  const [language] = useContext(LanguageContext);
  return (
    <div>
      <PreviousAnswer title={domain} content={statment} no_answer_string={""} />
      <TextQuestion
        initialValue={initialValue}
        question={language.steppingstone8_whoiam_1}
        hint={language.steppingstone8_whoiam_1_hint}
        submitAnswer={submitAnswer}
        delay={1000}
      />
    </div>
  );
};

export default WhoAmI;
