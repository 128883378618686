import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Activity3_intro from "./Activity3_intro";
import Activity3_main_1 from "./Activity3_main_1";
import Activity3_main_2 from "./Activity3_main_2";
import ActivityAssessment from "../../../Pages/ActivityAssessment/ActivityAssessment";
import ActivityWrapper from "../ActivityWrapper";

import { LanguageContext } from "../../../Store/LanguageStore";
import Heading from "../../../Components/SanatizedComponents/Heading";
import Paragraph from "../../../Components/SanatizedComponents/Paragraph";
import classes from "../Activity.module.scss";
import IconLighthouse from "../../../Components/icons/Lighthouse";
import WHO5 from "../../../Components/WHO5/WHO5";
import WHO5Display from "../../../Components/WHO5/WHO5Display";

const Activity3 = (props) => {
  const [language] = useContext(LanguageContext);
  const task_id = 3;
  const { attempt } = props;
  const pages = [
    {
      link: "/who",
      component: () => <WHO5 task_id={task_id} attempt={attempt} />,
    },
    {
      link: "/who/display",
      component: () => <WHO5Display task_id={task_id} attempt={attempt} />,
    },
    { link: "/introduction", component: () => <Activity3_intro /> },
    {
      link: "/activity/1",
      component: () => <Activity3_main_1 task_id={task_id} attempt={attempt} />,
    },
    {
      link: "/activity/2",
      component: () => <Activity3_main_2 task_id={task_id} attempt={attempt} />,
    },
    {
      link: "/activityassessment",
      component: () => (
        <ActivityAssessment task_id={task_id} attempt={attempt} />
      ),
    },
  ];

  return (
    <div>
      <div className={classes.headerWrapper}>
        <header className={classes.header}>
          <IconLighthouse base className={classes.icon} />
          <div>
            <Heading text={language.steppingstone3_title} />
            <Paragraph text={language.steppingstone3_aim} />
          </div>
        </header>
      </div>
      <nav className={classes.breadcrumbs}>
        <Link to="/user/map">{language.map_menu}</Link>
        {" > "}
        <span dangerouslySetInnerHTML={{__html: language.steppingstone3_title}}></span>
      </nav>
      <ActivityWrapper {...props} pages={pages} task_id={task_id} />
    </div>
  );
};

export default Activity3;
