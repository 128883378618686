import React, {useEffect, useState, useContext} from 'react';

import {ApplicationStatusContext} from '../../../Store/ApplicationStatusStore';

import axios from "axios"
import handleError from '../../../lib/handleError';
import Heading from '../../../Components/SanatizedComponents/Heading';

const ApplicationStateManager = () => {

    const [application,getApplicationStatus] = useContext(ApplicationStatusContext);

    const studyClick = async (e) => {
        try{
            console.log(!application.studyRunning)
            await axios.post('/admin/studyStatus/set',{status: !application.studyRunning})
            await getApplicationStatus();
        }
        catch(e){
            handleError(e, true);
        }
    }

    const appClick = async (e) => {
        try{
            await axios.post('/admin/applicationstatus/set',{status: !application.applicationRunning})
            await getApplicationStatus();
        }
        catch(e){
           handleError(e, true);
        }
    }



    return (
        <div>
            <Heading text="Application Status Settings"/>
            application_running: <input type="checkbox" checked={application.applicationRunning} onClick={appClick}></input>
            <br/>
            study_running: <input type="checkbox" checked={application.studyRunning} onClick={studyClick}></input>
        </div>
    );
}

export default ApplicationStateManager;
