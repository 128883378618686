import React, { useContext } from "react";
import { Link } from "react-router-dom";

import Activity10_intro from "./Activity10_intro";
import Activity10_main_1 from "./Activity10_main_1";
import Activity10_main_2 from "./Activity10_main_2";
import Activity10_main_3 from "./Activity10_main_3";
import Activity10_main_4 from "./Activity10_main_4";
import WHO5 from "../../../Components/WHO5/WHO5";

import ActivityAssessment from "../../../Pages/ActivityAssessment/ActivityAssessment";
import ActivityWrapper from "../ActivityWrapper";
import Activity10_summary from "./Activity10_summary";
import classes from "../Activity.module.scss";

import Heading from "../../../Components/SanatizedComponents/Heading";
import Paragraph from "../../../Components/SanatizedComponents/Paragraph";
import { LanguageContext } from "../../../Store/LanguageStore";
import IconBinoculars from "../../../Components/icons/Binoculars";
import WHO5Display from "../../../Components/WHO5/WHO5Display";

const Activity10 = (props) => {
  const [language] = useContext(LanguageContext);
  const task_id = 10;
  const { attempt } = props;

  const pages = [
    { link: "/introduction", component: () => <Activity10_intro /> },
    {
      link: "/who5",
      component: () => <WHO5 task_id={task_id} attempt={attempt} />,
    },
    {
      link: "/who/display",
      component: () => <WHO5Display task_id={task_id} attempt={attempt} />,
    },
    {
      link: "/activity/1",
      component: () => (
        <Activity10_main_1 task_id={task_id} attempt={attempt} />
      ),
    },
    {
      link: "/activity/2",
      component: () => (
        <Activity10_main_2 task_id={task_id} attempt={attempt} />
      ),
    },
    {
      link: "/activity/3",
      component: () => (
        <Activity10_main_3 task_id={task_id} attempt={attempt} />
      ),
    },
    {
      link: "/activity/4",
      component: () => (
        <Activity10_main_4 task_id={task_id} attempt={attempt} />
      ),
    },
    {
      link: "/summary",
      component: () => (
        <Activity10_summary task_id={task_id} attempt={attempt} />
      ),
    },
    {
      link: "/activityassessment",
      component: () => (
        <ActivityAssessment task_id={task_id} attempt={attempt} />
      ),
    },
  ];

  return (
    <div>
      <div className={classes.headerWrapper}>
        <header className={classes.header}>
          <IconBinoculars base className={classes.icon} />
          <div>
            <Heading text={language.steppingstone10_title} />
            <Paragraph text={language.steppingstone10_aim} />
          </div>
        </header>
      </div>
      <nav className={classes.breadcrumbs}>
        <Link to="/user/map">{language.map_menu}</Link>
        {" > "}
        <span dangerouslySetInnerHTML={{__html: language.steppingstone10_title}}></span>
      </nav>
      <ActivityWrapper {...props} pages={pages} task_id={task_id} />
    </div>
  );
};

export default Activity10;
