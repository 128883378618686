import React from "react";
import DraggableList from "../../../Components/Questions/ValueStatements/DraggableList";
import DraggableItemWrapper from "../../../Components/Questions/ValueStatements/DraggableItemWrapper";
import StatementCard from "./StatementCard";

const ValueStatementDraggableList = ({
  valueStatements,
  submitRankAnswer,
  deleteCard,
  updateCard,
}) => {
  let cards = [];

  valueStatements.forEach((statment, index) => {
    cards.push(
      <DraggableItemWrapper
        id={index}
        key={statment.id}
        index={index}
        key={index}
      >
        <StatementCard
          answer={statment.answer_text}
          index={statment.rank}
          area={statment.area}
          onDelete={() => deleteCard(statment.id)}
          updateCard={(answer, area) => updateCard(statment.id, answer, area)}
        />
      </DraggableItemWrapper>
    );
  });

  const onDragEnd = (result) => {
    try {
      let index = result.draggableId;
      if (result.draggableId === result.destination.index) {
        return;
      }
      const dragCard = valueStatements[index];
      let _cards = [...valueStatements];
      _cards.splice(index, 1);
      _cards.splice(result.destination.index, 0, dragCard);
      submitRankAnswer(_cards);
    } catch (e) {
      console.error(e);
    }
  };

  const onDragUpdate = (e) => {};

  return (
    <DraggableList
      cards={cards}
      submitRankAnswer={submitRankAnswer}
      onDragEnd={onDragEnd}
      onDragUpdate={onDragUpdate}
    />
  );
};

export default ValueStatementDraggableList;
