import React, {useRef, useState} from 'react';
import Heading from '../../../../Components/SanatizedComponents/Heading';
import TextQuestion from '../../../../Components/Questions/TextQuestion/TextQuestion';
import TextField from '../../../../Components/Forms/TextField';
import Axios from 'axios';
import handleError from '../../../../lib/handleError';
import SearchResults from './SearchResults';


const UserSearch = () => {

    const inputRef = useRef()
    const [result, setresult] = useState(undefined);


    const search = async () => {

        try{
            let _result = await Axios.post('/admin/user/search',{search_query: inputRef.current.value});
            setresult(_result.data);
        }catch(e){
            handleError(e, true);
        }

    }

    return (
        <div>
            <Heading text="User Search"/>
            <TextField 
                label="Enter the user email or qualtrics id"
                ref={inputRef}
            />
            <button  onClick={search}>
                Search
            </button>
            <SearchResults result={result}/>
            
        </div>
    );
}

export default UserSearch;
