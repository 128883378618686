import React, { Fragment } from 'react';

const SearchResults = ({result}) => {

    console.log(result)

    if(!result){
        return <Fragment/>
    }else{
        return (
            <div>
                {
                    Object.keys(result).map((key, index)=>{
                        if(["created_at","last_modified"].includes(key)){
                            return <Fragment><div key={index}>{key} : {new Date(result[key] * 1000).toString()}</div></Fragment>
                        }else if(["task"].includes(key)){
                            return <Fragment><div key={index}>{key} : {result[key] ? result[key] : "unstarted"}</div></Fragment>
                        }
                        else{
                            return <Fragment><div key={index}>{key} : {result[key] ? result[key] : "not set"}</div></Fragment>
                        }
                    })
                }
            </div>
        );
    }

}

export default SearchResults;
