import React, { Fragment } from "react";
import RenderFromKeys from "../../Components/RenderFromKeys/RenderFromKeys";
import { useContext } from "react";
import { LanguageContext } from "../../Store/LanguageStore";
import classes from "./TermsOfUse.module.scss";
import Footer from "./Footer/Footer";

const TermsOfUser = () => {
  const [language] = useContext(LanguageContext);

  return (
    <Fragment>
      <div className={classes.content}>
        <RenderFromKeys content={language.TermsOfUse} />
      </div>
      <div className={classes.footerWrapper}>
        <Footer />
      </div>
    </Fragment>
  );
};

export default TermsOfUser;
