import React, { useState, useCallback } from "react";

import { DragDropContext, Droppable } from "react-beautiful-dnd";
import classes from "./DraggableList.module.scss";

const DraggableList = ({ cards = [], onDragEnd, onDragUpdate }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
      <Droppable droppableId="0">
        {(provided) => (
          <ol
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={classes.list}
          >
            {cards.map((card, index) => {
              return card;
            })}
            {provided.placeholder}
          </ol>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
