import React from "react";

const Bridge = ({ className, base, baseColor = "#ddf5f5" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      {base && <circle cx="54" cy="54" r="45" fill={baseColor} />}

      <path d="M54,35C18,35,9,72,9,72H99S90,35,54,35Z" fill="#80918b" />
      <path d="M99,72S90,99,54,99,9,72,9,72l45-9Z" fill="#bfdcc1" />
      <path
        d="M99,71.81S81,63,54,63,9,72,9,72"
        fill="#bfdcc1"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />

      <path
        d="M62.22,25.3,60.77,37a.48.48,0,0,1-.94-.1l.83-11.77Z"
        fill="#a18c73"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <ellipse
        cx="61.44"
        cy="25.22"
        rx="0.39"
        ry="0.79"
        transform="translate(30.39 83.93) rotate(-84.45)"
        strokeWidth="2"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#d6bb9a"
      />

      <path
        d="M45.78,25.3,47.23,37a.48.48,0,0,0,.94-.1l-.83-11.77Z"
        fill="#a18c73"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <ellipse
        cx="46.56"
        cy="25.22"
        rx="0.79"
        ry="0.39"
        transform="translate(-2.22 4.62) rotate(-5.55)"
        strokeWidth="2"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#d6bb9a"
      />

      <polygon
        points="60.71 38.51 59.78 33.91 48.13 33.91 47.25 38.51 47.25 39.51 60.71 39.51 60.71 38.51"
        fill="#a18c73"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M61.5,40.14H46.57c-.38,1-.77,2.92-1.22,3.85v2H62.74V44C62.28,43.06,61.88,41.11,61.5,40.14Z"
        fill="#a18c73"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <rect x="45.38" y="43.99" width="17.36" height="2" opacity="0.2" />
      <path
        d="M63.79,46.36H44.31A36.76,36.76,0,0,1,41.17,51v2H66.94V51A35.81,35.81,0,0,1,63.79,46.36Z"
        fill="#a18c73"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <rect x="41.17" y="51.04" width="25.77" height="2" opacity="0.2" />
      <path
        d="M74.5,61.56l-.07-2.83L70.51,55c-.66-.62-1.28-1.28-1.89-2H39.5c-.69.77-1.4,1.51-2.15,2.22l-3.68,3.47-.17,2.83Z"
        fill="#a18c73"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <rect x="33.67" y="58.73" width="40.83" height="2.83" opacity="0.2" />
      <polygon
        points="31.55 60.73 25 66.9 25 70.9 83 70.9 83 66.9 76.53 60.73 31.55 60.73"
        fill="#a18c73"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <rect x="25" y="66.9" width="58" height="4" opacity="0.2" />

      <path
        d="M86.15,17S77.41,32.69,61.54,28.06M21.85,17s8.74,15.69,24.61,11.06"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M81.8,35.2c-16.44,1-20.93-4.89-20.93-4.89M26.2,35.2c16.44,1,20.93-4.89,20.93-4.89"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />

      <path
        d="M10.28,8.11,27.71,73.35a2.5,2.5,0,0,0,3.15,1.56A2.52,2.52,0,0,0,33,72.1L19,6Z"
        fill="#a18c73"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <ellipse
        cx="14.66"
        cy="7.07"
        rx="4.5"
        ry="2.25"
        transform="translate(-1.24 3.6) rotate(-13.43)"
        strokeWidth="2"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#d6bb9a"
      />

      <path
        d="M89,6,75,72.1a2.75,2.75,0,0,0,5.26,1.25L97.72,8.11Z"
        fill="#a18c73"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <ellipse
        cx="93.34"
        cy="7.07"
        rx="2.25"
        ry="4.5"
        transform="translate(64.78 96.21) rotate(-76.57)"
        strokeWidth="2"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#d6bb9a"
      />
    </svg>
  );
};

export default Bridge;
