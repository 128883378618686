import React from "react";

const Plan = ({ className, base, baseColor = "#ddf5f5" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      {base && <circle cx="54" cy="54" r="45" fill={baseColor} />}

      <polygon
        points="88.67 106 60.93 2 47.07 2 19.33 106 28 106 54 10.67 80 106 88.67 106"
        fill="#e6bbaa"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />

      <rect
        x="9"
        y="18"
        width="90"
        height="63"
        transform="translate(108 99) rotate(180)"
        strokeWidth="2"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#e6bbaa"
      />
      <rect
        x="27"
        y="9"
        width="54"
        height="81"
        transform="translate(4.5 103.5) rotate(-90)"
        strokeWidth="2"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#ddf5f5"
      />
      <polygon
        points="36 22.5 63 54 85.5 45 94.5 63 94.5 76.5 13.5 76.5 13.5 22.5 36 22.5"
        fill="#bfdcc1"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <polyline
        points="76.5 58.5 49.5 67.5 31.5 40.5"
        fill="none"
        stroke="#db6b5e"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <circle
        cx="76.5"
        cy="58.5"
        r="4.5"
        fill="#00759c"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <circle
        cx="49.5"
        cy="67.5"
        r="4.5"
        fill="#fabb26"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <circle
        cx="31.5"
        cy="40.5"
        r="4.5"
        fill="#db6b5e"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Plan;
