import React, {useContext} from 'react';

import Paragraph from '../../../Components/SanatizedComponents/Paragraph';
import {LanguageContext} from '../../../Store/LanguageStore'

const Activity10_intro = () => {
    const [language] = useContext(LanguageContext);
    return (
        <div>
            <Paragraph text={language.steppingstone10_paragraph1}/>
        </div>
    );
}

export default Activity10_intro;
