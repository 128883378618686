import React, { Fragment } from 'react';
import ApplicationStateManager from './Components/ApplicationStateManager';
import AdminLogoutButton from './Components/AdminLogoutButton';
import UserSearch from './Components/UserSearch/UserSearch';
import { Switch, NavLink, Route } from 'react-router-dom';

import classes from './admin.module.scss';
import BatchSearch from './Components/BatchSearch/BatchSearch';
import DownloadData from './Components/DownloadData/DownloadData';
import GetLink from './Components/GetLink';
import Languages from './Components/Languages/Languages';
import Gdpr from './Components/Gdpr';


const AdminControlPannel = (props) => {
    const {getAccountInfo, match} = props;
    return (
        <Fragment>
            <nav className={classes.navbar}>
                <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/ApplicationStatus`}>Application Status</NavLink>
                <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/userSearch`}>User Search</NavLink>
                <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/batchSearch`}>Batch Search</NavLink>
                <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/downloadData`}>Download data</NavLink>
                <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/getlink`}>Get register link</NavLink>
                <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/languages`}>Languages</NavLink>
                <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/gdpr`}>GDPR Functions</NavLink>
            </nav>
            <Switch>
                <Route
                    path={`${match.url}/ApplicationStatus`}
                    component={(props) => <ApplicationStateManager {...props} />}
                />
                <Route
                    path={`${match.url}/userSearch`}
                    component={(props) => <UserSearch {...props} />}
                />
                <Route
                    path={`${match.url}/batchSearch`}
                    component={(props) => <BatchSearch {...props} />}
                />
                <Route
                    path={`${match.url}/downloadData`}
                    component={(props) => <DownloadData {...props} />}
                />
                <Route
                    path={`${match.url}/getlink`}
                    component={(props) => <GetLink {...props} />}
                />
                <Route
                    path={`${match.url}/languages`}
                    component={(props) => <Languages {...props} />}
                />
                <Route
                    path={`${match.url}/gdpr`}
                    component={(props) => <Gdpr {...props} />}
                />
            </Switch>
            <br/>
            <AdminLogoutButton getAccountInfo={getAccountInfo}/>
        </Fragment>
    );
}

export default AdminControlPannel;
