import React, { useEffect } from "react";
import AdminLogin from "./Components/AdminLogin";
import { useState } from "react";
import axios from "axios";
import handleError from "../../lib/handleError";
import AdminControlPannel from "./AdminControlPannel";
import classes from "./admin.module.scss";

const AdminPage = (props) => {
  // if admin is logged in display admin page
  // otherwise display admin login page
  const [adminAccount, setadminAccount] = useState(null);
  const [loading, setloading] = useState(true);
  useEffect(() => {
    let source = axios.CancelToken.source();

    getAccountInfo();
  }, []);

  const getAccountInfo = async () => {
    try {
      let res = await axios.get("/admin/loggedin");
      if (res.data.id) {
        setadminAccount(res.data); // set the account
      } else {
        setadminAccount(null); // set the account
      }
      setloading(false);
    } catch (e) {
      if (!axios.isCancel(e)) {
        setadminAccount(null);
        handleError(e, false);
      }
      setloading(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.form}>
        {adminAccount ? (
          <AdminControlPannel getAccountInfo={getAccountInfo} {...props} />
        ) : (
          <AdminLogin getAccountInfo={getAccountInfo} />
        )}
      </div>
    </div>
  );
};

export default AdminPage;
