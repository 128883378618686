import React, { Fragment, useContext } from "react";
import Footer from "./Footer/Footer";
import classes from "./Support.module.scss";
import IconChairs from "../../Components/icons/Chairs";
import BalanceText from "react-balance-text";

import { LanguageContext } from "../../Store/LanguageStore";
import DOMPurify from "dompurify";
import SupportLinksSection from "./SupportLinksSection";

const Support = () => {
  const [language] = useContext(LanguageContext);
  const strings = language.support;

  return (
    <Fragment>
      <main>
        <section className={classes.intro}>
          <div className={classes.inner}>
            <IconChairs className={classes.introIcon} base />
            <h1>
              <BalanceText>{strings.title}</BalanceText>
            </h1>

            <p
              dangerouslySetInnerHTML={{
                __html: strings.intro,
              }}
            />
          </div>
        </section>

        <div className={classes.sections}>
          {strings.sections.map((section) => (
            <SupportLinksSection section={section} />
          ))}
          <section className={classes.section}>
            <div className={classes.inner}>
              <h2 className={classes.sectionTitle}>
                {strings.technicalSupportTitle}
              </h2>
              <p
                style={{ textAlign: "left" }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(strings.technicalSupportMain),
                }}
              />
            </div>
          </section>
        </div>
      </main>
      <div className={classes.footerWrapper}>
        <Footer />
      </div>
    </Fragment>
  );
};

export default Support;
