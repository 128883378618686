import React, {useContext} from 'react';

import Paragraph from '../../../Components/SanatizedComponents/Paragraph';
import Quote from '../../../Components/SanatizedComponents/Quote';
import UnorderedList from '../../../Components/SanatizedComponents/UnorderedList';
import {LanguageContext} from '../../../Store/LanguageStore'
const Activity7_intro = () => {
    const [language] = useContext(LanguageContext);
    return (
        <div>
            <Paragraph text={language.steppingstone7_paragraph1}/>
            <UnorderedList items={language.steppingstone7_paragraph1_bullets1}/>
            
            <Paragraph text={language.steppingstone7_paragraph2}/>
            <Quote text={language.steppingstone7_quote1}/>
        </div>
    );
}

export default Activity7_intro;
