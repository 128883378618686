import React from 'react';
import { Link } from 'react-router-dom';
import DOMPurify from "dompurify";

const useParseStringLinks = () => {

    const parseLink = (str, links, openInNewTab = false) => {

        let _links = [];

        for(let link of links){
            let startString = `<${link.placeholder}>`;
            let endString = `</${link.placeholder}>`;
            _links.push({
                to: link.to,
                start: str.indexOf(startString),
                end: str.indexOf(endString),
            })
        }

        _links.sort((a,b)=>{
            return a.start-b.start;
        })

        let strings = [];
        let currentIndex = 0;
        for(let link of _links){
            if(link.start != -1){
                strings.push({
                    string: " " + str.substring(currentIndex, link.start),
                    isLink: false
                });
                strings.push({
                    string: str.substring(link.start, link.end),
                    isLink: true,
                    to: link.to
                })
                currentIndex = link.end;
            }
        }
        strings.push({
            string: str.substring(currentIndex, str.length),
            isLink: false
        })

        return (
            <React.Fragment>
                {
                    strings.map((string, index)=>{
                        if(string.isLink){
                            return <span key={index}><Link  to={string.to} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(string.string)}}/> </span>
                        }else{
                            return <span key={index} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(string.string)}}/> 
                        }
                    })
                }
            </React.Fragment>
        )
    }

    const parseSupportLinks = (str, openInNewTab = false) => {
        let links = [
            {placeholder: "SupportLink", to: "/support"}
        ];
        return parseLink(str, links, openInNewTab)
    }

    const parseStudyInfoLinks = (str, openInNewTab = false) => {
        let links = [
            {placeholder: "StudyInfoLink", to: "/study-info"}
        ];
        return parseLink(str, links, openInNewTab)
    }

    const parseTermsOfUseLinks = (str, openInNewTab = false) => {
        let links = [
            {placeholder: "TermsOfUse", to: "/terms-of-use"}
        ];
        return parseLink(str, links, openInNewTab)
    }

    const parseAllLinks = (str, openInNewTab = false) => {
        let links = [
            {placeholder: "LoginLink", to: "/login"},
            {placeholder: "RegisterLink", to: "/register"},
            {placeholder: "SupportLink", to: "/support"},
            {placeholder: "StudyInfoLink", to: "/study-info"},
            {placeholder: "TermsOfUse", to: "/terms-of-use"},
            {placeholder: "Home", to: "/"},
            {placeholder: "PatientsLink", to: "/patients"},
            {placeholder: "ClinicsLink", to: "/clinics"},
            {placeholder: "OtherRoutesLink", to: "/patients/other-routes"},

        ];
        return parseLink(str, links, openInNewTab);
    }

    return {parseSupportLinks, parseStudyInfoLinks, parseAllLinks, parseTermsOfUseLinks};
    


}

export default useParseStringLinks;
