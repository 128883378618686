import React, {useState, useEffect} from 'react';
import axios from "axios";

import { Redirect, useLocation } from "react-router-dom";
import handleError from '../lib/handleError';


const initialState = {
    id: null,
    email: null,
    firstName: null,
    lastName: null,
    country: null,
    gender: null,
    demographicsComplete: true,
    progress: null
}

export const AccountContext = React.createContext();

const AccountStore = ({children}) => {    
    const [user, setuser] = useState(initialState);
    const [firstLogin, setfirstLogin] = useState(false);
    const [loading, setloading] = useState(true);
    const {pathname} = useLocation();

    useEffect(() => {
        init();
    },[]);

    const init = async () => {
        const _user = await getAccountInfo();
        if(_user){
            setfirstLogin(!_user.demographicsComplete);
        }
    }
    
    const getAccountInfo = async (_user) => {
        setloading(true);
        try {
            if(!_user){
                let res = await axios.get("/user/deetails");
                _user = res.data;
            }
            if (_user.id) {
                // if an account is returned
                let progress = await axios.get("/user/progress/get");
                _user.progress = progress.data.progress;
                setuser(_user); // set the account 
                setloading(false);
                return _user;
            }
            else{
                setloading(false);
                setuser(initialState);
            }
            
        } catch (e) {
            setloading(false);
            handleError(e);
        }  
    };

    

    if (!loading && user.id && !user.demographicsComplete && pathname != "/user/settings/info") {
        return <Redirect to="/user/settings/info" />;
    }
    else if (!loading && user.id && !user.seenReminderEmailsPrompt && user.demographicsComplete && pathname != "/user/settings/reminders") {
        return <Redirect to="/user/settings/reminders" />;
    } else {
        return (
            <AccountContext.Provider value={[user, getAccountInfo, loading]}>
                {children}
            </AccountContext.Provider>
        )
    }
}

export default AccountStore;