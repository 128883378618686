import React from "react";

const Calendar = ({ className, color = "#000", weight = 3 }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      <rect
        x="13.5"
        y="13.5"
        width="81"
        height="81"
        strokeWidth={weight}
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <rect
        x="13.5"
        y="13.5"
        width="81"
        height="18"
        strokeWidth={weight}
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={color}
      />

      <rect
        x="22.5"
        y="40.5"
        width="63"
        height="45"
        strokeWidth={weight}
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <line
        x1="22.5"
        y1="76.5"
        x2="85.5"
        y2="76.5"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <line
        x1="22.5"
        y1="67.5"
        x2="85.5"
        y2="67.5"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <line
        x1="22.5"
        y1="58.5"
        x2="85.5"
        y2="58.5"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <line
        x1="22.5"
        y1="49.5"
        x2="85.5"
        y2="49.5"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <line
        x1="76.5"
        y1="40.5"
        x2="76.5"
        y2="85.5"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <line
        x1="67.5"
        y1="40.5"
        x2="67.5"
        y2="85.5"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <line
        x1="58.5"
        y1="40.5"
        x2="58.5"
        y2="85.5"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <line
        x1="49.5"
        y1="40.5"
        x2="49.5"
        y2="85.5"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <line
        x1="40.5"
        y1="40.5"
        x2="40.5"
        y2="85.5"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <line
        x1="31.5"
        y1="40.5"
        x2="31.5"
        y2="85.5"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />

      <polyline
        points="30 42 30 42 24 48"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <polyline
        points="24 42 30 48 30 48"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />

      <polyline
        points="39 42 39 42 33 48"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <polyline
        points="33 42 39 48 39 48"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />

      <polyline
        points="48 42 48 42 42 48"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <polyline
        points="42 42 48 48 48 48"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />

      <polyline
        points="57 42 57 42 51 48"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <polyline
        points="51 42 57 48 57 48"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
    </svg>
  );
};

export default Calendar;
