import React, {useState} from "react";
import { Switch, Route, Link } from "react-router-dom";
import RoutineHub from "./RoutineHub";

import Routines from "./Routines";


const Backpack = (props) => {
  const { match } = props;

  return (
    <div>
      <Switch>
        <Route
          exact
          path={[`${match.url}`,`${match.url}/:routineId`]}
          component={(props) => <Routines {...props} />}
        />
        <Route
          path={`${match.url}/routine/:routineId`}
          component={(props) => <RoutineHub {...props} />}
        />
      </Switch>
    </div>
  );
};

export default Backpack;
