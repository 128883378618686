import React from "react";
import Alert from "./Alert";
import Binoculars from "./Binoculars";
import Bridge from "./Bridge";
import Calendar from "./Calendar";
import Campfire from "./Campfire";
import Compass from "./Compass";
import FirstAid from "./FirstAid";
import Headphones from "./Headphones";
import Heart from "./Heart";
import Map from "./Map";
import Lighthouse from "./Lighthouse";
import OneWay from "./OneWay";
import Path from "./Path";
import Pencil from "./Pencil";
import Plan from "./Plan";
import RightTurn from "./RightTurn";
import Ticket from "./Ticket";
import Torch from "./Torch";

const setIcon = ({ icon, className, base = true }) => {
  switch (icon) {
    case "alert":
      return <Alert base={base} className={className} />;
    case "binoculars":
      return <Binoculars base={base} className={className} />;
    case "bridge":
      return <Bridge base={base} className={className} />;
    case "calendar":
      return <Calendar base={base} className={className} />;
    case "campfire":
      return <Campfire base={base} className={className} />;
    case "compass":
      return <Compass base={base} className={className} />;
    case "firstaid":
      return <FirstAid base={base} className={className} />;
    case "headphones":
      return <Headphones base={base} className={className} />;
    case "heart":
      return <Heart base={base} className={className} />;
    case "map":
      return <Map base={base} className={className} />;
    case "lighthouse":
      return <Lighthouse base={base} className={className} />;
    case "oneway":
      return <OneWay base={base} className={className} />;
    case "path":
      return <Path base={base} className={className} />;
    case "pencil":
      return <Pencil base={base} className={className} />;
    case "plan":
      return <Plan base={base} className={className} />;
    case "rightturn":
      return <RightTurn base={base} className={className} />;
    case "ticket":
      return <Ticket base={base} className={className} />;
    case "torch":
      return <Torch base={base} className={className} />;
    default:
      return <Ticket base={base} className={className} />;
  }
};

export default setIcon;
