import React from "react";
import MonoCalendar from "./MonoCalendar";
import MonoChairs from "./MonoChairs";
import MonoMindful from "./MonoMindful";
import MonoWrite from "./MonoWrite";

const setIcon = ({ icon, className, color = "#404a45", key }) => {
  switch (icon) {
    case "calendar":
      return <MonoCalendar key={key} color={color} className={className} />;
    case "chairs":
      return <MonoChairs key={key} color={color} className={className} />;
    case "mindful":
      return <MonoMindful key={key} color={color} className={className} />;
    case "write":
      return <MonoWrite key={key} color={color} className={className} />;
    default:
      return <MonoWrite key={key} color={color} className={className} />;
  }
};

export default setIcon;
