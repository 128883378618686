import React, { useState, useRef, Fragment, useContext } from "react";
import Dropdown from "../../../Components/Forms/Dropdown/DropdownInput";
import { LanguageContext } from "../../../Store/LanguageStore";
import Paragraph from "../../../Components/SanatizedComponents/Paragraph";
import classes from "./ValueStatementInput.module.scss";
import CloseButton from "../../../Components/buttons/CloseButton/CloseButton";
import useValueStatmentStrings from "../../../Hooks/useValueStatmentStrings";

// ToDo: this file has potential for js injection

const ValueStatementInput = ({ submitAnswer }) => {
  const [language] = useContext(LanguageContext);
  const [modalOpen, setModalOpen] = useState(false);
  const lifeAreas = useValueStatmentStrings();

  lifeAreas.forEach((area) => {
    area.value = area.area;
  });

  const [currentLifeArea, setcurrentLifeArea] = useState(undefined);

  const inputRef = useRef();

  const inputOnKeyUp = (e) => {
    if (e.keyCode === 13) {
      submit();
    }
  };

  const close = () => {
    inputRef.current.value = "";
    setcurrentLifeArea(undefined);
    setModalOpen(false);
  };
  const submit = () => {
    submitAnswer(inputRef.current.value, currentLifeArea);
    inputRef.current.value = "";
    setModalOpen(false);
  };

  return (
    <Fragment>
      <button
        className={classes.buttonSubmit}
        onClick={() => setModalOpen(true)}
      >
        {language.steppingstone4_valuestatement4_Add_a_value}
      </button>
      {modalOpen && (
        <div className={classes.modalWrapper}>
          <div className={classes.modal}>
            <div className={classes.modalHeader}>
              <h2>{language.steppingstone4_valuestatement4_Add_a_value}</h2>
              <CloseButton className={classes.modalClose} onClick={close} />
            </div>
            <div className={`${classes.modalContent} ${classes.allowOverflow}`}>
              <div className={classes.fieldWrapper}>
                <Dropdown
                  options={lifeAreas}
                  label={language.life_area}
                  onChange={(value) => {
                    setcurrentLifeArea(value);
                  }}
                  value={currentLifeArea}
                />
              </div>
              <div className={classes.fieldWrapper}>
                <label>
                  {language.steppingstone4_valuestatement4_Your_value}
                </label>
                <input
                  type="text"
                  disabled={typeof currentLifeArea === "undefined"}
                  ref={inputRef}
                  onKeyUp={inputOnKeyUp}
                />
                {currentLifeArea != null && lifeAreas[currentLifeArea].hint ? (
                  <div className={classes.help}>
                    <Paragraph text={lifeAreas[currentLifeArea].hint} />
                  </div>
                ) : (
                  <Fragment />
                )}
              </div>
              <div className={classes.fieldWrapper}>
                <button
                  disabled={
                    typeof currentLifeArea === undefined ||
                    typeof inputRef.current?.value === undefined
                  }
                  className={classes.buttonSubmit}
                  onClick={submit}
                >
                  {language.steppingstone4_addValueLabel}
                </button>
                <button className={classes.buttonCancel} onClick={close}>
                  {language.cancel}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ValueStatementInput;
