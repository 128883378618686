import React from "react";
import classes from "./RoutineThumbnail.module.scss";
import { Link } from "react-router-dom";
import LikeButton from "../../buttons/LikeButton/LikeButton";
import Heading from "../../SanatizedComponents/Heading";
import setIconMono from "../../icons/setIconMono";
import { useContext } from "react";
import { LanguageContext } from "../../../Store/LanguageStore";

const RoutineThumbnail = React.forwardRef(({ title, icon, id }, ref) => {

  const [language] = useContext(LanguageContext);

  return (
    <article className={classes.card} ref={ref}>
      <div className={classes.inner}>
        <div className={classes.content}>
          <Heading level="h2" className={classes.title} text={title} />
        </div>
        {setIconMono({
          icon: icon,
          className: classes.icon,
        })}
      </div>
      <div className={classes.actions}>
        <Link
          to={`/user/backpack/routine/${id + 1}`}
          className={classes.buttonPrimary}
        >
          {language.view_this_routine}
        </Link>
        <LikeButton id={id+1} />
      </div>
    </article>
  );
});

export default RoutineThumbnail;
