import React, { useState, useEffect, useContext } from "react";
import WhoAmI from "./WhoAmI";
import axios from "axios";
import utils from "../../../utils/utils";
import TextQuestion from "../../../Components/Questions/TextQuestion/TextQuestion";
import LoadingScreen from "../../../Components/LoadingScreen/LoadingScreen";

import Paragraph from "../../../Components/SanatizedComponents/Paragraph";
import { LanguageContext } from "../../../Store/LanguageStore";
import PreviousAnswer from "../../../Components/PreviousAnswer/PreviousAnswer";
import handleError from "../../../lib/handleError";
import useValueStatmentStrings from "../../../Hooks/useValueStatmentStrings";

const Activity8_main = ({ attempt }) => {
  const [language] = useContext(LanguageContext);
  const [statments, setstatments] = useState([]);
  const [answers, setanswers] = useState([]);
  const [loading, setloading] = useState(true);
  const initialStatements = useValueStatmentStrings();

  let source;

  useEffect(() => {
    source = axios.CancelToken.source();

    const getPreviousAnswers = async () => {
      try {
        let answer = await axios.post(
          "/user/answer/get",
          {
            task_id: 4,
            question_id: 1,
          },
          { cancelToken: source.token }
        );

        if (answer.data.found) {
          let rankedStatements = answer.data.answer;
          rankedStatements.sort((a, b) => a.rank - b.rank);

          setstatments(rankedStatements);

          let whoAmI = await axios.post(
            "/user/answer/valuestatements/whoami/get",
            {}
          );
          let _answers = [];
          if (whoAmI.data.found) {
            for (const statment of rankedStatements) {
              let answer = whoAmI.data.answer.find(
                (answer) => answer.value_statement_id == statment.id
              );
              if (answer) _answers.push(answer.answer_text);
              else _answers.push("");
            }
          } else {
            for (let i = 0; i < rankedStatements.length; i++) {
              _answers.push("");
            }
          }

          let finalAnswer = await axios.post("/user/answer/get", {
            task_id: 8,
            question_id: 2,
          });
          if (finalAnswer.data.found) {
            _answers.push(finalAnswer.data.answer);
          } else {
            _answers.push("");
          }

          setanswers(_answers);
        } else {
          throw "answer not found";
        }
        setloading(false);
      } catch (e) {
        if (!axios.isCancel(e)) {
          handleError(e);
          setloading(false);
        }
      }
    };
    getPreviousAnswers();

    return () => {
      source.cancel();
    };
  }, []);

  const task_id = 8;

  const _submitAnswer = async (question_id, answer) => {
    utils.submitAnswer(task_id, question_id, answer, attempt);
  };

  const _submitWhoAmIAnswer = async (value_statement_id, answer) => {
    const data = {
      question_id: 1,
      task_id: 8,
      value_statement_id,
      answer,
      attempt,
    };
    try {
      await axios.post("/user/answer/valuestatements/whoami/set", data);
    } catch (e) {
      handleError(e, true);
    }
  };

  return (
    <LoadingScreen loading={loading}>
      <div>
        <Paragraph text={language.steppingstone8_paragraph3} />
        {statments.length <= 0 ? (
          <PreviousAnswer
            content={statments.length > 0}
            no_answer_string={
              language.steppingstone8_valuestatement1_NoPreviousAnswer
            }
          />
        ) : (
          statments.slice(0, 3).map((statment, index) => {
            return (
              <WhoAmI
                initialValue={answers[index]}
                domain={initialStatements[statment.area - 1].area}
                statment={statment.answer}
                rank={statment.rank}
                submitAnswer={(answer) =>
                  _submitWhoAmIAnswer(statment.id, answer)
                }
              />
            );
          })
        )}

        <TextQuestion
          initialValue={answers[4]}
          question={language.steppingstone8_paragraph4}
          hint={language.steppingstone8_paragraph4_hint}
          submitAnswer={(answer) => _submitAnswer(2, answer)}
        />
      </div>
    </LoadingScreen>
  );
};

export default Activity8_main;
