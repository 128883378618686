import React, { useEffect, useState, useContext } from 'react';
import TextQuestion from '../../../Components/Questions/TextQuestion/TextQuestion';
import axios from 'axios';
import LoadingScreen from '../../../Components/LoadingScreen/LoadingScreen';
import usePreviousAnswers from '../../../Hooks/usePreviousAnswers'

import {LanguageContext} from '../../../Store/LanguageStore'

import Heading from '../../../Components/SanatizedComponents/Heading'
import Paragraph from '../../../Components/SanatizedComponents/Paragraph'
import Quote from '../../../Components/SanatizedComponents/Quote'

import classes from "../Activity.module.scss"
import handleError from '../../../lib/handleError';


const Activity1_questions = ({attempt, allowContinue, restrictContinue}) => {

    const [language] = useContext(LanguageContext);

    const task_id = 1;

    const questionCount = 2;
    const firstQuestionIndex = 0;
    const [answers, loading, setAnswers] = usePreviousAnswers(task_id, questionCount, attempt, allowContinue, null, firstQuestionIndex);

    const addAnswerToState = (index, answer) => {
        let _answers = [...answers];
        _answers[index - firstQuestionIndex] = answer;
        if(!_answers.includes(undefined) || !_answers.includes("")) allowContinue();
        //else restrictContinue();
        setAnswers(_answers)
    }

    const submitAnswer = async (question_id, answer) => {

        const data = {
            task_id,
            question_id,
            answer,
            attempt
        }

        try {
            await axios.post("/user/answer/set", data)
            addAnswerToState(question_id-1, answer)    
            
        }
        catch (e) {
            handleError(e, true);
        }

    }

    return (
        <LoadingScreen loading={loading}>
            <div className={classes.questions}>
                <Heading level="h2" text={language.steppingstone1_subtitle1} />
                <Paragraph text={language.steppingstone1_paragraph2} />
                <Quote text={language.steppingstone1_quote1}/>
                <Quote text={language.steppingstone1_quote2}/>

                <TextQuestion submitAnswer={(answer) => submitAnswer(1, answer)} question={language.steppingstone1_question2} hint={language.steppingstone1_question2_hint} initialValue={answers[0]}/>
                <TextQuestion submitAnswer={(answer) => submitAnswer(2, answer)} question={language.steppingstone1_question3} hint={language.steppingstone1_question3_hint} initialValue={answers[1]} />

                <Heading level="h2" text={language.steppingstone1_statement1} />
            </div>
        </LoadingScreen>
    );

}

export default Activity1_questions;
