import React, { useEffect, useState, useContext } from "react";
import TextQuestion from "../../../Components/Questions/TextQuestion/TextQuestion";
import utils from "../../../utils/utils";

import LoadingScreen from "../../../Components/LoadingScreen/LoadingScreen";

import Heading from "../../../Components/SanatizedComponents/Heading";
import Paragraph from "../../../Components/SanatizedComponents/Paragraph";
import UnorderedList from "../../../Components/SanatizedComponents/UnorderedList";
import PreviousAnswer from "../../../Components/PreviousAnswer/PreviousAnswer";
import { LanguageContext } from "../../../Store/LanguageStore";

const Activity6_main_2 = ({ attempt, allowContinue, restrictContinue }) => {
  const [language] = useContext(LanguageContext);
  const task_id = 6;


  return (
    <div>
      <Paragraph text={language.steppingstone6_paragraph7} />
      <UnorderedList items={language.steppingstone6_paragraph7_bullets1} />
      <Paragraph text={language.steppingstone6_paragraph6} />
      <Paragraph text={language.steppingstone6_paragraph8} />
    </div>
  );
};

export default Activity6_main_2;
