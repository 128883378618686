import React, {useContext} from 'react';

import Heading from '../../../Components/SanatizedComponents/Heading';
import Quote from '../../../Components/SanatizedComponents/Quote';
import Paragraph from '../../../Components/SanatizedComponents/Paragraph';
import {LanguageContext} from '../../../Store/LanguageStore'
const Activity6_intro = () => {
    const [language] = useContext(LanguageContext);
    return (
        <div>
            <Paragraph text={language.steppingstone6_paragraph1}/>
            <Paragraph text={language.steppingstone6_paragraph2}/>
            <Quote text={language.steppingstone6_quote1}/>
        </div>
    );
}

export default Activity6_intro;
