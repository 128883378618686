import React, { useContext } from "react";
import TextQuestion from "../../../Components/Questions/TextQuestion/TextQuestion";

import Paragraph from "../../../Components/SanatizedComponents/Paragraph";
import { LanguageContext } from "../../../Store/LanguageStore";

import utils from "../../../utils/utils";
import usePreviousAnswers from "../../../Hooks/usePreviousAnswers";

const Activity10_summary = ({ task_id, attempt }) => {
  const [language] = useContext(LanguageContext);

  const questionCount = 3;
  const startIndex = 18;
  const [answers, loading] = usePreviousAnswers(
    task_id,
    questionCount,
    attempt,
    null,
    null,
    startIndex
  );

  const _submitAnswer = async (question_id, answer) => {
    utils.submitAnswer(task_id, question_id, answer, attempt);
  };

  return (
    <div>
      <Paragraph text={language.steppingstone10_ABC} />

      <TextQuestion
        submitAnswer={(answer) => _submitAnswer(18, answer)}
        question={language.steppingstone10_ABCaccept}
        hint={language.steppingstone10_ABCaccept_hint}
      />
      <TextQuestion
        submitAnswer={(answer) => _submitAnswer(19, answer)}
        question={language.steppingstone10_ABCbelieve}
        hint={language.steppingstone10_ABCbelieve_hint}
      />
      <TextQuestion
        submitAnswer={(answer) => _submitAnswer(20, answer)}
        question={language.steppingstone10_ABCcommit}
        hint={language.steppingstone10_ABCcommit_hint}
      />
    </div>
  );
};

export default Activity10_summary;
