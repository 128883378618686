import React from "react";

const Torch = ({ className, base, baseColor = "#ddf5f5" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      {base && <circle cx="54" cy="54" r="45" fill={baseColor} />}

      <path
        d="M53,19.85,48.65,41.79,13.55,76.9c-2.43,2.42-.46,8.32,4.39,13.16s10.74,6.82,13.16,4.39l35.11-35.1L88.15,55l8.77-8.78-35.1-35.1Z"
        fill="#80918b"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M88.15,55c-4.85,4.85-16.64.92-26.33-8.78S48.19,24.7,53,19.85l8.78-8.77,35.1,35.1Z"
        fill="#404a45"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <ellipse
        cx="79.37"
        cy="28.63"
        rx="12.41"
        ry="24.82"
        transform="translate(3 64.51) rotate(-45)"
        strokeWidth="2"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#fabb26"
      />

      <ellipse
        cx="44.26"
        cy="46.18"
        rx="6.21"
        ry="2.76"
        transform="translate(-19.69 44.83) rotate(-45)"
        strokeWidth="2"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#231f20"
      />
      <path
        d="M80.16,27.84A13,13,0,0,0,63,26.75a47.89,47.89,0,0,0,7.91,10.34A47.89,47.89,0,0,0,81.25,45,13,13,0,0,0,80.16,27.84Z"
        fill="#faf1ee"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Torch;
