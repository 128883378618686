import React, {useContext, useRef, Fragment} from 'react';
import { Link } from "react-router-dom";

import classes from "./UserLoginRegisterPages.module.scss";
import { LanguageContext } from '../../../Store/LanguageStore';
import { useState } from 'react';
import Axios from 'axios';
import handleError from '../../../lib/handleError';
import useResendConfirmationEmail from '../../../Hooks/useResendConfirmationEmail';

const ResetPassword = () => {

    const [language] = useContext(LanguageContext);
    const emailRef = useRef();

    const [message, setmessage] = useState(undefined)
    const [isError, setisError] = useState(false);
    const [resendConfirmationEmail] = useResendConfirmationEmail(setmessage,setisError);

    const validEmail = (email) => {
        if(email.length == 0){
          return false;
        }
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!re.test(String(email).toLowerCase())){
          return false;
        }
        return true;
    }

    const submit = async () => {

        let email = emailRef.current.value;
        if(!validEmail(email)){
            alert(language.email_invalid);
            return;
        }


        try{
            setisError(false);
            setmessage(<Fragment>{language.processing}</Fragment>)
            let result = await Axios.post('/user/password/reset',{email})
            setmessage(<React.Fragment>{language.recoveryEmailSent}<br/>{language.cantSeeTheEmail}<span className={classes.link} onClick={submit}>{language.resendHere}</span></React.Fragment>)
        }catch(e){
            setisError(true);
            switch(e.response.data){
                case "email doesn't exist":
                    setmessage(<React.Fragment>{language.noAccountWithThatEmail}<br/><Link to="/register">{language.register_here}</Link></React.Fragment>)
                    break;
                case "email hasn't been approved":
                    setmessage(<React.Fragment>{language.yourEmailIsRegisteredButUnconfirmed}<br/>{language.cantSeeTheEmail}<span className={classes.link} onClick={()=>resendConfirmationEmail(email)}>{language.resendHere}</span> </React.Fragment>)
                    break;
                default:
                    handleError(e, false);
                    setmessage(<React.Fragment>{language.anErrorOccured}</React.Fragment>)
            }
        }
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.content}>
                <div className={classes.form}>
                    <h1>{language.passwordRecovery}</h1>
                    <div className={classes.fieldWrapper}>
                        <label htmlFor="loginEmail">{language.pleaseEnterYourEmail}</label>
                        <input id="loginEmail" type="email" ref={emailRef}></input>
                    </div>
                    <button className={classes.buttonSubmit} onClick={submit}>{language.sendRecoveryEmail}</button>
                    {
                        message && (
                            <div className={`${classes.message} ${isError ? classes.isError : classes.isSuccess}`}>
                                {message}
                            </div>
                        )
                    }
                </div >
            </div>
        </div>
    );
}

export default ResetPassword;
