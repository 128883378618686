import React, { Fragment, useContext  } from "react";
import Paragraph from "../../Components/SanatizedComponents/Paragraph";
import classes from "./ActivityPreview.module.scss";
import { Link } from "react-router-dom";
import useStartNewAttempt from "../../Hooks/useStartNewAttempt";
import setIcon from "../../Components/icons/setIcon";
import setIconMono from "../../Components/icons/setIconMono";
import {LanguageContext} from "../../Store/LanguageStore";

const Activity_Preview = ({
  activity: { title, aim },
  index,
  match,
  disabled,
  active,
  complete,
  current_attempt,
  icon,
  activityIcons,
}) => {
  const [startNewAttempt] = useStartNewAttempt();
  const [language] = useContext(LanguageContext)

  return (
    <article
      className={`${classes.card} ${
        disabled ? classes.disabled : classes.active
      }`}
      key={index}
    >
      <div className={classes.inner}>
        {setIcon({ icon: icon, className: classes.stone })}
        <div className={classes.content}>
          <h2 className={classes.title}>{title}</h2>
          <Paragraph text={aim} />
        </div>
      </div>
      <div className={classes.actions}>
        {disabled ? (
          <div className={classes.locked}>
            {language.you_must_complete_previous_steps}
          </div>
        ) : (
          <Fragment />
        )}
        {!disabled && !active && !complete ? (
          <Link
            className={classes.buttonPrimary}
            to={`${match.url}/step/${index + 1}`}
            onClick={(e) => startNewAttempt(e, index)}
          >
            {language.take_this_step}
          </Link>
        ) : (
          <Fragment />
        )}
        {!disabled && active ? (
          <Link
            className={classes.buttonPrimary}
            to={`${match.url}/step/${index + 1}`}
          >
            {language.continue}
          </Link>
        ) : (
          <Fragment />
        )}
        {!disabled && !active && complete ? (
          <div className={classes.redo}>
            {language.you_completed_this_step_but_you_can}
            <Link
              to={`${match.url}/step/${index + 1}`}
            >
              {" "}
              {language.change_your_answers}{" "}
            </Link>
            {language.or}
            <Link
              onClick={(e) => startNewAttempt(e, index)}
              to={`${match.url}/step/${index + 1}`}
            >
              {" "}
              {language.start_over}{" "}
            </Link>
            {language.whenever_you_like}
          </div>
        ) : (
          <Fragment />
        )}
        {/* {
                    (!disabled && active && complete)
                        ? (
                            <div className={classes.redo}>You've completed this step but you can 
                                <Link to={`${match.url}/step/${index + 1}/attempt/${current_attempt-1}`}> change your answers </Link> or <Link to={`${match.url}/step/${index + 1}/attempt/${current_attempt}`}>continue with your new attempt</Link> whenever you like.</div>
                        
                            
                        )
                        : <Fragment/>
                } */}
        <div className={classes.routineIcons}>
          {activityIcons &&
            activityIcons.map((icon, i) =>
              setIconMono({
                icon: icon,
                key: i,
                className: classes.routineIcon,
              })
            )}
        </div>
      </div>
    </article>
  );
};

export default Activity_Preview;
