import React, {useState, useEffect} from 'react';
import { Redirect } from 'react-router-dom';

export const RedirectContext = React.createContext();

const RedirectTo = ({children}) => {
    const [redirectUrl, redirect] = useState(undefined);
    const [afterLoginUrl, setAfterLoginUrl] = useState(undefined);

    const redirectAfterLogin = (url) => {
        if(url){
            setAfterLoginUrl(url);
            redirect("/login");
        }else{
            setAfterLoginUrl(undefined);
        }
        
    }

    const afterLogin = () => {
        if(afterLoginUrl && afterLoginUrl != "/login"){
            let _url = afterLoginUrl;
            setAfterLoginUrl(undefined)
            redirect(_url);
            return true;
        }
        redirect("/user/map");
    }

    useEffect(() => {
        if(redirectUrl) redirect(undefined);
    });

    if(redirectUrl){
        return <Redirect to={redirectUrl}/>
    }

    return (
        <RedirectContext.Provider value={[redirect, redirectAfterLogin, afterLogin]}>
            {children}
        </RedirectContext.Provider>
    )
}

export default RedirectTo;