import React, { useContext } from "react";
import { Link } from "react-router-dom";

import Activity7_intro from "./Activity7_intro";
import Activity7_main_1 from "./Activity7_main_1";
import Activity7_main_2 from "./Activity7_main_2";
import Activity7_main_3 from "./Activity7_main_3";


import ActivityAssessment from "../../../Pages/ActivityAssessment/ActivityAssessment";
import ActivityWrapper from "../ActivityWrapper";
import classes from "../Activity.module.scss";
import IconBridge from "../../../Components/icons/Bridge";

import Heading from "../../../Components/SanatizedComponents/Heading";
import Paragraph from "../../../Components/SanatizedComponents/Paragraph";
import { LanguageContext } from "../../../Store/LanguageStore";
import WHO5 from "../../../Components/WHO5/WHO5";
import WHO5Display from "../../../Components/WHO5/WHO5Display";

const Activity7 = (props) => {
  const [language] = useContext(LanguageContext);
  const task_id = 7;
  const { attempt } = props;
  const pages = [
    {
      link: "/who",
      component: () => <WHO5 task_id={task_id} attempt={attempt} />,
    },
    {
      link: "/who/display",
      component: () => <WHO5Display task_id={task_id} attempt={attempt} />,
    },
    { link: "/introduction", component: () => <Activity7_intro /> },
    {
      link: "/activity/1",
      component: () => <Activity7_main_1 task_id={task_id} attempt={attempt} />,
    },
    {
      link: "/activity/2",
      component: () => <Activity7_main_2 task_id={task_id} attempt={attempt} />,
    },
    {
      link: "/activity/3",
      component: () => <Activity7_main_3 task_id={task_id} attempt={attempt} />,
    },
    {
      link: "/activityassessment",
      component: () => (
        <ActivityAssessment task_id={task_id} attempt={attempt} />
      ),
    },
    
  ];

  return (
    <div>
      <div className={classes.headerWrapper}>
        <header className={classes.header}>
          <IconBridge base className={classes.icon} />
          <div>
            <Heading text={language.steppingstone7_title} />
            <Paragraph text={language.steppingstone7_aim} />
          </div>
        </header>
      </div>
      <nav className={classes.breadcrumbs}>
        <Link to="/user/map">{language.map_menu}</Link>
        {" > "}
        
        <span dangerouslySetInnerHTML={{__html: language.steppingstone7_title}}></span>
      </nav>
      <ActivityWrapper {...props} pages={pages} task_id={task_id} />
    </div>
  );
};

export default Activity7;
