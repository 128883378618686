import React from "react";

const NoEntry = ({ className, base, baseColor = "#ddf5f5" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      {base && <circle cx="54" cy="54" r="45" fill={baseColor} />}

      <polygon
        points="84.9 27 64.3 18 54 27 43.7 18 23.1 27 2.5 81 33.4 72 54 90 74.6 72 105.5 81 84.9 27"
        fill="#faf1ee"
      />
      <polygon points="64.3 18 74.6 72 54 90 54 27 64.3 18" fill="#e6bbaa" />
      <polygon points="43.7 18 33.4 72 2.5 81 23.1 27 43.7 18" fill="#e6bbaa" />
      <polygon
        points="43.7 18 33.4 72 2.5 81 23.1 27 43.7 18"
        fill="none"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <polygon
        points="64.3 18 74.6 72 105.5 81 84.9 27 64.3 18"
        fill="none"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <polygon
        points="54 27 54 90 74.6 72 64.3 18 54 27"
        fill="none"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <polygon
        points="54 27 54 90 33.4 72 43.7 18 54 27"
        fill="none"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        x1="85.52"
        y1="64.77"
        x2="89.08"
        y2="55.57"
        fill="none"
        stroke="#db6b5e"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        x1="93.12"
        y1="62.89"
        x2="81.74"
        y2="56.01"
        fill="none"
        stroke="#db6b5e"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default NoEntry;
