import React, { Component } from 'react';
import handleError from '../lib/handleError';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
    }
  
    componentDidCatch(error, info) {
      handleError(error,false,info);
    }
  
    render() {
      return this.props.children;
    }
  }

export default ErrorBoundary;
