import React, { useContext, useEffect } from "react";
import Heading from "../../SanatizedComponents/Heading";
import Paragraph from "../../SanatizedComponents/Paragraph";
import UnorderedList from "../../SanatizedComponents/UnorderedList";

import classes from "./RoutinePage.module.scss";

import LikeButton from "../../buttons/LikeButton/LikeButton";
import AudioPlayer from "../../AudioPlayer/AudioPlayer";
import { LanguageContext } from "../../../Store/LanguageStore";
import { AccountContext } from "../../../Store/AccountStore";
import LookingAheadKit from "../LookingAheadKit/LookingAheadKit";

import { Link, withRouter, useHistory } from "react-router-dom";
import RememberAbc from "../RememberAbc/RememberAbc";
import useUsageMonitoring from "../../../Hooks/useUsageMonitoring";

const RoutinePage = withRouter((props) => {
  const { id, history } = props;
  const [language] = useContext(LanguageContext);
  const [user] = useContext(AccountContext);
  const { routines } = language;
  const routine = routines[id - 1];

  let location = useHistory();

  const [beginUsageMonitoring, endUsageMonitoring, clearTimeouts] =
    useUsageMonitoring(parseFloat(id) + 12);

  useEffect(() => {
    const backListener = history.listen((_location, action) => {
      if (action === "POP") {
        location.replace(`/user/backpack/${id - 1}`);
      }
    });

    beginUsageMonitoring();
    return () => {
      endUsageMonitoring();
      clearTimeouts();
    };
  }, [id]);

  let content;

  if (routine.id == 23) {
    content = <LookingAheadKit {...props} />;
  } else if (routine.id == 25) {
    content = <RememberAbc {...props} />;
  } else {
    content = (
      <div className={classes.wrapper}>
        <div className={classes.content}>
          {typeof routine.description === "object" ? (
            Object.keys(routine.description).map((key, index) => {
              console.log(key);

              if (/paragraph[_]?\d*/i.test(key)) {
                return (
                  <Paragraph key={index} text={routine.description[key]} />
                );
              } else if (/bullets[_]?\d*/i.test(key)) {
                return (
                  <UnorderedList key={index} items={routine.description[key]} />
                );
              } else {
                throw "routine description contains invalid key: " + key;
              }
            })
          ) : (
            <Paragraph text={routine.description} />
          )}
          <div className={classes.list}>
            {routine.audio !== undefined ? (
              <AudioPlayer
                className={classes.audio}
                mp3={routine.audio[user.gender || "Female"]}
              />
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={classes.headerWrapper}>
        <header className={classes.header}>
          <Heading text={routine.title} />
          <LikeButton id={id} />
        </header>
      </div>
      <nav className={classes.breadcrumbs}>
        <Link to={`/user/backpack/${id - 1}`}>{language.backpack_menu}</Link>
        {" > "}
        <span dangerouslySetInnerHTML={{ __html: routine.title }}></span>
      </nav>
      {content}
    </div>
  );
});

export default RoutinePage;
