import { useEffect, useState } from "react";
import axios from "axios";
import handleError from "../lib/handleError";

const usePreviousActivityAssessmentAnswers = () => {
  let source;
  const [answers, setanswers] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    source = axios.CancelToken.source();
    const getAssessments = async () => {
      try {
        
        let result = await axios.post("/user/activityassessment/get", {},{cancelToken: source.token});
        
        console.log(result)

        if(!result.data.found)
          throw "couldn't find activity assessments";

        

        setanswers(result.data.answer);
        setloading(false);
      } catch (e) {
        if(!axios.isCancel(e)){
          handleError(e);
          setloading(false);
        }
      }
    };

    getAssessments();

    return () => {
      source.cancel();
    };
  }, []);

  return [answers, loading, setanswers];
};

export default usePreviousActivityAssessmentAnswers;
