import React, { useContext, useEffect } from 'react';
import Paragraph from '../../SanatizedComponents/Paragraph';
import { LanguageContext } from '../../../Store/LanguageStore';
import RenderFromKeys from '../../RenderFromKeys/RenderFromKeys';

const LookingAheadKitResponse = ({checked, situation, previousRatings}) => {

    const [language] = useContext(LanguageContext)
    const id = 23;
    const strings = language.routines[id-1].responses; 

    let content = {};

    content.paragraph1 = strings.paragraph3;
    content.previousAnswer1 = {
        value: situation,
        no_answer_string: strings.paragraph3_NoPreviuosAnswer
    };

    //paragraph4_NoPreviuosAnswer
    if(situation){
        if(checked.includes(true)){
            let youFindThisDifficultBecause = [];
            for(const i in checked){
                if(checked[i]){
                    youFindThisDifficultBecause.push(language.routines[id-1].description.multipleChoiceQuestion1.answers[i]);
                }
            }
    
            if(youFindThisDifficultBecause.length > 0){
                content.paragraph2 = strings.paragraph4;
                content.bullets1 = youFindThisDifficultBecause;
            }
    
            const getRating = (task_id) => {
                let _rating = previousRatings.find(rating => {
                    if(!rating)
                        return false;
                    return (rating.task_id == task_id)})
                
                if(_rating)
                    return _rating.how_useful;
                else
                    return 3;
            }
    
            let haveResponse = false;
    
            if(checked[0] && (getRating(2) >= 3 || getRating(3) >= 3)){
                content.paragraph4 = strings.unhelpfulAndStressfulThoughts;
                haveResponse = true;
                
                if(getRating(2) >= 3)
                    content.paragraph5 = strings.inStep2;
                
                if(getRating(3) >= 3)
                    content.paragraph6 = strings.inStep3;
            }
    
            if(checked[1] && (getRating(7) >= 3)){
                content.paragraph7 = strings.inStep7;
                haveResponse = true;
            }
    
            if(checked[2]  && (getRating(4) >= 3 || getRating(5) >= 3)){
                content.paragraph8 = strings.meaningAndFulfillment
                haveResponse = true;
                
                if(getRating(4) >= 3)
                    content.paragraph9 = strings.inStep4;
                if(getRating(5) >= 3)
                    content.paragraph10 = strings.inStep5;
            }
    
            if( checked[3] && (getRating(6) >= 3 || getRating(9) >= 3)){
                content.paragraph11 = strings.closerToYourValues;
                haveResponse = true;
    
                
                if(getRating(6) >= 5)
                    content.paragraph12 = strings.inStep6;
                if(getRating(9) >= 6)
                    content.paragraph13 = strings.inStep9;
                
            }
    
            if(checked[4] && getRating(8) >= 6){
                content.paragraph14 = strings.inStep8;
                haveResponse = true;
            }
    
            if(!haveResponse){
                for(let k in strings.noStepsRatedAboveModerate){
                    content[`${k}00`] = strings.noStepsRatedAboveModerate[k];
                }
            }
        }else{
            content.previousAnswer2 = {
                value: "",
                no_answer_string: strings.paragraph4_NoPreviuosAnswer
            };
        }
    }
    

    

    return (
        <RenderFromKeys content={content}/>
    );
}

export default LookingAheadKitResponse;
