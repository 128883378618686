import React, { Fragment, useContext } from "react";

import { PopupContext } from "../../Store/PopupStore";
import classes from "./popup.module.scss";
import CloseButton from "../buttons/CloseButton/CloseButton";
import { LanguageContext } from "../../Store/LanguageStore";

const Popup = () => {
  const [, closePopup, isOpen, content] = useContext(PopupContext);
  const [language] = useContext(LanguageContext);

  return (
    <Fragment>
      {isOpen && (
        <div className={classes.modalWrapper}>
          <div className={classes.modal}>
            <div className={classes.modalHeader}>
              <h2 className={classes.modalTitle}>
                {language.steppingstone_finished_title}
              </h2>
              <CloseButton
                className={classes.modalClose}
                onClick={closePopup}
              />
            </div>
            <div className={classes.modalContent}>{content}</div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Popup;
