import React, { useEffect, useState } from 'react';
import axios from "axios";
import handleError from '../lib/handleError';

export const ApplicationStatusContext = React.createContext();

const initialState = {
    applicationRunning: false,
    studyRunning: false,
}

const ApplicationStatusStore = ({children}) => {

    const [applicationStatus, setapplicationStatus] = useState(initialState);

    useEffect(()=>{
        getApplicationState();
    },[])

    const getApplicationState = async () => {
        try{
            let app_running_res = await axios.get("/utils/applicationstatus");
            let study_running_res = await axios.get("/utils/studystatus");
    
            let _applicationStatus = {...applicationStatus};
    
            _applicationStatus.applicationRunning = app_running_res.data.status;
            _applicationStatus.studyRunning = study_running_res.data.status;
    
            setapplicationStatus(_applicationStatus);
        }
        catch(e){
            handleError(e);
        }
    }

    return (
        <ApplicationStatusContext.Provider value={[applicationStatus, getApplicationState]}>
            {children}
        </ApplicationStatusContext.Provider>
    );
}

export default ApplicationStatusStore;
