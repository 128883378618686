import React, { useContext, Fragment, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import useStepsList from "../../lib/useStepsList.js";
import useRoutinesList from "../../lib/useRoutinesList.js";
import classes from "./Routines.module.scss";
import BackPack from "../../Components/icons/BackPack";
import RoutineThumbnail from "../../Components/Routine/Routine_Thumbnails/RoutineThumbnail.jsx";
import setIcon from "../../Components/icons/setIcon";
import { AccountContext } from "../../Store/AccountStore";
import { LanguageContext } from "../../Store/LanguageStore";
import Paragraph from "../../Components/SanatizedComponents/Paragraph.jsx";
import Heading from "../../Components/SanatizedComponents/Heading.jsx";

const Routines = (props) => {
  const { match } = props;
  const {
    params: { routineId },
  } = match;

  const steps = useStepsList();
  const routines = useRoutinesList();

  const currentRoutineRef = useRef();

  const [language] = useContext(LanguageContext);
  const [user] = useContext(AccountContext);
  const progress = user.progress;

  useEffect(() => {
    if (currentRoutineRef.current)
      window.scrollTo(0, currentRoutineRef.current.offsetTop - 100);
  }, [currentRoutineRef]);

  return (
    <div>
      <div className={classes.headerWrapper}>
        <header className={classes.header}>
          <BackPack base className={classes.headerIcon} />
          <Heading text={language.backpack_title} className={classes.title} />
          <Paragraph text={language.backpack_description} />
        </header>
      </div>
      <div className={classes.wrapper}>
        {progress && !progress[0].complete && (
          <div className={classes.noRoutinesMsg}>
            {language.youHaveNoUnlockedRoutines}
            <br />
            <Link to="map">
              {language.takeYourFirstStepToStartUnlockingRoutines}
            </Link>
          </div>
        )}
        {steps && (
          <ol className={classes.list}>
            {steps
              .sort(function (a, b) {
                return b.index - a.index;
              })
              .map((step, i) => {
                // if the step is the first, or one of the last two.. dont print it out\
                if (
                  routines.find(
                    (routine) => routine.fromActivity === step.index
                  ) === undefined
                )
                  return <Fragment />;
                else if (progress && progress[step.index].complete) {
                  return (
                    <li key={i}>
                      <header className={classes.stepHeader}>
                        {setIcon({
                          icon: step.icon,
                          className: classes.stepHeaderIcon,
                        })}
                        <h1>{step.title}</h1>
                      </header>
                      <ul className={classes.list}>
                        {routines.map((routine, rI) => {
                          if (routine.fromActivity === step.index) {
                            return (
                              <li className={classes.item} key={rI}>
                                <RoutineThumbnail
                                  id={rI}
                                  icon={routine.icon}
                                  title={routine.title}
                                  ref={
                                    routineId === rI ? currentRoutineRef : null
                                  }
                                />
                              </li>
                            );
                          }
                        })}
                      </ul>
                    </li>
                  );
                } else return <Fragment key={i} />;
              })}
          </ol>
        )}
      </div>

      {/* {routines && <RoutineSelection>{routinesList}</RoutineSelection>} */}
    </div>
  );
};

export default Routines;
