import { useState, useEffect } from "react";
import axios from "axios";
import handleError from "../lib/handleError";

const usePreviousValueStatements = (
  attempt = 1,
  restrictContinue,
  allowContinue
) => {
  const [valueStatements, setvalueStatements] = useState([]);
  const [loading, setloading] = useState(true);

  let source;
  useEffect(() => {
    if(restrictContinue)
      restrictContinue();
    source = axios.CancelToken.source();

    const getAnswersFromCurrentAttempt = async () => {
      try {
        const result = await axios.post("/user/answer/get", {
          task_id: 4,
          question_id: 1,
          attempt,
        },{cancelToken: source.token});

        if (result.data.found) {
          let _valueStatements = [];
          for (let i = 0; i < result.data.answer.length; i++) {
            const { answer, rank, area } = result.data.answer[i];
            const statment = {
              id: i,
              answer_text: answer,
              area: area - 1,
              rank,
            };
            _valueStatements.push(statment);
          }
          if (_valueStatements.length > 0 && allowContinue !== undefined)
            allowContinue();

          setvalueStatements(_valueStatements);
        }

        //setanswers(_answers);
        setloading(false);
      } catch (e) {
        if(!axios.isCancel(e)){
          handleError(e);
          setloading(false);
        }
        
      }
    };

    getAnswersFromCurrentAttempt();

    return () => {
      source.cancel();
    };
  }, []);

  return [valueStatements, loading, setvalueStatements];
};

export default usePreviousValueStatements;
