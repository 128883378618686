import React, { Fragment, useState } from "react";
import classes from "./BatchSearchResults.module.scss";
import Result from "./Result";
import { query } from "chartist";

const sort = (sortBy, results, reverseSort = false) => {
  switch (sortBy) {
    case "Query":
      return results.sort((a, b) => {
        let queryA = a.query;
        let queryB = b.query;

        let comparison = 0;
        if (!queryA && !queryB) {
          comparison = 0;
        } else if (!queryA) {
          comparison = -1;
        } else if (!queryB) {
          comparison = 1;
        } else {
          queryA = queryA.toUpperCase();
          queryB = queryB.toUpperCase();
          if (queryA > queryB) {
            comparison = 1;
          } else if (queryA < queryB) {
            comparison = -1;
          }
        }

        if (reverseSort) comparison = comparison * -1;
        return comparison;
      });
      break;
    case "Status":
      return results.sort((a, b) => {
        let queryA = a.status;
        let queryB = b.status;

        let comparison = 0;
        if (!queryA && !queryB) {
          comparison = 0;
        } else if (!queryA) {
          comparison = -1;
        } else if (!queryB) {
          comparison = 1;
        } else {
          queryA = queryA.toUpperCase();
          queryB = queryB.toUpperCase();
          if (queryA > queryB) {
            comparison = 1;
          } else if (queryA < queryB) {
            comparison = -1;
          }
        }

        if (reverseSort) comparison = comparison * -1;
        return comparison;
      });
      break;
    case "Email":
      return results.sort((a, b) => {
        let queryA = a.email;
        let queryB = b.email;

        let comparison = 0;
        if (!queryA && !queryB) {
          comparison = 0;
        } else if (!queryA) {
          comparison = -1;
        } else if (!queryB) {
          comparison = 1;
        } else {
          queryA = queryA.toUpperCase();
          queryB = queryB.toUpperCase();
          if (queryA > queryB) {
            comparison = 1;
          } else if (queryA < queryB) {
            comparison = -1;
          }
        }

        if (reverseSort) comparison = comparison * -1;
        return comparison;
      });
      break;
    case "Qualtrics_id":
      return results.sort((a, b) => {
        let queryA = a.qualtrics_id;
        let queryB = b.qualtrics_id;

        let comparison = 0;
        if (!queryA && !queryB) {
          comparison = 0;
        } else if (!queryA) {
          comparison = -1;
        } else if (!queryB) {
          comparison = 1;
        } else {
          queryA = queryA.toUpperCase();
          queryB = queryB.toUpperCase();
          if (queryA > queryB) {
            comparison = 1;
          } else if (queryA < queryB) {
            comparison = -1;
          }
        }

        if (reverseSort) comparison = comparison * -1;
        return comparison;
      });
      break;
    default:
      return results.sort((a, b) => {
        let queryA = a[sortBy];
        let queryB = b[sortBy] || 1;

        if (queryA === undefined) {
          queryA = 1;
        }
        if (queryB === undefined) {
          queryB = 1;
        }

        console.log(queryA);

        let comparison = queryA - queryB;

        if (reverseSort) comparison = comparison * -1;
        return comparison;
      });
      break;
  }
};

const BatchSearchResults = ({ results }) => {
  const [sortBy, setsortBy] = useState(undefined);
  const [reverseSort, setreverseSort] = useState(false);

  if (!results) {
    return <Fragment></Fragment>;
  }

  results = Object.values(results);

  if (sortBy) {
    results = sort(sortBy, results, reverseSort);
  }

  const changeSorting = (_sortBy) => {
    if (_sortBy === sortBy) {
      setreverseSort(!reverseSort);
    } else {
      setreverseSort(false);
      setsortBy(_sortBy);
    }
  };

  return (
    <div className={classes.resultsTable}>
      <div className={`${classes.cell}`}>
        <h4 className={classes.header} onClick={() => changeSorting("Query")}>
          Query
        </h4>
      </div>
      <div className={`${classes.cell}`}>
        <h4 className={classes.header} onClick={() => changeSorting("Status")}>
          Status
        </h4>
      </div>
      <div className={`${classes.cell}`}>
        <h4 className={classes.header} onClick={() => changeSorting("Email")}>
          Email
        </h4>
      </div>
      <div className={`${classes.cell}`}>
        <h4
          className={classes.header}
          onClick={() => changeSorting("Qualtrics_id")}
        >
          Qualtrics_id
        </h4>
      </div>
      <div className={`${classes.cell}`}>
        <h4
          className={classes.header}
          onClick={() => changeSorting("created_at")}
        >
          Created At
        </h4>
      </div>
      <div className={`${classes.cell}`}>
        <h4
          className={classes.header}
          onClick={() => changeSorting("current_task")}
        >
          current task
        </h4>
      </div>
      <div className={`${classes.cell}`}>
        <h4 className={classes.header}>Link</h4>
      </div>
      {results.map((result, index) => {
        return <Result result={result} index={index} />;
      })}
    </div>
  );
};

export default BatchSearchResults;
