import React, { useState, useEffect, useContext } from 'react';
import classes from "../Activity.module.scss"


import TextQuestion from '../../../Components/Questions/TextQuestion/TextQuestion';
import SliderQuestion from '../../../Components/Questions/SliderQuestion/SliderQuestion';
import LoadingScreen from '../../../Components/LoadingScreen/LoadingScreen';

import usePreviousAnswers from '../../../Hooks/usePreviousAnswers'

import {LanguageContext} from '../../../Store/LanguageStore'
import Heading from '../../../Components/SanatizedComponents/Heading'
import Paragraph from '../../../Components/SanatizedComponents/Paragraph'

import utils from '../../../utils/utils'
import PreviousAnswer from '../../../Components/PreviousAnswer/PreviousAnswer';


const Activity2_main_1 = ({task_id, attempt, allowContinue, restrictContinue}) => {
    const [language] = useContext(LanguageContext);

    const self_compassion_answers = [
        {
            value: 1,
            label: " 1 - " + language.self_compassionate_answers_1,
        },
        {
            value: 2,
            label: " 2 - " + language.self_compassionate_answers_2,
        },
        {
            value: 3,
            label: " 3 - " + language.self_compassionate_answers_3,
        },
        {
            value: 4,
            label: " 4 - " + language.self_compassionate_answers_4,
        },
        {
            value: 5,
            label: " 5 - " + language.self_compassionate_answers_5,
        },
    ]
    const questionCount = 2;
    const [answers, loading, setAnswers] = usePreviousAnswers(task_id, questionCount, attempt, allowContinue, null, 0);


    const submitAnswer = async (question_id, answer) => {
        utils.submitAnswer(task_id, question_id, answer, attempt);
        utils.addAnswerToState(question_id-1, answer, answers, setAnswers, allowContinue);
    }

    return (
        <LoadingScreen loading={loading}>
            <div className={classes.questions}>
                <Paragraph text={language.steppingstone2_paragraph3} />

                <SliderQuestion 
                    initialValue={answers[0]} 
                    submitAnswer={(answer) => submitAnswer(1, answer)} 
                    question={language.steppingstone2_question1} 
                    min={1} 
                    max={5} 
                    marks={self_compassion_answers}
                />
                <TextQuestion initialValue={answers[1]} submitAnswer={(answer) => submitAnswer(2, answer)} question={language.steppingstone2_exercise_a} hint={language.steppingstone2_exercise_a_hint} />
            </div>
        </LoadingScreen>
    );
}

export default Activity2_main_1;
