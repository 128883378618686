import React from "react";

const NoEntry = ({ className, base, baseColor = "#ddf5f5" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      {base && <circle cx="54" cy="54" r="45" fill={baseColor} />}

      <rect
        x="49.5"
        y="2.5"
        width="9"
        height="103"
        strokeWidth="2"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#404a45"
      />
      <path
        d="M32.5,84a7.16,7.16,0,0,1-7-7.29V16.29A7.16,7.16,0,0,1,32.5,9h43a7.16,7.16,0,0,1,7,7.29V76.71a7.16,7.16,0,0,1-7,7.29Z"
        fill="#faf1ee"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M32.5,79a2.19,2.19,0,0,1-2-2.29V16.29a2.19,2.19,0,0,1,2-2.29h43a2.19,2.19,0,0,1,2,2.29V76.71a2.19,2.19,0,0,1-2,2.29Z"
        fill="#00759c"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <polygon
        points="36 35.5 36 48 47.5 40.01 47.5 70 60.5 70 60.5 40.01 72 48 72 35.5 54 23 36 35.5"
        fill="#faf1ee"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default NoEntry;
