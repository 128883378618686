import React, {useContext} from 'react';

import {LanguageContext} from '../../../Store/LanguageStore'
import Paragraph from '../../../Components/SanatizedComponents/Paragraph'
import Quote from '../../../Components/SanatizedComponents/Quote'

const Activity2_intro = () => {
    const [language] = useContext(LanguageContext);
    return (
        <div>
            <Paragraph text={language.steppingstone2_paragraph1}/>
            <Paragraph text={language.steppingstone2_paragraph2}/>
            <Quote text={language.steppingstone2_quote1}/>
        </div>
    );
}

export default Activity2_intro;
