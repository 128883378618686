import React, {useState, Children} from 'react';

export const PopupContext = React.createContext();

const PopupStore = ({children}) => {

    const [isOpen, setisOpen] = useState(false);
    const [content, setcontent] = useState(<React.Fragment/>);

    const openPopup = (content) => {
        console.log("opening popup")
        setcontent(content);
        setisOpen(true);
    }

    const closePopup = () => {
        setisOpen(false);
        setcontent(undefined);
    }

    return (
        <PopupContext.Provider value={[openPopup, closePopup, isOpen, content]}>
            {children}
        </PopupContext.Provider>
    );
}

export default PopupStore;
