import React from 'react';
import TextField from '../../../../Components/Forms/TextField';
import { useContext } from 'react';
import { LanguageContext } from '../../../../Store/LanguageStore';
import { useState } from 'react';
import { useRef } from 'react';

import classes from "./emailInput.module.scss";
import Axios from 'axios';
import handleError from '../../../../lib/handleError';

const EmailInput = ({initialValue}) => {

    const [language] = useContext(LanguageContext)
    const [email, setemail] = useState(initialValue);

    const emailRef = useRef();

    const [emailHint, setemailHint] = useState("");
    const [emailValid, setemailValid] = useState(false);

    const [submittedEmail, setSubmittedEmail] = useState(null);

    const [message, setmessage] = useState(undefined);
    const [isError, setisError] = useState(false);

    const onEmailChange = (_email) => {
        
        if(_email.length > 0)
            setemail(_email)
        else
            setemail(null)

        let email = emailRef.current.value;
        setemailValid(false);
        
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(re.test(String(email).toLowerCase())){
            setemailHint("")
            setemailValid(true);
        }else{
            setemailHint({
                isError: true,
                hint: language.email_invalid
            })
        }
    }

    const submit = async () => {
        
        try{
            await Axios.post('/user/email/change',{email});

            setemailHint({
                isError: false,
                hint: language.confirmationEmailSent
            })
            setSubmittedEmail(email);
            window.setTimeout(()=>{
                setemailHint("");
            },15000)

        }catch(e){
            setemailHint({
                isError: true,
                hint: language.anErrorOccured
            })
            handleError(e);
        }

    }

    return (
        <div>
            <TextField
                label={`* ${language.email}`}
                value={email}
                hint={emailHint}
                onChange={(e) => onEmailChange(e.target.value)}
                ref={emailRef}
            />
            <div style={{display: (email != initialValue && emailValid) ? "block" : "none"}}>
                <button className={classes.button} onClick={submit}>
                    {submittedEmail == email ? language.resendEmail : language.save}
                </button>
                <button className={classes.button} onClick={() => setemail(initialValue)}>
                    {language.reset}
                </button>
            </div>
            {
                message && (
                    <div className={`${classes.message} ${isError ? classes.isError : classes.isSuccess}`}>
                        {message}
                    </div>
                )
            }
        </div>
    );
}

export default EmailInput;
