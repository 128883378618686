import React from "react";

const Logo = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 333.59 158.31"
      preserveAspectRatio="xMidYMid"
    >
      <title>MyJourney</title>
      <path d="M50,95.27,46.41,65.06l-12,24.85a1,1,0,0,1-.9.57H26.86a1,1,0,0,1-.9-.57L13.85,65.06,10.31,95.27a1,1,0,0,1-1,.89H1A1,1,0,0,1,0,95L6,46.85a1,1,0,0,1,1-.87H14a1,1,0,0,1,.9.57L30.17,78.9,45.26,46.55a1,1,0,0,1,.91-.57h7.22a1,1,0,0,1,1,.88L60.25,95a1,1,0,0,1-1,1.12H50.94A1,1,0,0,1,50,95.27Z" />
      <path d="M95,63.6,74.81,112.22a1,1,0,0,1-.92.61h-8a1,1,0,0,1-.92-1.37L72.37,93,59.58,63.62a1,1,0,0,1,.92-1.4h8.29a1,1,0,0,1,.93.62l7.75,19,7.54-19a1,1,0,0,1,.93-.63H94A1,1,0,0,1,95,63.6Z" />
      <path d="M94.9,111.34l.68-7.22a1,1,0,0,1,1.2-.88,29.47,29.47,0,0,0,5.1.49c4.44,0,6.19-3.35,6.19-7.36V47a1,1,0,0,1,1-1h8.13a1,1,0,0,1,1,1V96.37c0,9.18-4.45,16.9-16.32,16.9a30.87,30.87,0,0,1-6.25-.87A1,1,0,0,1,94.9,111.34Z" />
      <path d="M139.29,61.34c-9.69,0-18.28,7-18.28,17.77s8.59,17.7,18.28,17.7,18.28-7,18.28-17.7S149,61.34,139.29,61.34Zm9.87,19.77a9.63,9.63,0,0,1-.58,1.87,9.28,9.28,0,0,1-.92,1.73,10.49,10.49,0,0,1-1.23,1.52,10.11,10.11,0,0,1-7.08,2.92,9.24,9.24,0,0,1-2-.19,10.58,10.58,0,0,1-1.87-.57,10.15,10.15,0,0,1-1.72-.92,9.67,9.67,0,0,1-1.52-1.24A10.63,10.63,0,0,1,131,84.71a11.47,11.47,0,0,1-.92-1.73,11.2,11.2,0,0,1-.57-1.87,9.92,9.92,0,0,1,2.74-9,9.14,9.14,0,0,1,1.52-1.24,11,11,0,0,1,1.72-.93,11.45,11.45,0,0,1,1.87-.56,10,10,0,0,1,12,9.8A10.84,10.84,0,0,1,149.16,81.11Z" />
      <path d="M193.61,63.22V95.16a1,1,0,0,1-1,1h-7.54a1,1,0,0,1-1-1V90.62c-1.45,4.44-6.84,6.34-10.19,6.34-8.89,0-13.55-5.9-13.48-16.1V63.22a1,1,0,0,1,1-1h7.54a1,1,0,0,1,1,1V79.84c0,5.32,3.21,7.72,6.49,7.72s7.64-1.82,7.64-7.94V63.22a1,1,0,0,1,1-1h7.54A1,1,0,0,1,193.61,63.22Z" />
      <path d="M223.22,62.44,221,71.54a9.93,9.93,0,0,0-4.88-1.24c-4.88,0-8.23,4.52-8.23,14.2V95.16a1,1,0,0,1-1,1h-7.54a1,1,0,0,1-1-1V63.22a1,1,0,0,1,1-1h7.47a1,1,0,0,1,1,1v6.21c1.89-5.68,5.75-8.09,10-8.09A12.14,12.14,0,0,1,223.22,62.44Z" />
      <path d="M259.55,77.51V95.16a1,1,0,0,1-1,1H251a1,1,0,0,1-1-1V78.53c0-5.39-3.21-7.79-6.48-7.79-3.43,0-7.65,1.82-7.65,8V95.16a1,1,0,0,1-1,1h-7.54a1,1,0,0,1-1-1V63.22a1,1,0,0,1,1-1h7.54a1,1,0,0,1,1,1V67.9c1.45-4.59,6.84-6.56,10.19-6.56C255,61.34,259.62,67.32,259.55,77.51Z" />
      <path d="M297.55,82.25H272.86c1,3.93,4.23,6.11,8.81,6.11a12.16,12.16,0,0,0,8.24-3.09,1,1,0,0,1,1.37,0l4.25,4.25a1,1,0,0,1,0,1.38c-3.31,3.82-8.93,5.88-14.54,5.88C270.6,96.81,263,89.67,263,79.19c0-11,8.3-17.85,18.06-17.85s17.62,6.85,17.62,17.26c0,.74,0,1.62-.09,2.69A1,1,0,0,1,297.55,82.25Zm-9.62-6.19a1,1,0,0,0,1-1.21c-.81-3.28-3.9-5.2-7.74-5.2-3.47,0-6.58,1.46-7.92,5.08a1,1,0,0,0,.95,1.33Z" />
      <path d="M333.51,63.6l-20.14,48.62a1,1,0,0,1-.93.61h-8a1,1,0,0,1-.93-1.37L310.93,93,298.14,63.62a1,1,0,0,1,.91-1.4h8.29a1,1,0,0,1,.93.62l7.75,19,7.55-19a1,1,0,0,1,.93-.63h8.09A1,1,0,0,1,333.51,63.6Z" />
      <g opacity="0.5">
        <path d="M70.16,158.31a10,10,0,0,1-10-10h0a10,10,0,0,1,10-10h0a10,10,0,0,1,10,10h0a10,10,0,0,1-10,10h0Z" />
        <path d="M96.08,144.08a10,10,0,0,1,3.65-13.67h0a10,10,0,0,1,13.66,3.66h0a10,10,0,0,1-3.65,13.67h0a10,10,0,0,1-5,1.34h0A10,10,0,0,1,96.08,144.08Z" />
        <path d="M30.59,147.71h0A10,10,0,0,1,26.94,134h0a10,10,0,0,1,13.67-3.64h0a10,10,0,0,1,3.65,13.67h0a10,10,0,0,1-8.67,5h0A10,10,0,0,1,30.59,147.71Z" />
        <path d="M125,122.44a10,10,0,0,1-3.63-13.67h0a10,10,0,0,1,13.67-3.63h0a10,10,0,0,1,3.63,13.67h0a10,10,0,0,1-8.65,5h0A9.92,9.92,0,0,1,125,122.44Z" />
        <path d="M1.63,118.74h0a10,10,0,0,1,3.66-13.67h0A10,10,0,0,1,19,108.73h0a10,10,0,0,1-3.64,13.66h0a10,10,0,0,1-5,1.35h0A10,10,0,0,1,1.63,118.74Z" />
        <path d="M143.62,53.17A10,10,0,0,1,140,39.5h0a10,10,0,0,1,13.67-3.63h0a10,10,0,0,1,3.64,13.66h0a10,10,0,0,1-8.66,5h0A9.88,9.88,0,0,1,143.62,53.17Z" />
        <path d="M238.08,27.92a10,10,0,0,1-3.64-13.67h0a10,10,0,0,1,13.67-3.65h0a10,10,0,0,1,3.64,13.67h0a10,10,0,0,1-8.67,5h0A10,10,0,0,1,238.08,27.92Z" />
        <path d="M165.3,24.23A10,10,0,0,1,169,10.57h0a10,10,0,0,1,13.67,3.66h0A10,10,0,0,1,179,27.9h0a10,10,0,0,1-5,1.33h0A10,10,0,0,1,165.3,24.23Z" />
        <path d="M208.53,20a10,10,0,0,1-10-10h0a10,10,0,0,1,10-10h0a10,10,0,0,1,10,10h0a10,10,0,0,1-10,10h0Z" />
      </g>
    </svg>
  );
};

export default Logo;
