import React, { useRef, useContext } from "react";
import axios from "axios";
import classes from "./AdminLogin.module.scss";
import { LanguageContext } from "../../../Store/LanguageStore.js";
import handleError from "../../../lib/handleError.js";

const AdminLogin = ({ getAccountInfo }) => {
  const usernameRef = useRef();
  const passRef = useRef();

  const [language] = useContext(LanguageContext);

  const login = async () => {
    let username = usernameRef.current.value;
    let password = passRef.current.value;

    let data = { username, password };

    try {
      let res = await axios.post("/admin/login", data);
      getAccountInfo();
    } catch (e) {
      // ToDo: need to add output for responses
      handleError(e, true);
    }
  };

  return (
    <div className={classes.content}>
      <div>
        <h1>{"Administrator Login"}</h1>
        <div className={classes.fieldWrapper}>
          <label>{"Username"}</label>
          <input type="text" ref={usernameRef}></input>
        </div>
        <div className={classes.fieldWrapper}>
          <label>{"Password"}</label>
          <input type="password" ref={passRef}></input>
        </div>

        <div className={classes.formActions}>
          <button className={classes.buttonSubmit} onClick={login}>
            {language.login}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
