import React from "react";
import classes from "./Support.module.scss";

const SupportLinksSection = ({ section }) => {
  if (section.sections !== undefined) {
    return (
      <section className={classes.section}>
        <div className={classes.inner}>
          <h2
            className={classes.sectionTitle}
            dangerouslySetInnerHTML={{ __html: section.title }}
          ></h2>
          <div>
            {section.sections.map((subSection) => {
              return (
                <React.Fragment>
                  {/* if the subsection has a title display it */}
                  {subSection.title !== undefined &&
                  subSection.title !== null &&
                  subSection.title !== "" ? (
                    <h3
                      className={classes.sectionTitle}
                      dangerouslySetInnerHTML={{ __html: subSection.title }}
                    ></h3>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}

                  {/* display the links */}
                  {subSection.links !== undefined ? (
                    <ul className={classes.links}>
                      {subSection.links.map((link) => (
                        <li className={classes.link}>
                          <a href={link.URL}>{link.name}</a>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}

                  {/* display other text */}
                  {subSection.points !== undefined ? (
                    <ul className={classes.links}>
                      {subSection.points.map((point) => (
                        <li className={classes.link}>{point}</li>
                      ))}
                    </ul>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </section>
    );
  } else {
    console.log(section);
    throw "link grouping " + section.title + " doesn't have any subsections";
    return <React.Fragment></React.Fragment>;
  }
};

export default SupportLinksSection;
