import React, { Fragment, useContext } from "react";
import Footer from "./Footer/Footer";
import classes from "./About.module.scss";
import IconSteps from "../../Components/icons/Steps";
import Heading from "../../Components/SanatizedComponents/Heading";
import Paragraph from "../../Components/SanatizedComponents/Paragraph";

import { LanguageContext } from "../../Store/LanguageStore";

const About = () => {
  const [language] = useContext(LanguageContext);
  const strings = language.about;

  const team = [
    {
      name: strings.team_sofia_name,
      role: strings.team_sofia_role,
      blurb: strings.team_sofia_blurb,
      imgSrc: require("../../assets/images/sofia-gameiro.jpg"),
    },
    {
      name: strings.team_beth_name,
      role: strings.team_beth_role,
      blurb: strings.team_beth_blurb,
      imgSrc: require("../../assets/images/beth-rowbottom.jpg"),
    },
    {
      name: strings.team_ana_name,
      role: strings.team_ana_role,
      blurb: strings.team_ana_blurb,
      imgSrc: require("../../assets/images/ana-galhardo.jpg"),
    },
    {
      name: strings.team_pfa_name,
      blurb: strings.team_pfa_blurb,
      imgSrc: require("../../assets/images/apf.jpg"),
    },
    {
      name: strings.team_fertilityuk_name,
      blurb: strings.team_fertilityuk_blurb,
      imgSrc: require("../../assets/images/fertility-network.jpg"),
    },

    {
      name: strings.team_george_name,
      role: strings.team_george_role,
      blurb: strings.team_george_blurb,
      URL: "https://georgegoodall.github.io/",
      imgSrc: require("../../assets/images/george-goodall.jpg"),
    },
    {
      name: strings.team_marc_name,
      role: strings.team_marc_role,
      blurb: strings.team_marc_blurb,
      URL: "https://properdesign.co.uk",
      imgSrc: require("../../assets/images/proper-design.jpg"),
    },
    {
      name: strings.team_zoe_name,
      role: strings.team_zoe_role,
      blurb: strings.team_zoe_blurb,
      imgSrc: require("../../assets/images/zoe-lee.jpg"),
    },
    {
      name: strings.team_helen_name,
      role: strings.team_helen_role,
      blurb: strings.team_helen_blurb,
      imgSrc: require("../../assets/images/helen-penny.jpg"),
    },
  ];

  const supporters = [
    {
      name: strings.supporters_imst_name,
      imgSrc: require("../../assets/images/logo-ismt.jpg"),
      URL: "https://ismt.pt/",
    },
    {
      name: strings.supporters_cineicc_name,
      imgSrc: require("../../assets/images/logo-cineicc.png"),
      URL: "https://cineicc.uc.pt/",
    },
    {
      name: strings.supporters_imst_name,
      imgSrc: require("../../assets/images/logo-apm.jpg"),
      URL: "http://apmindfulness.com",
    },
    {
      name: strings.supporters_cu_name,
      imgSrc: require("../../assets/images/logo-cardiff-uni.jpg"),
      URL: "https://www.cardiff.ac.uk",
    },
    {
      name: strings.supporters_esrcfest_name,
      imgSrc: require("../../assets/images/logo-esrc-festival.jpg"),
      URL: "https://www.cardiff.ac.uk/",
    },
    {
      name: "HEFCW",
      imgSrc: require("../../assets/images/logo-hefcw.png"),
      URL: "https://www.hefcw.ac.uk/",
    },
    {
      name: "REDLARA",
      imgSrc: require("../../assets/images/logo-redlara.png"),
      URL: "https://redlara.com/",
    },
    {
      name: "Concebir",
      imgSrc: require("../../assets/images/logo-concebir.png"),
      URL: "https://www.concebir.org.ar/",
    },
    {
      name: "Asociación Red Nacional Infértiles",
      imgSrc: require("../..//assets/images/logo-associaionrednacional.png"),
      URL: "https://redinfertiles.com/",
    },
    {
      name: "Das Universitätsspital Basel",
      imgSrc: require("../../assets/images/logo-basel.png"),
      URL: "https://www.unispital-basel.ch/en/",
    },
    {
      name: "Universitätsklinikum Heidelberg",
      imgSrc: require("../../assets/images/logo-heidelberg.png"),
      URL: "https://www.klinikum.uni-heidelberg.de",
    },
    {
      name: "European Fertility Society",
      imgSrc: require("../../assets/images/logo-efs.png"),
      URL: "https://www.europeanfertilitysociety.com/",
    },
    {
      name: "British Infertility Counselling Association",
      imgSrc: require("../../assets/images/logo-bica.png"),
      URL: "https://www.bica.net/",
    },
    {
      name: "Sociedade Portuguesa de Medicina da Reprodução",
      imgSrc: require("../../assets/images/logo-spmr.png"),
      URL: "https://www.spmr.pt/",
    },
    {
      name: "Universidad Europea Madrid",
      imgSrc: require("../../assets/images/logo-uemadrid.png"),
      URL: "https://universidadeuropea.com/",
    },
  ];

  return (
    <Fragment>
      <main>
        <section className={classes.intro}>
          <div className={classes.inner}>
            <IconSteps className={classes.introIcon} base />
            <Heading text={strings.title} />
            <Paragraph text={strings.intro} />
          </div>
        </section>
        <section className={classes.whoFor}>
          <div className={classes.inner}>
            <div className={classes.whoForContent}>
              <div>
                <h3
                  dangerouslySetInnerHTML={{
                    __html: strings.who_for_title,
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: strings.who_for_intro,
                  }}
                />
              </div>
              <div>
                <h3
                  dangerouslySetInnerHTML={{
                    __html: strings.who_for_subtitle_1,
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: strings.who_for_section_1,
                  }}
                />
              </div>
              <div>
                <h3
                  dangerouslySetInnerHTML={{
                    __html: strings.who_for_subtitle_2,
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: strings.who_for_section_2,
                  }}
                />
              </div>
            </div>
          </div>
        </section>

        <section className={classes.research}>
          <div className={classes.inner}>
            <h2 dangerouslySetInnerHTML={{ __html: strings.research_title }} />
            <p
              dangerouslySetInnerHTML={{ __html: strings.research_paragraph_1 }}
            />
            <p
              dangerouslySetInnerHTML={{ __html: strings.research_paragraph_2 }}
            />
            <p
              dangerouslySetInnerHTML={{ __html: strings.research_paragraph_3 }}
            />
            <p
              dangerouslySetInnerHTML={{ __html: strings.research_paragraph_4 }}
            />
          </div>
        </section>
        <section className={classes.team}>
          <div className={classes.inner}>
            <h2 dangerouslySetInnerHTML={{ __html: strings.team_title }} />
            <ul className={classes.teamMembers}>
              {team.map((person, i) => (
                <li key={i} className={classes.member}>
                  {person.imgSrc && (
                    <div className={classes.memberImageWrapper}>
                      <img
                        className={classes.memberImage}
                        src={person.imgSrc}
                        alt={person.name}
                      />
                    </div>
                  )}
                  <div className={classes.memberContent}>
                    <h3
                      className={classes.memberName}
                      dangerouslySetInnerHTML={{ __html: person.name }}
                    />
                    {person.role && (
                      <div
                        className={classes.memberRole}
                        dangerouslySetInnerHTML={{ __html: person.role }}
                      />
                    )}
                    <div
                      className={classes.memberBlurb}
                      dangerouslySetInnerHTML={{ __html: person.blurb }}
                    />
                    {person.URL && (
                      <a href={person.URL}>{new URL(person.URL).host}</a>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </section>

        <section className={classes.acknowlegements}>
          <div className={classes.inner}>
            <h2
              dangerouslySetInnerHTML={{
                __html: strings.acknowlegements_title,
              }}
            />
            <h3
              dangerouslySetInnerHTML={{
                __html: strings.acknowlegements_ip_title,
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: strings.acknowlegements_ip_content,
              }}
            />
            <h3
              dangerouslySetInnerHTML={{
                __html: strings.acknowlegements_mindfulness_title,
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: strings.acknowlegements_mindfulness_content,
              }}
            />
            <h3
              dangerouslySetInnerHTML={{
                __html: strings.acknowlegements_wellbeing_title,
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: strings.acknowlegements_wellbeing_paragraph_1,
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: strings.acknowlegements_wellbeing_paragraph_2,
              }}
            />
          </div>
        </section>
        <section className={classes.supportersWrapper}>
          <h2 className={classes.supportersTitle}>
            {strings.supporters_title}
          </h2>
          <div className={classes.supporters}>
            {supporters.map((supporter, i) => (
              <Fragment>
                {supporter.URL && (
                  <a key={i} href={supporter.URL}>
                    <img
                      className={classes.supporterLogo}
                      alt={supporter.name && supporter.name}
                      src={supporter.imgSrc}
                    />
                  </a>
                )}
                {!supporter.URL && (
                  <img
                    key={i}
                    className={classes.supporterLogo}
                    alt={supporter.name && supporter.name}
                    src={supporter.imgSrc}
                  />
                )}
              </Fragment>
            ))}
          </div>
        </section>
      </main>
      <div className={classes.footerWrapper}>
        <Footer />
      </div>
    </Fragment>
  );
};

export default About;
