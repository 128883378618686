import React, { useState, useEffect, useContext } from "react";
import { Switch, Route, Link, useLocation, Redirect } from "react-router-dom";

import AudioWithText from "../../../Components/AudioPlayer/AudioWithText";
import Activity2_intro from "./Activity2_intro";
import Activity2_main_1 from "./Activity2_main_1";
import Activity2_main_2 from "./Activity2_main_2";
import Activity2_main_3 from "./Activity2_main_3";
import SelfCompassionReminder from "./SelfCompassionReminder";
import ActivityAssessment from "../../../Pages/ActivityAssessment/ActivityAssessment";
import ActivityWrapper from "../ActivityWrapper";
import Heart from "../../../Components/icons/Heart";

import { LanguageContext } from "../../../Store/LanguageStore";
import Heading from "../../../Components/SanatizedComponents/Heading";
import Paragraph from "../../../Components/SanatizedComponents/Paragraph";
import classes from "../Activity.module.scss";
import WHO5 from "../../../Components/WHO5/WHO5";
import WHO5Display from "../../../Components/WHO5/WHO5Display";
import { AccountContext } from "../../../Store/AccountStore";

const Activity2 = (props) => {
  const [language] = useContext(LanguageContext);
  const [user] = useContext(AccountContext);
  const task_id = 2;

  const { attempt } = props;

  const pages = [
    {
      link: "/who",
      component: () => <WHO5 task_id={task_id} attempt={attempt} />,
    },
    {
      link: "/who/display",
      component: () => <WHO5Display task_id={task_id} attempt={attempt} />,
    },
    { link: "/introduction", component: () => <Activity2_intro /> },
    {
      link: "/audio/1",
      component: () => (
        <AudioWithText
          title={language.steppingstone2_meditation1_title}
          text={language.steppingstone2_meditation1_description}
          audio={
            language.steppingstone2_meditation1_audio[user.gender || "Female"]
          }
        />
      ),
    },
    {
      link: "/activity/1",
      component: () => <Activity2_main_1 task_id={task_id} attempt={attempt} />,
    },
    {
      link: "/activity/2",
      component: () => <Activity2_main_2 task_id={task_id} attempt={attempt} />,
    },
    {
      link: "/activity/3",
      component: () => <Activity2_main_3 task_id={task_id} attempt={attempt} />,
    },
    {
      link: "/activityassessment",
      component: () => (
        <ActivityAssessment task_id={task_id} attempt={attempt} />
      ),
    },
  ];

  return (
    <div>
      <div className={classes.headerWrapper}>
        <header className={classes.header}>
          <Heart base className={classes.icon} />
          <div>
            <Heading text={language.steppingstone2_title} />
            <Paragraph text={language.steppingstone2_aim} />
          </div>
        </header>
      </div>
      <nav className={classes.breadcrumbs}>
        <Link to="/user/map">{language.map_menu}</Link>
        {" > "}
        <span dangerouslySetInnerHTML={{__html: language.steppingstone2_title}}></span>
      </nav>
      <ActivityWrapper {...props} pages={pages} task_id={task_id} />
    </div>
  );
};

export default Activity2;
