import React, { useContext } from "react";

import { Switch, Route, NavLink } from "react-router-dom";

import UserInfo from "./UserInfo/UserInfo";
import classes from "./UserSettings.module.scss";
import Heading from "../../../Components/SanatizedComponents/Heading";
import ChangePassword from "./ChangePassword/ChangePassword";
import ReminderSettings from "./ReminderSettings/ReminderSettings";
import { LanguageContext } from "../../../Store/LanguageStore";

const UserSettings = (props) => {
  const { match } = props;

  const [language] = useContext(LanguageContext);

  console.log("rerender settings");

  return (
    <div className={classes.content}>
      <Heading text={language.top_menu_settings} level="h1" />
      <nav className={classes.navbar}>
        <NavLink
          className={classes.link}
          activeClassName={classes.active}
          to={`${match.url}/info`}
        >
          {language.your_info_nav}
        </NavLink>
        <NavLink
          className={classes.link}
          activeClassName={classes.active}
          to={`${match.url}/reminders`}
        >
          {language.reminders_nav}
        </NavLink>
        <NavLink
          className={classes.link}
          activeClassName={classes.active}
          to={`${match.url}/password`}
        >
          {language.change_password_nav}
        </NavLink>
      </nav>
      <Switch>
        <Route
          path={`${match.url}/reminders`}
          component={(props) => <ReminderSettings {...props} />}
        />
        <Route
          path={`${match.url}/info`}
          component={(props) => <UserInfo {...props} />}
        />
        <Route
          path={`${match.url}/password`}
          component={(props) => <ChangePassword {...props} />}
        />
      </Switch>
    </div>
  );
};

export default UserSettings;
