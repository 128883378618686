import React, {useState, useRef,useEffect, useContext} from 'react';
import classes from './dropdown.module.scss'
import {MdArrowDropDown} from 'react-icons/md'
import { LanguageContext } from '../../../Store/LanguageStore';

const Dropdown = ({options, value = null, onChange, placeholder}) => {

    const [language] = useContext(LanguageContext);

    if(!placeholder) placeholder = language.placeholder;

    const [open, setopen] = useState(false);

    const [usingKeys, setusingKeys] = useState(false);
    
    let windowClicked = false;

    const openDropdown = () => {
        setopen(true);
        window.addEventListener('mouseup',windowClick);
    }

    const windowClick = (e) => {
        windowClicked = true;
        closeDropdown(e);
    }

    const closeDropdown = (e = null) => {
        if(e == null || (e.target != mainRef.current && e.target.parentElement != mainRef.current && e.target.children[0] != mainRef.current)){
            setopen(false);
            window.removeEventListener('mouseup',windowClick);
        }
    }

    const main_click = () => {
        if(!windowClicked){
            if(open){
                closeDropdown();
            }else{
                openDropdown();
            }
        }
    }

    const mainRef = useRef();

    const [displayedValue, setdisplayedValue] = useState("");


    let currentAnswer;
    currentAnswer = options.find(option=>option.id == value);
    currentAnswer = currentAnswer != undefined ? currentAnswer.value : placeholder;



    const onClick = (value) => {
        onChange(value)
        setopen(false);
    }

    const keyDown = (e) => {
        switch(e.which) {
            case 38: // up
                setusingKeys(true);
                if(value-1>0){
                    onChange(value-1);
                }
                e.preventDefault();
                break;
    
            case 40: // down
                setusingKeys(true);
                if(value == null){
                    onChange(1);
                }
                else if(value+1<=options.length){
                    onChange(value+1);
                }
                e.preventDefault();
                break;

            case 13: // enter
                setopen(!open);
                break;

            
            case 9: // tab
                setopen(false);
                break;

            default: return; // exit this handler for other keys
        }
    }


    return (
        <div className={classes.dropdown} tabIndex="0" onKeyDown={keyDown}>
            <div className={classes.dropdown_main} onClick={main_click} ref={mainRef}>
                <div className={classes.dropdown_main_content}>
                    {currentAnswer}
                </div>
                <MdArrowDropDown className={classes.dropdown_arrow}/>
            </div>
            <div className={classes.dropdown_list} style={{display: open ? "block" : "none"}} onMouseMove={()=>setusingKeys(false)}>
                {
                    <div className={`${classes.dropdown_option} ${classes.placeholder}`}>
                        {placeholder}
                    </div>    
                }
                {
                    options.map((option)=>{
                        return (
                            <div key={option.id} className={`${classes.dropdown_option} ${usingKeys ? classes.noHover : ""} ${usingKeys && option.id == value ? classes.selected : ""}`} onClick={()=>onClick(option.id)}>
                                {option.value}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default Dropdown;
