import React from 'react';
import DOMPurify from 'dompurify'
import useParseStringLinks from '../../Hooks/useParseStringLinks';


const Paragraph = ({ text, className }) => {

    const {parseSupportLinks} = useParseStringLinks()

    return (
        <p className={className}>{parseSupportLinks(text.replace(/\n/g, "<br />"),true)}</p>
    );
}

export default Paragraph;
