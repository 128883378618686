import React from "react";

const Ticket = ({ className, base, baseColor = "#ddf5f5" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      {base && <circle cx="54" cy="54" r="45" fill={baseColor} />}

      <path
        d="M87.75,35.25a5.31,5.31,0,0,1,0-7.5l-7.5-7.5a5.3,5.3,0,0,1-7.5-7.5L69,9,9,69l3.75,3.75a5.3,5.3,0,0,1,7.5,7.5l7.5,7.5a5.3,5.3,0,0,1,7.5,7.5L39,99,99,39l-3.75-3.75A5.31,5.31,0,0,1,87.75,35.25Z"
        fill="#e6bbaa"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M66.52,36.51l-30,30a3.51,3.51,0,0,0,5,5l30-30a3.51,3.51,0,0,0-5-5Z"
        fill="#231f20"
      />
      <path
        d="M75.67,45.67l-30,30a1.17,1.17,0,0,0,1.66,1.66l30-30a1.17,1.17,0,0,0-1.66-1.66Z"
        fill="#231f20"
      />
      <path
        d="M32.33,62.33l30-30a1.17,1.17,0,0,0-1.66-1.66l-30,30a1.17,1.17,0,0,0,1.66,1.66Z"
        fill="#231f20"
      />
    </svg>
  );
};

export default Ticket;
