import React from 'react';
import Axios from 'axios';
import FileDownload from 'js-file-download';
import handleError from '../../../../lib/handleError';
import { useState, useEffect } from 'react';


const Languages = () => {

    const [file, setfile] = useState(undefined);
    const [commitMessage, setCommitMessage] = useState("");
    const [languageCommits, setLanguageCommits] = useState([]);
    const [numPrevCommits, setnumPrevCommits] = useState(10);
    const [currentCommitHash, setcurrentCommitHash] = useState("");

    const downloadLanguagesCSV = () => {
        
        Axios.post('/admin/getLanguageExcel',null,{responseType: 'arraybuffer'}).then((res)=>{
            var data = res.data; //from server
            var blob = new Blob([data], { type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml;charset=UTF-8"});
            var link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'myJourneyLanguages.xlsx';
            link.click();
        }).catch((e)=>{
            alert("an error occured, please check the system logs");
        });
        
    }

    const onFileUpload = (e) => {
        setfile(e.target.files[0]);
    }

    const upload = async () => {
        if(file){
            const data = new FormData() 
            data.append('file', file);
            data.append('commitMessage',commitMessage);

            try{
                await Axios.post('/admin/uploadLanguageExcel', data);
                alert("Success");
            }catch(e){
                handleError(e, true);
            }
        }
    }

    const changeCommitMessage = (e) => {
        setCommitMessage(e.target.value);
    }

    const revertToLanguage = async (i) => {
        let {message,date,hash} = languageCommits[i];
        if(window.confirm("are you sure you want to revert to the language file from: \n\n " + date + " \n\nwith message:\n\n " + message)){
            
            try{
                let result = await Axios.post('/admin/revertToCommit',{hash});
                setcurrentCommitHash(hash);
                alert("Success");

            }catch(e){
                handleError(e,true)
            }
            
            
        }
    }


    useEffect(() => {

        const getCommits = async () => {
            let commits = await Axios.get('/admin/getLanguageGitCommits')
            
            console.log(commits);
    
            setLanguageCommits(commits.data.all);
            setcurrentCommitHash(commits.data.currentHash);
        }

        getCommits();

    }, []);

    return (
        <div>
            <button onClick={downloadLanguagesCSV}>Download languages Excel</button>
            <br/>
            <br/>
            <div>
                <h2 style={{margin: 0}}>Upload languages Excel: </h2>
                <input type="file" name="file" onChange={onFileUpload}/>
                <h3 style={{margin: "0.5em 0 0 0"}}>Commit Message:</h3>
                <p style={{margin: 0}}> this is used to reference the change in language so you can revert back at any time</p>
                <p style={{margin: 0}}>I.E. updated patients links, added new language German or reworded home screen intro</p>
                <input type="text" name="commitMessage" onChange={changeCommitMessage} value={commitMessage} placeholder="Commit Message: (Name/Description to identify this language change)"/>
                <button onClick={upload}>Upload</button>
            </div>
            <div>
                <h2>Previous Language Updates</h2>
                {languageCommits.map((commit,i)=>{
                    if(i < numPrevCommits){
                        return <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "1em", backgroundColor: commit.hash == currentCommitHash ? 'lightgreen' : (i % 2 == 0) ? '#dddddd' : 'white', padding: "0.4em 0em"}}>
                            <div style={{overflowWrap: "break-word", overflow: "hidden", inlineSize: "100%"}}>{new Date(commit.date).toLocaleDateString('en-GB') + " "+ new Date(commit.date).toLocaleTimeString()}</div>
                            <div style={{overflowWrap: "break-word", overflow: "hidden", inlineSize: "100%"}}>{commit.message}</div>
                            <div>
                                {
                                    commit.hash == currentCommitHash 
                                    ? 
                                    <div style={{overflowWrap: "break-word", overflow: "hidden", inlineSize: "100%"}}>Current Commit in use</div>
                                    :
                                    <button onClick={()=>revertToLanguage(i)}>Revert to this language file</button>

                                }
                            </div>
                        </div>
                    }
                })}
                <p style={{color: "blue", cursor: "pointer"}}
                    onClick={
                    ()=>setnumPrevCommits((n)=>{
                        let newVal = n + 10;
                        newVal = Math.min(newVal,languageCommits.length);
                        return newVal;
                    })
                }>show more</p>
            </div>
            <br/>
            <br/>
        </div>
    );
}

export default Languages;
