import React, {useContext} from "react";
import useWho5Score from "../../Hooks/useWho5Score";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import WHOChart from "./WHOChart";
import WHODetails from "./WHODetails";
import { AccountContext } from "../../Store/AccountStore";

const WHO5Display = ({ task_id, attempt }) => {
  const [score, loading] = useWho5Score(task_id, attempt);

  const [user,,loadingUser] = useContext(AccountContext);

  return (
    <LoadingScreen loading={loading || loadingUser}>
      <WHOChart score={score} country={user.country} gender={user.gender} />
      <WHODetails score={score} country={user.country} gender={user.gender} />
    </LoadingScreen>
  );
};

export default WHO5Display;
