import React, { useEffect, useState, useContext } from 'react';

import LoadingScreen from '../../../Components/LoadingScreen/LoadingScreen';

import {LanguageContext} from '../../../Store/LanguageStore'
import Heading from '../../../Components/SanatizedComponents/Heading'
import Paragraph from '../../../Components/SanatizedComponents/Paragraph'
import Quote from '../../../Components/SanatizedComponents/Quote'

import classes from "../Activity.module.scss"


const Activity1_questions = ({attempt, allowContinue, restrictContinue}) => {
    const [language] = useContext(LanguageContext);
    return (
        <div className={classes.questions}>
            <Paragraph text={language.steppingstone1_paragraph6} />
            <Quote text={language.steppingstone1_quote9}/>
            <Quote text={language.steppingstone1_quote10}/>

            <Heading level="h2" text={language.steppingstone1_statement5} />
        </div>
    );

}

export default Activity1_questions;
