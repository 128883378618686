import React from "react";
import Paragraph from "../SanatizedComponents/Paragraph";
import UnorderedList from "../SanatizedComponents/UnorderedList";
import TextQuestion from "../Questions/TextQuestion/TextQuestion";
import MultipleChoiceQuestion from "../Questions/MultipleChoiceQuestion/MultipleChoiceQuestion";
import PreviousAnswer from "../PreviousAnswer/PreviousAnswer";
import Heading from "../SanatizedComponents/Heading";
import OrderedList from "../SanatizedComponents/OrderedList";
import styles from "./styles.module.scss";

const RenderFromKeys = ({ content, questionIndexStart = 0 }) => {
  let questionIndex = questionIndexStart;

  return (
    <React.Fragment>
      {typeof content === "object" ? (
        Object.keys(content).map((key) => {
          if (/subSubSubHeader[_]?\d*/i.test(key)) {
            return <Heading key={key} text={content[key]} level="h4"/>
          } else if (/subSubHeader[_]?\d*/i.test(key)) {
            return <Heading key={key} text={content[key]} level="h3"/>
          } else if (/subHeader[_]?\d*/i.test(key)) {
            return <Heading key={key} text={content[key]} level="h2"/>
          } else if (/header[_]?\d*/i.test(key)) {
            return <Heading key={key} text={content[key]}/>
          } else if (/paragraph[_]?\d*/i.test(key)) {
            return <Paragraph key={key} text={content[key]} />;
          } else if (/bullets[_]?\d*$/i.test(key)) {
            return <UnorderedList key={key} items={content[key]} />;
          } else if (/bulletsNumbered[_]?\d*$/i.test(key)) {
            return <OrderedList key={key} items={content[key]} />;
          } else if (/facts[_]?\d*$/i.test(key)) {
            return <OrderedList key={key} items={content[key]} className={styles.numberedBlocks}/>;
          } else if (/textQuestion[_]?\d*$/i.test(key)) {
            if (typeof content[key] == "object")
              return (
                <TextQuestion
                  key={key}
                  question={content[key].question}
                  submitAnswer={content[key].submit}
                  initialValue={content[key].value}
                  hint={content[key].hint}
                  delay={content[key].delay}
                />
              );
            else return <TextQuestion key={key} question={content[key]} />;
          } else if (/multipleChoiceQuestion[_]?\d*$/i.test(key)) {
            return (
              <MultipleChoiceQuestion
                key={key}
                question={content[key].question}
                answers={content[key].answers}
                submit={content[key].submit}
                checked={content[key].checked}
              />
            );
          } else if (/previousAnswer[_]?\d*$/i.test(key)) {
            if(typeof content[key] === "object"){
              return <PreviousAnswer key={key} content={content[key].value} no_answer_string={content[key].no_answer_string}/>;
            }
            else
              return <PreviousAnswer key={key} content={content[key]} />;
          } else if (/hint/i.test(key)) {
            // do nothing if its a hint
          } else {
            throw "routine description contains invalid key: " + key;
          }
        })
      ) : (
        <Paragraph text={content} />
      )}
    </React.Fragment>
  );
};

export default RenderFromKeys;
