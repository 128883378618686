import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import Logo from "./Logo/Logo";
import classes from "./header.module.scss";
import { AccountContext } from "../../Store/AccountStore";
import LanguageSelection from "./Navbar/LanguageSelection.jsx";
const Header = () => {
  const [user, setuser] = useContext(AccountContext);

  return (
    <div className={classes.header}>
      <div className={classes.logoWrapper}>
        <Link to={!user.id ? "/" : "/user/map"}>
          <Logo className={classes.logo} />
        </Link>
      </div>

      <LanguageSelection className={classes.languageWrapper} />
      <Navbar className={classes.navWrapper} />
    </div>
  );
};

export default Header;
