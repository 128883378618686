import React, { useContext } from "react";

import Paragraph from "../../../Components/SanatizedComponents/Paragraph";
import Quote from "../../../Components/SanatizedComponents/Quote";

import { LanguageContext } from "../../../Store/LanguageStore";
const Activity5_Intro = () => {
  const [language] = useContext(LanguageContext);
  return (
    <div>
      <Paragraph text={language.steppingstone5_paragraph1} />
      <Paragraph text={language.steppingstone5_paragraph2} />
      <Quote text={language.steppingstone5_quote1} />
      <Quote text={language.steppingstone5_quote2} />
      <Quote text={language.steppingstone5_quote3} />
      <Quote text={language.steppingstone5_quote4} />
    </div>
  );
};

export default Activity5_Intro;
