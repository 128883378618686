import React, { useContext } from "react";
import averages from "../../lib/WHOAverages";
import classes from "./WHODetails.module.scss";
import { LanguageContext } from "../../Store/LanguageStore";

import Heading from "../SanatizedComponents/Heading";
import Paragraph from "../SanatizedComponents/Paragraph";

import useParseStringLinks from "../../Hooks/useParseStringLinks";

const WHODetails = ({ score, country, gender }) => {
  const [language] = useContext(LanguageContext);

  const { parseSupportLinks } = useParseStringLinks();

  const average = averages[country][gender];

  let yourScoreIs = `<strong>${language.whoChart_your_wellbeing_score_is} ${score}.</strong><br />`;

  return (
    <div>
      <Heading text={language.whoChart_title} level="h2" />
      <Paragraph
        text={`${language.whoChart_opener} ${language.whoChart_gender[gender]} ${language.whoChart_living_in[country]} ${language.whoChart_score} ${average}.`}
      />
      <ol className={classes.scoreDefinitions}>
        <li
          className={
            score > 50
              ? `${classes.scoreDefinitionHigh} ${classes.current}`
              : classes.scoreDefinitionHigh
          }
        >
          {parseSupportLinks(
            (score > 50 ? yourScoreIs : "") + language.whoChart_score_above_50,
            true
          )}
        </li>

        <li
          className={
            score > 27 && score < 50
              ? `${classes.scoreDefinitionMed} ${classes.current}`
              : classes.scoreDefinitionMed
          }
        >
          {parseSupportLinks(
            (score > 27 && score < 51 ? yourScoreIs : "") +
              language.whoChart_score_above_28,
            true
          )}
        </li>

        <li
          className={
            score < 28
              ? `${classes.scoreDefinitionLow} ${classes.current}`
              : classes.scoreDefinitionLow
          }
        >
          {parseSupportLinks(
            (score < 28 ? yourScoreIs : "") + language.whoChart_score_lower_28,
            true
          )}
        </li>
      </ol>
    </div>
  );
};

export default WHODetails;
