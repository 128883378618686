import React from "react";

const Heart = ({ className, base, baseColor = "#ddf5f5" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      {base && <circle cx="54" cy="54" r="45" fill={baseColor} />}

      <path
        d="M78.88,68.91,87.53,74s6-7.39,7.79-6.52-2,7.61-2,7.61,8-.37,7.71,2-7.44,2.37-7.44,2.37,5.36,4.73,3.45,6.1-6.08-2.66-6.08-2.66,2,5.59,0,6.26-4.82-9.53-4.82-9.53L75.39,74Zm-49.76,0L20.47,74s-6-7.39-7.79-6.52,2,7.61,2,7.61-8-.37-7.71,2,7.44,2.37,7.44,2.37S9.07,84.2,11,85.57s6.08-2.66,6.08-2.66-2,5.59,0,6.26,4.82-9.53,4.82-9.53L32.61,74Z"
        fill="#db6b5e"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M101,41.31c0-29.21-35.27-29.21-47-9.74C42.25,12.1,7,12.1,7,41.31,7,60.79,24.61,75.39,54,90,83.39,75.39,101,60.79,101,41.31Z"
        fill="#db6b5e"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Heart;
