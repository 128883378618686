import React from "react";

const Mindful = ({ className, base, baseColor = "#ddf5f5" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      {base && <circle cx="54" cy="54" r="45" fill={baseColor} />}

        <path d="M27,62c-.67-6.77-7.48-25.84,2.46-37.56C35,17.9,43.28,14.57,54,14.57s19,3.33,24.54,9.88C88.48,36.16,81.67,55.24,81,62l-.5-8C80.5,72,66,90,54,90S27.5,72,27.5,54" fill="#d6bb9a" stroke="#000" 
        strokeLinecap="round" 
        strokeLinejoin="round" 
        strokeWidth="2"/>
    
      
        <polyline points="82 70.42 82 67.28 82 65" fill="none" stroke="#231f20" 
        strokeLinejoin="round" 
        strokeWidth="2"/>
        <path d="M82,76.45c0-3,4,0,4-3s-4,0-4-3" fill="none" stroke="#231f20" 
        strokeLinejoin="round" 
        strokeWidth="2"/>
      
      <path d="M82,76.45c0,3,4,0,4,3s-4,0-4,3" fill="none" stroke="#231f20" 
      strokeLinejoin="round" 
      strokeWidth="2"/>
      <path d="M82,82.48c0,3,4,0,4,3s-4,0-4,3" fill="none" stroke="#231f20" 
      strokeLinejoin="round" 
      strokeWidth="2"/>
      <path d="M82,88.52c0,3,4,0,4,3s-4,0-4,3" fill="none" stroke="#231f20" 
      strokeLinejoin="round" 
      strokeWidth="2"/>
      
        <polyline points="82 100.58 82 103.72 82 106" fill="none" stroke="#231f20" 
        strokeLinejoin="round" 
        strokeWidth="2"/>
        <path d="M82,94.55c0,3,4,0,4,3s-4,0-4,3" fill="none" stroke="#231f20" 
        strokeLinejoin="round" 
        strokeWidth="2"/>
      
    
    <path d="M36,53.81c0-3.65,2.69-6.61,6-6.61s6,3,6,6.61S36,57.46,36,53.81Z" fill="#a18c73"/>
    <path d="M60,53.81c0-3.65,2.69-6.61,6-6.61s6,3,6,6.61S60,57.46,60,53.81Z" fill="#a18c73"/>
    
      <path d="M60,54s1.2,3,6,3,6-3,6-3" fill="none" stroke="#000" 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      strokeWidth="2"/>
      <path d="M36,54s1.2,3,6,3,6-3,6-3" fill="none" stroke="#000" 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      strokeWidth="2"/>
      <path d="M47.5,67A14.46,14.46,0,0,0,54,69a14.46,14.46,0,0,0,6.5-2" fill="none" stroke="#000" 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      strokeWidth="2"/>
      <path d="M64.5,74A64.34,64.34,0,0,1,54,75a64.34,64.34,0,0,1-10.5-1" fill="none" stroke="#000" 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      strokeWidth="2"/>
    
    
      <path d="M21,62A1.76,1.76,0,0,1,18,61c-1-12.38-1-49.38,36-49.38S91.06,48.61,90,61A1.76,1.76,0,0,1,87,62H81c.67-6.77,7.48-25.85-2.46-37.56C73,17.9,64.72,14.57,54,14.57S35,17.9,29.46,24.45C19.52,36.17,26.33,55.24,27,62Z" fill="#fabb26" stroke="#000" 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      strokeWidth="2"/>
      <path d="M29.88,67H27c-12,0-12-27,0-27h.77l1.52.29h0A52.4,52.4,0,0,0,27.5,54,38.53,38.53,0,0,0,29.88,67ZM81,40h-.77l-1.52.29h0A52.4,52.4,0,0,1,80.5,54a38.53,38.53,0,0,1-2.38,13H81C93,67,93,40,81,40Z" stroke="#000" 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      strokeWidth="2"/>
    
    <path d="M78.54,24.45C73,17.9,64.72,14.57,54,14.57S35,17.9,29.46,24.45c-5.06,6-5.78,13.83-5.18,21l.72.28C45,36,36,22.25,36,22.25A103.76,103.76,0,0,0,81,45.34l2,.41.72-.33C84.31,38.25,83.59,30.4,78.54,24.45Z" fill="#80918b" stroke="#000" 
    strokeLinecap="round" 
    strokeLinejoin="round" 
    strokeWidth="2"/>
  
    </svg>
  );
};

export default Mindful;
