import React, { Fragment, useContext } from "react";
import Footer from "./Footer/Footer";
import styles from "./Patients.module.scss";
import Steps from "../../Components/icons/Steps";
import classes from "./Support.module.scss";

import { LanguageContext } from "../../Store/LanguageStore";
import RenderFromKeys from "../../Components/RenderFromKeys/RenderFromKeys";
import SupportLinksSection from "./SupportLinksSection";
import PatientsNav from "../../Components/PatientsNav";

const NotReady = () => {
  const [language] = useContext(LanguageContext);

  return (
    <Fragment>
      <main>
        <section className={styles.intro}>
          <div className={styles.inner}>
            <Steps className={styles.introIcon} base />
            <RenderFromKeys content={language.otherRoutes.content} />
          </div>
        </section>
        <PatientsNav />
        <div className={classes.sections}>
          {language.otherRoutes.Links.map((section) => (
            <SupportLinksSection section={section} />
          ))}
        </div>
      </main>
      <div className={styles.footerWrapper}>
        <Footer />
      </div>
    </Fragment>
  );
};

export default NotReady;
