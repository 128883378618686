import React, { useEffect, useState, useContext } from "react";
import usePreviousAnswers from "../../../Hooks/usePreviousAnswers";
import axios from "axios";
import TextQuestion from "../../../Components/Questions/TextQuestion/TextQuestion";
import utils from "../../../utils/utils";

import LoadingScreen from "../../../Components/LoadingScreen/LoadingScreen";

import Heading from "../../../Components/SanatizedComponents/Heading";
import Paragraph from "../../../Components/SanatizedComponents/Paragraph";
import PreviousAnswer from "../../../Components/PreviousAnswer/PreviousAnswer";
import { LanguageContext } from "../../../Store/LanguageStore";
import handleError from "../../../lib/handleError";
import useValueStatmentStrings from "../../../Hooks/useValueStatmentStrings";

const Activity6_main_1 = ({ attempt, allowContinue, restrictContinue }) => {
  const [language] = useContext(LanguageContext);
  const initialStatements = useValueStatmentStrings();
  const task_id = 6;
  const questionCount = 4;
  const firstQuestionIndex = 0;
  const [valueDomainIndex, setvalueDomainIndex] = useState(0);
  const [valueDomainAnswer, setvalueDomainAnswer] = useState("");
  const [answers, loading, setAnswers] = usePreviousAnswers(
    task_id,
    questionCount,
    attempt,
    allowContinue,
    null,
    firstQuestionIndex
  );

  const _submitAnswer = async (question_id, answer) => {
    utils.submitAnswer(task_id, question_id, answer, attempt);
    utils.addAnswerToState(
      question_id - 1,
      answer,
      answers,
      setAnswers,
      allowContinue
    );
  };

  let source;
  useEffect(() => {
    source = axios.CancelToken.source();

    const getValueStatement = async () => {
      try {
        let answer = await axios.post(
          "/user/answer/get",
          {
            task_id: 4,
            question_id: 1,
          },
          { cancelToken: source.token }
        );

        if (answer.data.found) {
          setvalueDomainIndex(answer.data.answer[0].area - 1);
          setvalueDomainAnswer(answer.data.answer[0].answer);
        } else {
          return false;
        }
      } catch (e) {
        if (!axios.isCancel(e)) {
          handleError(e);
        }
        return false;
      }
    };

    getValueStatement();

    return () => {
      source.cancel();
    };
  }, []);

  return (
    <LoadingScreen loading={loading}>
      <div>
        <Paragraph text={language.steppingstone6_paragraph3} />
        <PreviousAnswer
          title={initialStatements[valueDomainIndex].area}
          content={valueDomainAnswer}
          no_answer_string={
            language.steppingstone6_paragraph3__NoPreviousAnswer
          }
        />
        <Paragraph text={language.steppingstone6_paragraph4} />
        <Paragraph text={language.steppingstone6_paragraph5} />
        <Heading text={language.steppingstone6_longtermgoal} level="h3" />
        <TextQuestion
          initialValue={answers[0]}
          submitAnswer={(answer) => _submitAnswer(1, answer)}
          question=""
          hint={language.steppingstone6_longtermgoal_hint}
          height="s"
        />
        <Heading text={language.steppingstone6_shorttermgoals} level="h3" />
        <TextQuestion
          initialValue={answers[1]}
          submitAnswer={(answer) => _submitAnswer(2, answer)}
          question={language.steppingstone6_goal1}
          hint={language.steppingstone6_goal1_hint}
          height="s"
        />
        <TextQuestion
          initialValue={answers[2]}
          submitAnswer={(answer) => _submitAnswer(3, answer)}
          question={language.steppingstone6_goal2}
          hint={language.steppingstone6_goal2_hint}
          height="s"
        />
        <TextQuestion
          initialValue={answers[3]}
          submitAnswer={(answer) => _submitAnswer(4, answer)}
          question={language.steppingstone6_goal3}
          hint={language.steppingstone6_goal3_hint}
          height="s"
        />
      </div>
    </LoadingScreen>
  );
};

export default Activity6_main_1;
