import React, { useEffect, useState, useContext, Fragment } from "react";
import TextQuestion from "../../../Components/Questions/TextQuestion/TextQuestion";
import axios from "axios";
import utils from "../../../utils/utils";
import usePreviousAnswers from "../../../Hooks/usePreviousAnswers";
import LoadingScreen from "../../../Components/LoadingScreen/LoadingScreen";

import Paragraph from "../../../Components/SanatizedComponents/Paragraph";
import UnorderedList from "../../../Components/SanatizedComponents/UnorderedList";
import { LanguageContext } from "../../../Store/LanguageStore";
import PreviousAnswer from "../../../Components/PreviousAnswer/PreviousAnswer";
import handleError from "../../../lib/handleError";
const task_id = 9;
const Activity9_main_1 = ({ attempt }) => {
  const [language] = useContext(LanguageContext);
  const questionCount = 3;

  const [longTermGoal, setlongTermGoal] = useState("");
  const [shortTermGoals, setshortTermGoals] = useState([]);
  const [answers, loading] = usePreviousAnswers(task_id, questionCount);

  useEffect(() => {
    let source = axios.CancelToken.source();

    const getGoals = async () => {
      try {
        let goalPromises = [];
        for (let i = 1; i <= 4; i++) {
          goalPromises.push(
            axios.post(
              "/user/answer/get",
              {
                task_id: 6,
                question_id: i,
              },
              { cancelToken: source.token }
            )
          );
        }
        let results = await Promise.all(goalPromises);
        if (results[0].data.found) setlongTermGoal(results[0].data.answer);

        let _shortTermGoals = [];
        for (let i = 1; i < results.length; i++) {
          if (results[i].data.found) {
            _shortTermGoals.push(results[i].data.answer);
          }
        }
        setshortTermGoals(_shortTermGoals);
      } catch (e) {
        if (!axios.isCancel(e)) {
          handleError(e);
        }
      }
    };

    getGoals();
  }, []);

  const _submitAnswer = async (question_id, answer) => {
    utils.submitAnswer(task_id, question_id, answer, attempt);
  };

  return (
    <LoadingScreen loading={loading}>
      <div>
        <Paragraph text={language.steppingstone9_paragraph3} />
        <PreviousAnswer
          content={
            longTermGoal || shortTermGoals.length > 0 ? (
              <Fragment>
                <Paragraph text={longTermGoal} />
                <UnorderedList items={shortTermGoals} />
              </Fragment>
            ) : null
          }
          no_answer_string={language.steppingstone9_paragraph3_NoPreviousAnswer}
        />
        <Paragraph text={language.steppingstone9_statement1} />

        <TextQuestion
          initialValue={answers[0]}
          submitAnswer={(answer) => _submitAnswer(1, answer)}
          question={language.steppingstone9_exercise_a}
          hint={language.steppingstone9_exercise_a_hint}
        />
        <TextQuestion
          initialValue={answers[1]}
          submitAnswer={(answer) => _submitAnswer(2, answer)}
          question={language.steppingstone9_exercise_b}
          hint={language.steppingstone9_exercise_b_hint}
        />
        <TextQuestion
          initialValue={answers[2]}
          submitAnswer={(answer) => _submitAnswer(3, answer)}
          question={language.steppingstone9_exercise_c}
          hint={language.steppingstone9_exercise_c_hint}
        />
      </div>
    </LoadingScreen>
  );
};

export default Activity9_main_1;
