import React, { useContext } from "react";
import classes from "./WHOChart.module.scss";
import averages from "../../lib/WHOAverages";
import { LanguageContext } from "../../Store/LanguageStore";

// this SVG is 500 units wide so we have to adjust for that
const scoreToXCoords = (score) => {
  return score * 5;
};

const scoreToColor = (score) => {
  if (score < 28) {
    return "#aca4c1";
  } else if (score < 50) {
    return "#ddf5f5";
  } else {
    return "#bfdcc1";
  }
};

const WHOChart = ({ score, country, gender }) => {
  const [language] = useContext(LanguageContext);
  const average = averages[country][gender];

  return (
    <svg
      className={classes.chart}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-25 0 550 250"
    >
      <g className="grid-twos" fill="none" stroke="#ddf5f5" strokeWidth="0.5">
        <line x1="490" y1="24.99" x2="490" y2="224.99" />
        <line x1="480" y1="24.99" x2="480" y2="224.99" />
        <line x1="470" y1="24.99" x2="470" y2="224.99" />
        <line x1="460" y1="24.99" x2="460" y2="224.99" />
        <line x1="450" y1="24.99" x2="450" y2="224.99" />
        <line x1="440" y1="24.99" x2="440" y2="224.99" />
        <line x1="430" y1="24.99" x2="430" y2="224.99" />
        <line x1="420" y1="24.99" x2="420" y2="224.99" />
        <line x1="410" y1="24.99" x2="410" y2="224.99" />
        <line x1="400" y1="24.99" x2="400" y2="224.99" />
        <line x1="390" y1="24.99" x2="390" y2="224.99" />
        <line x1="380" y1="24.99" x2="380" y2="224.99" />
        <line x1="370" y1="24.99" x2="370" y2="224.99" />
        <line x1="360" y1="24.99" x2="360" y2="224.99" />
        <line x1="350" y1="24.99" x2="350" y2="224.99" />
        <line x1="340" y1="24.99" x2="340" y2="224.99" />
        <line x1="330" y1="24.99" x2="330" y2="224.99" />
        <line x1="320" y1="24.99" x2="320" y2="224.99" />
        <line x1="310" y1="24.99" x2="310" y2="224.99" />
        <line x1="300" y1="24.99" x2="300" y2="224.99" />
        <line x1="290" y1="24.99" x2="290" y2="224.99" />
        <line x1="280" y1="24.99" x2="280" y2="224.99" />
        <line x1="270" y1="24.99" x2="270" y2="224.99" />
        <line x1="260" y1="24.99" x2="260" y2="224.99" />
        <line x1="250" y1="24.99" x2="250" y2="224.99" />
        <line x1="240" y1="24.99" x2="240" y2="224.99" />
        <line x1="230" y1="24.99" x2="230" y2="224.99" />
        <line x1="220" y1="24.99" x2="220" y2="224.99" />
        <line x1="210" y1="24.99" x2="210" y2="224.99" />
        <line x1="200" y1="24.99" x2="200" y2="224.99" />
        <line x1="190" y1="24.99" x2="190" y2="224.99" />
        <line x1="180" y1="24.99" x2="180" y2="224.99" />
        <line x1="170" y1="24.99" x2="170" y2="224.99" />
        <line x1="160" y1="24.99" x2="160" y2="224.99" />
        <line x1="150" y1="24.99" x2="150" y2="224.99" />
        <line x1="140" y1="24.99" x2="140" y2="224.99" />
        <line x1="130" y1="24.99" x2="130" y2="224.99" />
        <line x1="120" y1="24.99" x2="120" y2="224.99" />
        <line x1="110" y1="24.99" x2="110" y2="224.99" />
        <line x1="100" y1="24.99" x2="100" y2="224.99" />
        <line x1="90" y1="24.99" x2="90" y2="224.99" />
        <line x1="80" y1="24.99" x2="80" y2="224.99" />
        <line x1="70" y1="24.99" x2="70" y2="224.99" />
        <line x1="60" y1="24.99" x2="60" y2="224.99" />
        <line x1="50" y1="24.99" x2="50" y2="224.99" />
        <line x1="40" y1="24.99" x2="40" y2="224.99" />
        <line x1="30" y1="24.99" x2="30" y2="224.99" />
        <line x1="20" y1="24.99" x2="20" y2="224.99" />
        <line x1="10" y1="24.99" x2="10" y2="224.99" />
      </g>
      <g className="grid-tens" fill="none" stroke="#ddf5f5" strokeWidth="0.75">
        <line x1="499.5" y1="25" x2="499.5" y2="225" />
        <line x1="450" y1="25" x2="450" y2="225" />
        <line x1="400" y1="25" x2="400" y2="225" />
        <line x1="350" y1="25" x2="350" y2="225" />
        <line x1="300" y1="25" x2="300" y2="225" />
        <line x1="250" y1="25" x2="250" y2="225" />
        <line x1="200" y1="25" x2="200" y2="225" />
        <line x1="150" y1="25" x2="150" y2="225" />
        <line x1="100" y1="25" x2="100" y2="225" />
        <line x1="50" y1="25" x2="50" y2="225" />
        <line x1="0.5" y1="25" x2="0.5" y2="225" />
      </g>

      <g
        className={classes.xAxis}
        fill="#ddf5f5"
        fontSize="12"
        textAnchor="middle"
      >
        <text className={classes.label} y="130" x="0">
          0
        </text>
        <text className={classes.label} y="130" x="125">
          25
        </text>
        <text className={classes.label} y="130" x="250">
          50
        </text>
        <text className={classes.label} y="130" x="375">
          75
        </text>
        <text className={classes.label} y="130" x="500">
          100
        </text>
      </g>
      <rect x="250" width="250" height="25" fill="#bfdcc1" />
      <rect x="140" width="110" height="25" fill="#ddf5f5" />
      <rect width="140" height="25" fill="#aca4c1" />
      <rect y="225" x="250" width="250" height="25" fill="#bfdcc1" />
      <rect y="225" x="140" width="110" height="25" fill="#ddf5f5" />
      <rect y="225" width="140" height="25" fill="#aca4c1" />

      <g className="you">
               <text
          className={classes.label}
          fontSize="15"
          textAnchor="left"
          y="115"
          x="5"
        >
          {language.whoChart_labelYou}
        </text>
        <line
          strokeWidth="2"
          stroke="#404a45"
          x1="0"
          y1="75"
          x2={scoreToXCoords(score)}
          y2="75"
        />
        <circle
          cx={scoreToXCoords(score)}
          cy="75"
          r="20"
          fill={scoreToColor(score)}
          strokeWidth="2"
          stroke="#404a45"
        />
 
        <text
          className={classes.score}
          fontSize="20"
          fontWeight="bold"
          transform="translate(0 6)"
          textAnchor="middle"
          y="75"
          x={scoreToXCoords(score)}
        >
          {score}
        </text>
      </g>
      <g className="avg">
        <text
          className={classes.label}
          fill="#80918b"
          fontSize="15"
          textAnchor="left"
          y="215"
          x="5"
        >
          {language.whoChart_labelAvg}
        </text>
        <line
          strokeWidth="2"
          stroke="#80918b"
          x1="0"
          y1="175"
          x2={scoreToXCoords(average)}
          y2="175"
        />
        <circle
          cx={scoreToXCoords(average)}
          cy="175"
          r="20"
          fill={scoreToColor(average)}
          strokeWidth="2"
          stroke="#80918b"
        />
   
        <text
          className={classes.score}
          fontSize="20"
          fontWeight="bold"
          transform="translate(0 6)"
          textAnchor="middle"
          y="175"
          x={scoreToXCoords(average)}
          fill="#80918b"
        >
          {average}
        </text>
      </g>
    </svg>
  );
};

export default WHOChart;
