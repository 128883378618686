import React, { useContext } from 'react';

import { useEffect } from 'react';
import ActivityPage from './ActivityPage';


const ActivityHub = (props) => {

    const {match} = props;
    let {params: {activityId}} = match;

    return <ActivityPage id={activityId} {...props}/>;

    

    
}

export default ActivityHub;
