import React from "react";

const BackPack = ({ className, base, baseColor = "#ddf5f5" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      {base && <circle cx="54" cy="54" r="45" fill={baseColor} />}

      <polygon
        points="90 27.03 81 27.03 81 106.03 90 106.03 90 68.69 90 27.03"
        fill="#2b6679"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <rect
        x="18"
        y="27.03"
        width="9"
        height="79"
        strokeWidth="2"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#2b6679"
      />
      <rect
        x="14.75"
        y="72.35"
        width="4.5"
        height="9"
        transform="translate(55.96 8.46) rotate(42.14)"
        strokeWidth="2"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#faf1ee"
      />
      <rect
        x="18"
        y="19.5"
        width="72"
        height="81"
        rx="9"
        strokeWidth="2"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#00759c"
      />
      <rect
        x="24.75"
        y="53.5"
        width="58.5"
        height="39"
        rx="7"
        strokeWidth="2"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#2b6679"
      />
      <line
        x1="79"
        y1="63"
        x2="29"
        y2="63"
        fill="#404a45"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <rect
        x="31.5"
        y="63"
        width="4.5"
        height="9"
        strokeWidth="2"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#faf1ee"
      />
      <circle
        cx="54"
        cy="36"
        r="10.5"
        fill="#faf1ee"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M77.51,24C76.59,10.2,72.48,2,54,2S31.41,10.2,30.49,24h-1.3V42H35.4V24h-.8C35.4,11.36,38.87,6,54,6s18.6,5.36,19.4,18h-.8V42h6.21V24Z"
        fill="#2b6679"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M56.92,33.08A2.92,2.92,0,0,0,54,36a2.91,2.91,0,0,1-5.77.59,5.8,5.8,0,0,0,11.54,0,2.88,2.88,0,0,0,.06-.59A2.91,2.91,0,0,0,56.92,33.08Z"
        fill="#db6b5e"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default BackPack;
