import {useEffect, useState} from 'react';
import axios from 'axios'
import handleError from '../lib/handleError';

const useWho5Score = (task_id, attempt = 1) => {

    
    const [score, setscore] = useState(undefined);
    const [loading, setloading] = useState(true);
    
    useEffect(() => {
        
        const source = axios.CancelToken.source();
        const getWho5Score = async () => {
            try {
                let score = await axios.post("/user/who5/get", {
                    task_id,
                    attempt,
                },{cancelToken: source.token})
                
                

                if(score.data.found){
                    setscore(score.data.data.score);
                    setloading(false);
                }else{
                    throw "no score found";
                }

                // setscore(score);
              } catch (e) {
                if(!axios.isCancel(e)){
                    handleError(e);
                    setloading(false);
                }
              }
        }

        getWho5Score();

        return () => {
            source.cancel();
        };
    }, []);

    return [score, loading];
}

export default useWho5Score;
