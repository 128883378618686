import React, { Fragment, useEffect, useState } from 'react';

const Countdown = ({onFinish, due}) => {

    const [timeLeft, settimeLeft] = useState();

    const getTimeLeft = () => {
        let _timeleft = due - Math.floor(Date.now() / 1000);
        if(_timeleft <= 0){
            onFinish()
            return false;
        }else{
            settimeLeft(new Date(_timeleft * 1000).toISOString().substr(11, 8))
            return true;
        }
    }

    const tick = () => {
        if(getTimeLeft()){
            window.setTimeout(tick,500);
        }
    }

    useEffect(()=>{
        tick();
    })

    return (
        <Fragment>{timeLeft}</Fragment>
    );
}

export default Countdown;
