import React from 'react';
import Axios from 'axios';
import usageActions from '../lib/usageActions.json';
import handleError from '../lib/handleError';
import { useState } from 'react';
import { useRef } from 'react';

const keepAliveTime = 1000 * 60;

const useUsageMonitoring = (action_on) => {

    const [timeout, set_timeout_ref] = useState(undefined);
    const [interval, set_interval_ref] = useState(undefined);
    const [session_id, setsession_id] = useState(undefined);
    const [session_open, set_session_open] = useState(false);

    const timeout_Ref = useRef();
    timeout_Ref.current = timeout;

    const session_id_Ref = useRef();
    session_id_Ref.current = session_id;

    const interval_Ref = useRef();
    interval_Ref.current = interval;
    
    let attempts = 0;
    
    
    const beginUsageMonitoring = async () => {
        if(!session_open){
            try{
                console.log("attempting to start session");
                let res = await Axios.post("/user/recordAction",{action_on, isOpen: true});
                console.log("open session for action "+action_on+": " + res.data.action_id);
                setsession_id(res.data.action_id);
                set_interval_ref(setInterval(()=>sendKeepAlive(action_on,res.data.action_id),keepAliveTime));
                set_session_open(true)
                attempts = 0;
            }catch(e){
                if(attempts < 3){
                    attempts++;
                    set_timeout_ref(window.setTimeout(()=>beginUsageMonitoring(),500*2**attempts));
                }else{
                    handleError(e, false, {data: "/user/recordAction", action: "OPEN"});
                    attempts = 0;
                }
            }
        }
    }

    const endUsageMonitoring = async () => {
        if(session_id_Ref.current){
            try{
                console.log("close session for action "+action_on+": " + session_id_Ref.current);
                await Axios.post("/user/recordAction",{action_on, isOpen: false, action_id: session_id_Ref.current});
                set_session_open(false);
                clearInterval(interval_Ref.current)
            }catch(e){
                handleError(e, false, {route: "/user/recordAction", action: "CLOSE"});
            }
        }
    }

    const clearTimeouts = () => {
        clearTimeout(timeout_Ref.current);
    }

    const sendKeepAlive = async (_action_on, _sessionId) => {
        try{
            console.log("sending keep alive: " + _sessionId);
            await Axios.post("/user/session/keepalive",{action_on, action_id: _sessionId});
            attempts = 0;
        }catch(e){
            if(attempts < 3){
                attempts++;
                set_timeout_ref(window.setTimeout(()=>sendKeepAlive(_action_on, _sessionId),500*2**attempts));
            }else{
                handleError(e, false, {route: "/user/session/keepalive"});
                console.error("Cannot send keep alive signal");
                attempts = 0;
            }
        }
    };

    return [beginUsageMonitoring, endUsageMonitoring, clearTimeouts];

}

export default useUsageMonitoring;
