import React from "react";

const Steps = ({ className, base, baseColor = "#ddf5f5" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      {base && <circle cx="54" cy="54" r="45" fill={baseColor} />}
      <ellipse
        cx="54"
        cy="91.68"
        rx="38.36"
        ry="12.79"
        fill="#404a45"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <ellipse
        cx="68"
        cy="62.55"
        rx="27.26"
        ry="9.09"
        fill="#404a45"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <ellipse
        cx="55"
        cy="43.38"
        rx="16.88"
        ry="5.63"
        fill="#404a45"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <ellipse
        cx="48"
        cy="31.06"
        rx="11.81"
        ry="3.94"
        fill="#404a45"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <ellipse
        cx="54"
        cy="22"
        rx="6.75"
        ry="2.25"
        fill="#404a45"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Steps;
