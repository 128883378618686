import React, { useContext } from "react";

import { LanguageContext } from "../Store/LanguageStore";

const useStepsList = () => {
  const [language] = useContext(LanguageContext);

  return [
    {
      index: 0,
      title: language.steppingstone1_title,
      aim: language.steppingstone1_aim,
      icon: "ticket",
      activityIcons: ["write", "mindful"],
    },
    {
      index: 1,
      title: language.steppingstone2_title,
      aim: language.steppingstone2_aim,
      icon: "heart",
      activityIcons: ["write", "mindful", "calendar"],
    },
    {
      index: 2,
      title: language.steppingstone3_title,
      aim: language.steppingstone3_aim,
      icon: "lighthouse",
      activityIcons: ["write", "calendar"],
    },
    {
      index: 3,
      title: language.steppingstone4_title,
      aim: language.steppingstone4_aim,
      icon: "compass",
      activityIcons: ["write"],
    },
    {
      index: 4,
      title: language.steppingstone5_title,
      aim: language.steppingstone5_aim,
      icon: "torch",
      activityIcons: ["write", "calendar"],
    },
    {
      index: 5,
      title: language.steppingstone6_title,
      aim: language.steppingstone6_aim,
      icon: "plan",
      activityIcons: ["write"],
    },
    {
      index: 6,
      title: language.steppingstone7_title,
      aim: language.steppingstone7_aim,
      icon: "bridge",
      activityIcons: ["write", "calendar"],
    },
    {
      index: 7,
      title: language.steppingstone8_title,
      aim: language.steppingstone8_aim,
      icon: "campfire",
      activityIcons: ["write"],
    },
    {
      index: 8,
      title: language.steppingstone9_title,
      aim: language.steppingstone9_aim,
      icon: "path",
      activityIcons: ["write"],
    },
    {
      index: 9,
      title: language.steppingstone10_title,
      aim: language.steppingstone10_aim,
      icon: "binoculars",
      activityIcons: ["write"],
    },
  ];
};

export default useStepsList;
