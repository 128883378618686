import React, {useContext} from 'react';

import {LanguageContext} from '../../../Store/LanguageStore'
import Paragraph from '../../../Components/SanatizedComponents/Paragraph'
import Quote from '../../../Components/SanatizedComponents/Quote'

const Activity3_intro = () => {
    const [language] = useContext(LanguageContext);
    return (
        <div>
            <Paragraph text={language.steppingstone3_paragraph1}/>
            <Paragraph text={language.steppingstone3_paragraph2}/>
            <Quote text={language.steppingstone3_quote1}/>
        </div>
    );
}

export default Activity3_intro;
