import React, {useContext} from 'react';
import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";
import classes from './LoadingScreen.module.scss';


const LoadingScreen = ({children, loading}) => {

    if(loading){
        return (
            <div className={classes.loadingContainer}>
                <BeatLoader
                    size={"2em"}
                    color={"#404a45"}
                    loading={loading}
                    className={classes.loadingContainer}
                />
            </div>
        )
    }else{
        return children;
    }
}

export default LoadingScreen;
