import React, { useState, useEffect, useContext } from 'react';
import classes from "../Activity.module.scss"

import TextQuestion from "../../../Components/Questions/TextQuestion/TextQuestion"

import utils from "../../../utils/utils"
import LoadingScreen from '../../../Components/LoadingScreen/LoadingScreen';

import usePreviousAnswers from '../../../Hooks/usePreviousAnswers'

import {LanguageContext} from '../../../Store/LanguageStore'
import Heading from '../../../Components/SanatizedComponents/Heading'
import Paragraph from '../../../Components/SanatizedComponents/Paragraph'
import UnorderedList from '../../../Components/SanatizedComponents/UnorderedList'

const Activity3_main_2 = ({attempt, allowContinue, restrictContinue}) => {
    const [language] = useContext(LanguageContext);
    const task_id = 3;

    const questionCount = 1;
    const firstQuestionIndex = 1;
    const [answers, loading, setAnswers] = usePreviousAnswers(task_id, questionCount, attempt, allowContinue, null, firstQuestionIndex);

    const _submitAnswer = async (question_id, answer) => {
        utils.submitAnswer(task_id, question_id, answer, attempt);
        utils.addAnswerToState(question_id - 1 - firstQuestionIndex, answer, answers, setAnswers, allowContinue);
    }


    return (
        <LoadingScreen loading={false}>
            <div className={classes.questions}>
                <TextQuestion height="s" initialValue={answers[0]} submitAnswer={(answer) => _submitAnswer(2, answer)} question={language.steppingstone3_exercise_d} hint={language.steppingstone3_exercise_d_hint}/>
                <Paragraph text={language.steppingstone3_exercise_d_paragraph}/>
                <UnorderedList items={[language.steppingstone3_exercise_d_1, language.steppingstone3_exercise_d_2, language.steppingstone3_exercise_d_3, language.steppingstone3_exercise_d_4]} />
                <Paragraph text={language.steppingstone3_paragraph4} />
            </div>
        </LoadingScreen>
    );
}

export default Activity3_main_2;
