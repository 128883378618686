import React, { useContext, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";



import { AccountContext } from '../../../Store/AccountStore'

import UserLoginPage from './UserLoginPage';
import UserRegisterPage from './UserRegisterPage';
import ResetPassword from './ResetPassword';
import ChangePassword from './ChangePassword';


const UserLogin = (props) => {

    const { match } = props;

    return (

        <Switch>
            <Route path={`/login`} component={() => <UserLoginPage />} />
            <Route path={`/register`} component={(_props) => <UserRegisterPage history={props.history} {..._props} />} />
            <Route path={`/resetPassword`} component={() => <ResetPassword />} />
            <Route path={`/changepassword`} component={() => <ChangePassword />} />
        </Switch>

    );
}

export default UserLogin;
