import React, { useState, useEffect, useContext, Fragment }  from 'react';
import axios from 'axios';
import Heading from '../../../../Components/SanatizedComponents/Heading';
import classes from './ReminderSettings.module.scss';
import Paragraph from '../../../../Components/SanatizedComponents/Paragraph';
import Checkbox from '../../../../Components/Questions/Checkbox/Checkbox';
import handleError from '../../../../lib/handleError';
import { AccountContext } from '../../../../Store/AccountStore';
import { LanguageContext } from '../../../../Store/LanguageStore';
import { RedirectContext } from '../../../../Store/RedirectTo';

const ReminderSettings = () => {

    const [subscribed, setSubscribed] = useState(true);

    const [user, getAccountInfo] = useContext(AccountContext);
    const [language] = useContext(LanguageContext)
    const [redirectTo] = useContext(RedirectContext);

    useEffect(()=>{
        
        let source = axios.CancelToken.source();

        const getSubscribed = async () => {
            try{
                let result = await axios.get('/user/reminders/subscribed/get',{cancelToken: source.token})
                setSubscribed(result.data);
            }catch(e){
                if(!axios.isCancel(e)){
                    handleError(e, true);
                }
            }
        }
        if(user.seenReminderEmailsPrompt)
            getSubscribed();
    },[])

    const onClick = async () => {
        try{
            if(user.seenReminderEmailsPrompt)
                await axios.post('/user/reminders/subscribed/set',{subscribed: !subscribed})
            setSubscribed(!subscribed);
        }catch(e){
            if(!axios.isCancel(e)){
                handleError(e, true);
            }
        }
    }

    const continue_click = async () => {
        if(!subscribed && window.confirm(language.dont_want_to_subscribe)){
            await axios.post('/user/reminders/subscribed/set',{subscribed: subscribed})
            redirectTo('/user/map')
        }else if(subscribed) {
            await axios.post('/user/reminders/subscribed/set',{subscribed: subscribed})
            let _user = {...user};
            _user.seenReminderEmailsPrompt = true;
            getAccountInfo(_user);
            redirectTo('/user/map')
        }

    }

    return (
        <div className={classes.reminderSettings}>
            <Paragraph text={language.reminders_explination}/>
            <Checkbox text={language.i_agree_to_recieve_emails} checked={subscribed} onCheck={onClick}/>
            {
                !user.seenReminderEmailsPrompt ? (
                    <button className={classes.buttonSubmit} onClick={continue_click}>
                        {language.continue}
                    </button>
                ) : (
                    <Fragment/>
                )
            }
        </div>
    );
}

export default ReminderSettings;
