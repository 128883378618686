import React, {useContext} from 'react';
import axios from 'axios';
import {AccountContext} from '../Store/AccountStore.js';
import handleError from '../lib/handleError.js';

const useStartNewAttempt = () => {
    const [user, setuser] = useContext(AccountContext);

    const startNewAttempt = async (event,task_id) => {
        try{
            await axios.post('/user/progress/start_new_attempt',{task_id: task_id+1});
            let _user = {...user};
            _user.progress[task_id].active = 1;
            _user.progress[task_id].disabled = 0;
            setuser(_user);
        }catch(e){
            event.stopPropagation();
            handleError(e, true);
        }
    }

    return [startNewAttempt];
}

export default useStartNewAttempt;
