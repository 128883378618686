import React, { useState, useEffect, useContext } from 'react';
import classes from "../Activity.module.scss"


import TextQuestion from '../../../Components/Questions/TextQuestion/TextQuestion';
import SliderQuestion from '../../../Components/Questions/SliderQuestion/SliderQuestion';
import LoadingScreen from '../../../Components/LoadingScreen/LoadingScreen';

import usePreviousAnswers from '../../../Hooks/usePreviousAnswers'

import {LanguageContext} from '../../../Store/LanguageStore'
import Heading from '../../../Components/SanatizedComponents/Heading'
import Paragraph from '../../../Components/SanatizedComponents/Paragraph'

import utils from '../../../utils/utils'
import PreviousAnswer from '../../../Components/PreviousAnswer/PreviousAnswer';


const Activity2_main_3 = ({task_id, attempt, allowContinue, restrictContinue}) => {
    const [language] = useContext(LanguageContext);
    const self_compassion_responses = [
        {
            response: language.steppingstone2_question2_feedback1,
            min: 1,
            max: 2
        },
        {
            response: language.steppingstone2_question2_feedback2,
            min: 3,
            max: 3
        },
        {
            response: language.steppingstone2_question2_feedback3,
            min: 4,
            max: 5
        }
    ];

    const self_compassion_answers = [
        {
            value: 1,
            label: " 1 - " + language.self_compassionate_answers_1,
        },
        {
            value: 2,
            label: " 2 - " + language.self_compassionate_answers_2,
        },
        {
            value: 3,
            label: " 3 - " + language.self_compassionate_answers_3,
        },
        {
            value: 4,
            label: " 4 - " + language.self_compassionate_answers_4,
        },
        {
            value: 5,
            label: " 5 - " + language.self_compassionate_answers_5,
        },
    ]
    const questionCount = 3;
    const firstQuestionIndex = 4;
    const [answers, loading, setAnswers] = usePreviousAnswers(task_id, questionCount, attempt, allowContinue, null, firstQuestionIndex);


    const submitAnswer = async (question_id, answer) => {
        utils.submitAnswer(task_id, question_id, answer, attempt);
        console.log(answers)
        utils.addAnswerToState(question_id - 1 - firstQuestionIndex, answer, answers, setAnswers, allowContinue);
    }

    const [response, setresponse] = useState("");

    const submitFinalSlider = (answer) => {
        submitAnswer(7, answer);
        getResponse(answer);
    }

    const getResponse = (answer) => {
        let answered = false;
        for (let response of self_compassion_responses) {
            console.log(response.min, response.max, answer)
            if (response.min <= answer && response.max >= answer) {
                console.log(response.response)
                setresponse(response.response);
                return;
            }
        }
        setresponse("");
    }

    return (
        <LoadingScreen loading={loading}>
            <div className={classes.questions}>
                
                <TextQuestion initialValue={answers[0]} submitAnswer={(answer) => submitAnswer(5, answer)} question={language.steppingstone2_exercise_d} hint={language.steppingstone2_exercise_d_hint} />
                <TextQuestion initialValue={answers[1]} submitAnswer={(answer) => submitAnswer(6, answer)} question={language.steppingstone2_exercise_e} hint={language.steppingstone2_exercise_e_hint} />
                <SliderQuestion 
                    saveDelay={0} 
                    initialValue={answers[2]} 
                    submitAnswer={submitFinalSlider} 
                    question={language.steppingstone2_question2} 
                    min={1} 
                    max={5} 
                    marks={self_compassion_answers}
                />
                <Paragraph text={response}/>
                
            </div>
        </LoadingScreen>
    );
}

export default Activity2_main_3;
