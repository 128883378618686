import React, {useContext} from 'react';

import Quote from '../../../Components/SanatizedComponents/Quote';
import Paragraph from '../../../Components/SanatizedComponents/Paragraph';
import {LanguageContext} from '../../../Store/LanguageStore'

const Activity9_intro = () => {
    const [language] = useContext(LanguageContext);
    return (
        <div>
            <Paragraph text={language.steppingstone9_paragraph1}/>
            <Paragraph text={language.steppingstone9_paragraph2}/>
            <Quote text={language.steppingstone9_quote1}/>
        </div>
    );
}

export default Activity9_intro;
