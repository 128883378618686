import React from "react";

const Chairs = ({ className, color = "#000", weight = 3 }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      <polygon
        points="75.92 45.29 81.73 48.19 93.34 54 99.14 56.9 81.73 65.61 58.5 54 75.92 45.29"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <polygon
        points="64.31 93.19 70.11 90.29 70.11 68.52 64.31 65.61 64.31 93.19"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />

      <polygon
        points="93.34 90.29 99.14 93.19 99.14 65.61 93.34 68.52 93.34 90.29"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <path
        d="M75.92,13.91V45.29L58.5,54V90.29l5.81,2.9V65.61l11.61,5.81V96.1L81.73,99l5.8-2.9V71.42l11.62-5.81V93.19l5.8-2.9V24.47a10,10,0,0,0-5.53-8.94l-15.91-8a4,4,0,0,0-3.57,0l-.15.08A7,7,0,0,0,75.92,13.91Z"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />

      <polygon
        points="32.08 45.29 8.86 56.9 26.27 65.61 49.5 54 32.08 45.29"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <polygon
        points="37.89 68.52 37.89 90.29 43.69 93.19 43.69 65.61 37.89 68.52"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <path
        d="M26.27,99l5.81-2.9V71.42l11.61-5.81V93.19l5.81-2.9V54L32.08,45.29V13.91a7,7,0,0,0-3.87-6.26l-.15-.08a4,4,0,0,0-3.57,0l-15.91,8a10,10,0,0,0-5.53,8.94V90.29l5.8,2.9V65.61l11.62,5.81V96.1l5.8,2.9Z"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <polygon
        points="8.86 65.61 14.66 68.52 14.66 90.29 8.86 93.19 8.86 65.61"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
    </svg>
  );
};

export default Chairs;
