import React, { useContext, useState, useEffect }  from 'react';
import classes from './LookingAheadKit.module.scss';
import Heading from '../../SanatizedComponents/Heading';
import LikeButton from '../../buttons/LikeButton/LikeButton';
import { LanguageContext } from '../../../Store/LanguageStore';
import RenderFromKeys from '../../RenderFromKeys/RenderFromKeys';
import { Switch, Route, Link, Redirect, useLocation } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import LookingAheadKitResponse from './LookingAheadKitResponse';
import usePreviousAnswers from '../../../Hooks/usePreviousAnswers'
import usePreviousActivityAssessmentAnswers from '../../../Hooks/usePreviousActivityAssessmentAnswers'

import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import Axios from 'axios';
import handleError from '../../../lib/handleError';

import ProgressBar from '../../../Components/ProgressBar/ProgressBar'




const LookingAheadKit = (props) => {
    const { match } = props;

    const location = useLocation();
    const [language] = useContext(LanguageContext)
    const { routines } = language;
    const id = 23;
    const routine = routines[id-1];

    const [currentIndex, setcurrentIndex] = useState(0);
    
    
    const splitIntoPageObjects = (content) => {
        
        let pages = [];
        let currentPage = [];
        for(let [key, value] of Object.entries(content)){  
            if(key == "pageSplit"){
                pages.push(currentPage);
                currentPage = [];
            }else{
                currentPage[key] = value;
            }
        }
        if(Object.keys(currentPage).length > 0){
            pages.push(currentPage);
        }
        
        return pages;
    }
    
    const pageObjects = splitIntoPageObjects(routine.description);
    const [checked, setchecked] = useState(Array(pageObjects[1].multipleChoiceQuestion1.answers.length).fill(false));
    const [situation, setsituation] = useState("");

    let canContinue = true;
    if(currentIndex == 1 && situation.length < 1)
        canContinue = false;
    
    pageObjects[1].multipleChoiceQuestion1.submit = (_checked) => setchecked(_checked)
    pageObjects[1].multipleChoiceQuestion1.checked = checked;
    
    pageObjects[1].textQuestion1 = {...pageObjects[1].textQuestion1};
    pageObjects[1].textQuestion1.value = situation;
    pageObjects[1].textQuestion1.submit = (_situation) => setsituation(_situation);
    
    let pages = [];
    
    for(let [index] in pageObjects){
        pages[index] = { component: (props) => <RenderFromKeys content={pageObjects[index]}/> };
    }
    
    const [_previousAnswers, loading] = usePreviousAnswers(10,14,null,null,null,2);
    const [previousAssessments, loadingAssessments] = usePreviousActivityAssessmentAnswers();
    
    let previousAnswers = [];
    if(!loading && !loadingAssessments){
        let previousAnswerIndexs = [
            {index: 0, task_id: 2},
            {index: 1, task_id: 3},
            {index: 2, task_id: 7},
            {index: 5, task_id: 4},
            {index: 6, task_id: 5},
            {index: 9, task_id: 6},
            {index: 12, task_id: 9},
            {index: 13, task_id: 8},
        ];

        for(let answer of previousAnswerIndexs){
            
            if(_previousAnswers[answer.index]){
                
                previousAnswers.push({task_id: answer.task_id, how_useful: _previousAnswers[answer.index]})
            }else{
                
                console.log(previousAssessments.find(assessment => {
                    return assessment.task_id == answer.task_id
                }));
                previousAnswers.push(previousAssessments.find(assessment => assessment.task_id == answer.task_id))
            }
            
        }
    }

    pages.push({ component: () => <LookingAheadKitResponse checked={checked} situation={situation} previousRatings={previousAnswers}/> });

    const changeSlideIndex = (delta) => {
        
        if (!canContinue && delta > 0) return;
        setcurrentIndex(currentIndex + delta);
    };

    const submitAnswers = async () => {
        //if (!canContinue) return;
        setcurrentIndex(2);
        try{
            await Axios.post('/user/lookingforward/submit', {situation, checked})
        }catch(e){
            handleError(e, true);
        }
    }

    if(loading || loadingAssessments) return <LoadingScreen loading={loading || loadingAssessments}/>

    return (
        <div>
          <div className={classes.wrapper}>
            <div className={classes.content}>
                {pages[currentIndex].component()}
            </div>

            <nav className={classes.nav}>
                {(currentIndex > 0) ? (
                    <div style={{ color: "#FFFFFF", cursor: "pointer" }} onClick={() => changeSlideIndex(-1)}>
                        <FaArrowLeft />
                    </div>
                ) : <div />
                }

                <ProgressBar totalPages={pages.length} currentIndex={currentIndex}/>

                {/* {canContinue ? ( */}
                {true ? (
                    currentIndex == 1 ? (
                        <div style={{ color: "#FFFFFF", cursor: "pointer" }} onClick={() => submitAnswers()}>
                            <FaArrowRight />
                        </div>
                    ) : 
                        currentIndex == 0 ? (
                            <div style={{ color: "#FFFFFF", cursor: "pointer" }} onClick={() => changeSlideIndex(1)}>
                                <FaArrowRight />
                            </div>
                        ) : (
                            <Link to={`/user/backpack`}>
                                <div onClick={(e) => {}}>{language.submit}</div>
                            </Link>
                        )
                ) : (
                    <div
                        style={{ color: "#CCCCCC", cursor: "pointer" }}
                        onClick={() => alert(language.unanswered_questions_message)}
                    >
                        <FaArrowRight />
                    </div>
                )}
            </nav>
          </div>
        </div>
      );
}

export default LookingAheadKit;
