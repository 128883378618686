import React from "react";

import AudioPlayer from "./AudioPlayer";
import Heading from "../SanatizedComponents/Heading";
import Paragraph from "../../Components/SanatizedComponents/Paragraph";

import classes from "./AudioWithText.module.scss";

// ensure that no user generated text is passed into this component
const AudioWithText = ({ text, title, audio }) => {
  return (
    <div>
      <Heading level="h2" text={title} />
      <Paragraph text={text} />
      <AudioPlayer className={classes.audio} mp3={audio} />
    </div>
  );
};

export default AudioWithText;
