import React from "react";

const Plant = ({ className, base, baseColor = "#ddf5f5" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      {base && <circle cx="54" cy="54" r="45" fill={baseColor} />}

     <polygon points="69.93 99 38.07 99 30.1 63 77.9 63 69.93 99" fill="#e6bbaa" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    <rect x="27" y="54" width="54" height="18" strokeWidth="2" stroke="#000" strokeLinecap="round" strokeLinejoin="round" fill="#e6bbaa"/>
    <line x1="54" y1="54" x2="54" y2="18" fill="#468f79" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    
      <path d="M90,36c-7.2,7.79-28.8,15.59-36,0C61.2,20.41,82.8,28.21,90,36Z" fill="#bfdcc1" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <polyline points="71.3 27.24 64 36 71.3 44.76" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <polyline points="77.9 29.28 72.3 36 77.9 42.72" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <line x1="54" y1="36" x2="85.3" y2="36" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
 
      <path d="M18,18c7.2-7.79,28.8-15.59,36,0C46.8,33.59,25.2,25.79,18,18Z" fill="#468f79" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <polyline points="36.7 26.76 44 18 36.7 9.24" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <polyline points="30.1 24.72 35.7 18 30.1 11.28" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <line x1="54" y1="18" x2="22.7" y2="18" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
  
    </svg>
  );
};

export default Plant;
