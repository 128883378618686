import React from "react";

const calendar = ({ className, base, baseColor = "#ddf5f5" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      {base && <circle cx="54" cy="54" r="45" fill={baseColor} />}

      <rect
        x="13.74"
        y="9"
        width="81"
        height="81"
        strokeWidth="2"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#faf1ee"
      />
      <rect
        x="13.74"
        y="9"
        width="81"
        height="18"
        strokeWidth="2"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#db6b5e"
      />

      <rect x="22.74" y="36" width="63" height="45" fill="#fff" />
      <line x1="22.74" y1="72" x2="85.74" y2="72" fill="#fff" />
      <line x1="22.74" y1="63" x2="85.74" y2="63" fill="#fff" />
      <line x1="22.74" y1="54" x2="85.74" y2="54" fill="#fff" />
      <line x1="22.74" y1="45" x2="85.74" y2="45" fill="#fff" />
      <line x1="76.74" y1="36" x2="76.74" y2="81" fill="#fff" />
      <line x1="67.74" y1="36" x2="67.74" y2="81" fill="#fff" />
      <line x1="58.74" y1="36" x2="58.74" y2="81" fill="#fff" />
      <line x1="49.74" y1="36" x2="49.74" y2="81" fill="#fff" />
      <line x1="40.74" y1="36" x2="40.74" y2="81" fill="#fff" />
      <line x1="31.74" y1="36" x2="31.74" y2="81" fill="#fff" />

      <rect
        x="22.74"
        y="36"
        width="63"
        height="45"
        strokeWidth="2"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <line
        x1="22.74"
        y1="72"
        x2="85.74"
        y2="72"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        x1="22.74"
        y1="63"
        x2="85.74"
        y2="63"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        x1="22.74"
        y1="54"
        x2="85.74"
        y2="54"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        x1="22.74"
        y1="45"
        x2="85.74"
        y2="45"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        x1="76.74"
        y1="36"
        x2="76.74"
        y2="81"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        x1="67.74"
        y1="36"
        x2="67.74"
        y2="81"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        x1="58.74"
        y1="36"
        x2="58.74"
        y2="81"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        x1="49.74"
        y1="36"
        x2="49.74"
        y2="81"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        x1="40.74"
        y1="36"
        x2="40.74"
        y2="81"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        x1="31.74"
        y1="36"
        x2="31.74"
        y2="81"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />

      <polyline
        points="30.24 37.5 30.24 37.5 24.24 43.5"
        fill="none"
        stroke="#00759c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <polyline
        points="24.24 37.5 30.24 43.5 30.24 43.5"
        fill="none"
        stroke="#00759c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />

      <polyline
        points="39.24 37.5 39.24 37.5 33.24 43.5"
        fill="none"
        stroke="#00759c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <polyline
        points="33.24 37.5 39.24 43.5 39.24 43.5"
        fill="none"
        stroke="#00759c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />

      <polyline
        points="48.24 37.5 48.24 37.5 42.24 43.5"
        fill="none"
        stroke="#00759c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <polyline
        points="42.24 37.5 48.24 43.5 48.24 43.5"
        fill="none"
        stroke="#00759c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />

      <polyline
        points="57.24 37.5 57.24 37.5 51.24 43.5"
        fill="none"
        stroke="#00759c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <polyline
        points="51.24 37.5 57.24 43.5 57.24 43.5"
        fill="none"
        stroke="#00759c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default calendar;
