import React, { useRef, useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import { AccountContext } from "../../../Store/AccountStore.js";
import { LanguageContext } from "../../../Store/LanguageStore.js";
import { RedirectContext } from "../../../Store/RedirectTo";
import classes from "./UserLoginRegisterPages.module.scss";
import handleError from "../../../lib/handleError";
import Countdown from "../../../Components/Countdown/Countdown";
import { URLParamsContext } from "../../../Store/URLParamsStore";
import useResendConfirmationEmail from "../../../Hooks/useResendConfirmationEmail";
import useParseStringLinks from "../../../Hooks/useParseStringLinks";

const UserLoginPage = () => {
  const [redirectTo, redirectAfterLogin, afterLogin] =
    useContext(RedirectContext);
  const [language] = useContext(LanguageContext);
  const [params] = useContext(URLParamsContext);

  const [message, setmessage] = useState(undefined);
  const [isError, setisError] = useState(false);
  const [resendConfirmationEmail] = useResendConfirmationEmail(
    setmessage,
    setisError
  );
  const { parseAllLinks } = useParseStringLinks();

  const emailRef = useRef();
  const passRef = useRef();
  const [rememberFor, setrememberFor] = useState(false);

  const [user, setuser] = useContext(AccountContext);

  useEffect(() => {
    const setMessageFromConfirmationParams = () => {
      if (params.approveEmail) {
        switch (params.approveEmail) {
          case "expiredLink":
            setisError(true);
            setmessage(
              <React.Fragment>
                {language.expiredConfirmationLink}
              </React.Fragment>
            );
            break;
          case "noAccountToApprove":
            setisError(true);
            setmessage(
              <React.Fragment>
                {parseAllLinks(language.noAccountToApprove)}
              </React.Fragment>
            );
            break;
          case "accountAlreadyApproved":
            setisError(true);
            setmessage(
              <React.Fragment>
                {parseAllLinks(language.accountAlreadyApproved)}
              </React.Fragment>
            );
            break;
          case "emaiInUse":
            setisError(true);
            setmessage(<React.Fragment>{language.emailInUse}</React.Fragment>);
            break;
          case "error":
            setisError(true);
            setmessage(
              <React.Fragment>{language.anErrorOccured}</React.Fragment>
            );
            break;
          case "emailConfirmed":
            setisError(false);
            setmessage(
              <React.Fragment>{language.emailConfirmed}</React.Fragment>
            );
            break;
          default:
            break;
        }
      }
    };

    setMessageFromConfirmationParams();

    return () => {
      redirectAfterLogin(false);
    };
  }, []);

  const login = async (e) => {
    e.preventDefault();

    let email = emailRef.current.value;
    let password = passRef.current.value;

    let data = { email, password, rememberFor };

    try {
      setisError(false);
      setmessage(<React.Fragment>{language.processing}</React.Fragment>);
      let res = await axios.post("/user/login", data);
      setisError(false);
      setmessage(undefined);
      setuser(res.data.user);
      afterLogin();
    } catch (e) {
      console.log(e.response.data);

      if (e.response.data.message == "account locked") {
        setisError(true);
        setmessage(
          <React.Fragment>
            {language.yourAccountIsLockedFor}
            <b>
              <Countdown
                due={e.response.data.unclockAt}
                onFinish={() => {
                  setisError(false);
                  setmessage(language.accountUnlocked);
                }}
              />
            </b>
          </React.Fragment>
        );
        passRef.current.value = "";
      } else {
        switch (e.response.data) {
          case "email unapproved":
            setisError(true);
            setmessage(
              <React.Fragment>
                {language.yourEmailIsRegisteredButUnconfirmed}
                <br />
                {language.cantSeeTheEmail}
                <span
                  className={classes.link}
                  onClick={() => resendConfirmationEmail(email)}
                >
                  {language.resendHere}
                </span>{" "}
              </React.Fragment>
            );
            break;
          case "no account found":
            setisError(true);
            setmessage(
              <React.Fragment>
                {language.incorrect_username_or_password}
              </React.Fragment>
            );
            break;
          case "incorrect password":
            setisError(true);
            setmessage(
              <React.Fragment>
                {language.incorrect_username_or_password}
              </React.Fragment>
            );
            passRef.current.value = "";
            break;
          case "Application is closed":
            setisError(true);
            setmessage(
              <React.Fragment>{language.application_Is_Closed}</React.Fragment>
            );
            break;
          default:
            setisError(true);
            setmessage(
              <React.Fragment>{language.anErrorOccured}</React.Fragment>
            );
            handleError(e, true);
        }
      }
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <form className={classes.form} onSubmit={login}>
          <h1>{language.login}</h1>
          <div className={classes.fieldWrapper}>
            <label htmlFor="loginEmail">{language.email}</label>
            <input id="loginEmail" type="email" ref={emailRef}></input>
          </div>
          <div className={classes.fieldWrapper}>
            <label htmlFor="loginPassword">{language.password}</label>
            <input id="loginPassword" type="password" ref={passRef}></input>
          </div>
          <div className={classes.fieldWrapper}>
            <input
              name="remember"
              id="rememberThisDevice"
              type="checkbox"
              checked={rememberFor}
              onChange={(event) => setrememberFor(event.target.checked)}
            />
            {language.rememberThisDevice}
            <br />
          </div>
          <div className={classes.formActions}>
            <button className={classes.buttonSubmit} type="submit">
              {language.login}
            </button>
            <div className={classes.forgotYourPassword}>
              <Link to={`/resetPassword`}>{language.forgotYourPassword}</Link>
            </div>
          </div>
          {message && (
            <div
              className={`${classes.message} ${
                isError ? classes.isError : classes.isSuccess
              }`}
            >
              {message}
            </div>
          )}
        </form>
        <div className={classes.wrongViewMessage}>
          {language.dont_allready_have_an_account}{" "}
          <Link to={`/register`}>{language.register_here}</Link>
        </div>
      </div>
    </div>
  );
};

export default UserLoginPage;
