import React from "react";

const FirstAid = ({ className, base, baseColor = "#ddf5f5" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      {base && <circle cx="54" cy="54" r="45" fill={baseColor} />}

      <rect
        x="10.15"
        y="24.23"
        width="87.7"
        height="65.77"
        rx="2"
        strokeWidth="2"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#468f79"
      />
      <path
        d="M38.77,24.23V17.46a4,4,0,0,1,3.81-4.11H65.42a4,4,0,0,1,3.81,4.11v6.77h4.35V17.46A8.32,8.32,0,0,0,65.42,9H42.58a8.32,8.32,0,0,0-8.16,8.46v6.77Z"
        fill="#faf1ee"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <circle
        cx="54"
        cy="58.5"
        r="22.5"
        fill="#faf1ee"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <polygon
        points="68.89 53.04 58.07 53.04 58.07 42.23 49.93 42.23 49.93 53.04 39.11 53.04 39.11 61.19 49.93 61.19 49.93 72 58.07 72 58.07 61.19 68.89 61.19 68.89 53.04"
        fill="#468f79"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default FirstAid;
