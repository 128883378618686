import Axios from 'axios';
import React, {useState} from 'react';
import FileDownload from 'js-file-download';
import handleError from '../../../lib/handleError';


const Gdpr = () => {

    const [downloadInputValue, setdownloadInputValue] = useState("");
    const [deleteInputValue, setdeleteInputValue] = useState("");
    

    const downloadData = () => {
        Axios.post('/admin/downloadUserData',{user_id: downloadInputValue},{responseType: 'arraybuffer'}).then((res)=>{
            var data = res.data; //from server
            var blob = new Blob([data], { type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml;charset=UTF-8"});
            var link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'userdata.xlsx';
            link.click();
            
        }).catch((e)=>{
            if(e.response.status == 404){
                alert("user not found");
            }else{
                alert("an error occured, please check the system logs:");
            }
            handleError(e);
        });;
    }

    const deleteData = () => {
        if(!window.confirm("Are you sure you want to anonymise the data for " + deleteInputValue + ". this cannot be undone"))
            return;

        Axios.post('/admin/anonymiseUserData',{user_id: deleteInputValue}).then((res)=>{
            if(res.status == 200){
                alert("Success");
            }
            
        }).catch((e)=>{
            if(e.response.status == 404){
                alert("user not found");
            }else{
                alert("an error occured, please check the system logs:");
            }
            handleError(e);
        });;
    }

    return (
        <div>
            <h1>Download user data</h1>
            <p>this function allows you to enter a user email and recieve all data of that user. to be sent to a user if requested</p>
            <input value={downloadInputValue} onChange={e=>setdownloadInputValue(e.target.value)}></input>
            <button onClick={()=>downloadData()}>Download</button>
            <br/>
            <br/>
            <br/>
            <br/>
            <h1>Anonymise User data</h1>
            <p>this function anonymises all data associated with an entered user. specify a user by their email address</p>
            <input value={deleteInputValue} onChange={e=>setdeleteInputValue(e.target.value)}></input>
            <button onClick={()=>deleteData()}>Anonymise</button>
        </div>
    );
}

export default Gdpr;
