import React, {useState, useEffect} from 'react';

const usePersistedState = (key, defaultValue, forceDefaultValue = false) => {
    
    if(!forceDefaultValue) defaultValue = JSON.parse(localStorage.getItem(key)) || defaultValue;

    const [state, setState] = useState(
        () => JSON.parse(localStorage.getItem(key)) || defaultValue
    );

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);

    const clearStorage = () => {
        localStorage.removeItem(key);
    }

    return [state, setState, clearStorage];
}

export default usePersistedState;
