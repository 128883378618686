import React, {useEffect} from 'react';
import ActivityHub from "../Activities/ActivityHub"
import SteppingStones from "../SteppingStones/SteppingStones"


import {
    Switch,
    Route,
    Link
} from "react-router-dom";

const Map = (props) => {

    const { match } = props;



    return (
        <div>
            <Switch>
                <Route exact path={`${match.url}/`} component={(props) => <SteppingStones {...props} />} />
                <Route path={[`${match.url}/step/:activityId`,`${match.url}/step/:activityId`]} component={(props) => <ActivityHub {...props} />} />
            </Switch>
        </div>
    );
}

export default Map;
