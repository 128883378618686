import React from 'react';
import { useContext } from 'react';
import { LanguageContext } from '../Store/LanguageStore';



const useActivityAssessmentAnswers = () => {
    const [language] = useContext(LanguageContext);

    const activity_answers = [
        {
          label: language.steppingstoneevaluation_answer1,
          value: 1,
        },
        {
          label: language.steppingstoneevaluation_answer2,
          value: 2,
        },
        {
          label: language.steppingstoneevaluation_answer3,
          value: 3,
        },
        {
          label: language.steppingstoneevaluation_answer4,
          value: 4,
        },
        {
          label: language.steppingstoneevaluation_answer5,
          value: 5,
        },
      ];

    return activity_answers;
}

export default useActivityAssessmentAnswers;
