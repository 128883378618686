import React, { useContext } from "react";
import {LanguageContext} from "../Store/LanguageStore";

const useRoutinesList = () => {
  const [language] = useContext(LanguageContext);
  const { routines } = language;

  return [
    {
      index: 0,
      title: routines[0].title,
      description: routines[0].description,
      fromActivity: 0,
      icon: "mindful",
    },
    {
      index: 1,
      title: routines[1].title,
      description: routines[1].description,
      fromActivity: 1,
      icon: "write",
    },
    {
      index: 2,
      title: routines[2].title,
      description: routines[2].description,
      fromActivity: 1,
      icon: "mindful",
    },
    {
      index: 3,
      title: routines[3].title,
      description: routines[3].description,
      fromActivity: 2,
      icon: "calendar",
    },
    {
      index: 4,
      title: routines[4].title,
      description: routines[4].description,
      fromActivity: 2,
      icon: "write",
    },
    {
      index: 5,
      title: routines[5].title,
      description: routines[5].description,
      fromActivity: 2,
      icon: "mindful",
    },
    {
      index: 6,
      title: routines[6].title,
      description: routines[6].description,
      fromActivity: 3,
      icon: "chairs",
    },
    {
      index: 7,
      title: routines[7].title,
      description: routines[7].description,
      fromActivity: 3,
      icon: "write",
    },
    {
      index: 8,
      title: routines[8].title,
      description: routines[8].description,
      fromActivity: 3,
      icon: "calendar",
    },
    {
      index: 9,
      title: routines[9].title,
      description: routines[9].description,
      fromActivity: 4,
      icon: "calendar",
    },
    {
      index: 10,
      title: routines[10].title,
      description: routines[10].description,
      fromActivity: 4,
      icon: "mindful",
    },
    {
      index: 11,
      title: routines[11].title,
      description: routines[11].description,
      fromActivity: 5,
      icon: "write",
    },
    {
      index: 12,
      title: routines[12].title,
      description: routines[12].description,
      fromActivity: 5,
      icon: "chairs",
    },
    {
      index: 13,
      title: routines[13].title,
      description: routines[13].description,
      fromActivity: 5,
      icon: "calendar",
    },
    {
      index: 14,
      title: routines[14].title,
      description: routines[14].description,
      fromActivity: 6,
      icon: "calendar",
    },
    {
      index: 15,
      title: routines[15].title,
      description: routines[15].description,
      fromActivity: 6,
      icon: "mindful",
    },
    {
      index: 16,
      title: routines[16].title,
      description: routines[16].description,
      fromActivity: 7,
      icon: "calendar",
    },
    {
      index: 17,
      title: routines[17].title,
      description: routines[17].description,
      fromActivity: 7,
      icon: "mindful",
    },
    {
      index: 18,
      title: routines[18].title,
      description: routines[18].description,
      fromActivity: 7,
      icon: "chairs",
    },
    {
      index: 19,
      title: routines[19].title,
      description: routines[19].description,
      fromActivity: 8,
      icon: "calendar",
    },
    {
      index: 20,
      title: routines[20].title,
      description: routines[20].description,
      fromActivity: 8,
      icon: "write",
    },
    {
      index: 21,
      title: routines[21].title,
      description: routines[21].description,
      fromActivity: 8,
      icon: "calendar",
    },
    {
      index: 22,
      title: routines[22].title,
      description: routines[22].description,
      fromActivity: 9,
      //icon: "calendar",
    },
    {
      index: 23,
      title: routines[23].title,
      description: routines[23].description,
      fromActivity: 9,
      icon: "mindful",
    },
    {
      index: 24,
      title: routines[24].title,
      description: routines[24].description,
      fromActivity: 9,
      //icon: "calendar",
    },
  ];
};

export default useRoutinesList;
