
	



const averages = {
  "Austria": {
    "Other":66.3,	
    "Prefer not to say":66.3,	
    "Female":65.2,	
    "Male":67.5
  },
    
  "Germany": {
    "Other":65.7,	
    "Prefer not to say":65.7,	
    "Female":65.1,	
    "Male":66.3
  },
    
  "Portugal": {
    "Other":65.5,	
    "Prefer not to say":65.5,	
    "Female":63.2,	
    "Male":68.1
  },
    
  "Spain": {
    "Other":65.4,	
    "Prefer not to say":65.4,	
    "Female":64.2,	
    "Male":66.7
  },
    
  "United Kingdom": {
    "Other":58.6,	
    "Prefer not to say":58.6,	
    "Female":56.1,	
    "Male":61.3
  },

  "Switzerland": {
    "Female": 62,
    "Male": 66,
    "Other": 64,
    "Prefer not to say": 64,
  },

  "Other": {  // eu
    "Female": 62,
    "Male": 66,
    "Other": 64,
    "Prefer not to say": 64,
  },
};

export default averages;
