import React from "react";

const Pencil = ({ className, base, baseColor = "#ddf5f5" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      {base && <circle cx="54" cy="54" r="45" fill={baseColor} />}

      <rect
        x="26.82"
        y="44.15"
        width="50.94"
        height="23.02"
        transform="translate(-24.04 53.28) rotate(-45)"
        strokeWidth="2"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#fabb26"
      />

      <polygon
        points="26.14 65.53 18 89.95 42.42 81.81 26.14 65.53"
        fill="#e6bbaa"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <rect
        x="26.82"
        y="51.82"
        width="50.94"
        height="7.67"
        transform="translate(-24.04 53.28) rotate(-45)"
        strokeWidth="2"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#231f20"
      />
      <polygon
        points="20.71 81.81 18 89.95 26.14 87.23 20.71 81.81"
        fill="#231f20"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />

      <circle
        cx="78.44"
        cy="29.51"
        r="11.51"
        fill="#db6b5e"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <rect
        x="68.61"
        y="22.07"
        width="11.51"
        height="23.02"
        transform="translate(-1.96 62.42) rotate(-45)"
        strokeWidth="2"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#faf1ee"
      />
    </svg>
  );
};

export default Pencil;
