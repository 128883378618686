import React from "react";
import DOMPurify from "dompurify";

const OrderedList = ({ items, className }) => {
  return (
    <ol className={className}>
      {items.map((item, index) => {
        return (
          <li
            key={index}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item) }}
          ></li>
        );
      })}
    </ol>
  );
};

export default OrderedList;
