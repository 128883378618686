import React, {useContext} from "react";
import Happy from "../../assets/images/emojis/HappyEmoji.png";
import Inspired from "../../assets/images/emojis/InspiredEmoji.png";
import Calm from "../../assets/images/emojis/CalmEmoji.png";
import Anxious from "../../assets/images/emojis/AnxiousEmoji.png";
import Upset from "../../assets/images/emojis/UpsetEmoji.png";
import Sad from "../../assets/images/emojis/SadEmoji.png";
import {LanguageContext} from '../../Store/LanguageStore'
import Paragraph from "../../Components/SanatizedComponents/Paragraph";

import classes from "./EmojiPicker.module.scss";

const emojis = {
  happy: Happy,
  inspired: Inspired,
  calm: Calm,
  anxious: Anxious,
  upset: Upset,
  sad: Sad,
};

const EmojiPicker = ({ submitAnswer, selected }) => {
  const [language] = useContext(LanguageContext);
  selected = [...selected];
  const emojiClick = (clicked) => {
    const index = selected.indexOf(clicked);
    console.log(index);
    if (index == -1) {
      selected.push(clicked);
    } else {
      selected.splice(index, 1);
    }
    console.log(selected);
    submitAnswer(selected);
  };

  return (
    <div className={classes.wrapper}>
      <Paragraph className={classes.question} text={language.moodquestion} />
      <div className={classes.emojis}>
        {Object.keys(emojis).map((emotion, index) => {
          return (
            <button
              key={index}
              onClick={() => emojiClick(index + 1)}
              className={`${classes.emoji} ${
                selected.includes(index + 1) ? classes.selected : ""
              }`}
            >
              <img className={classes.emojiImg} src={emojis[emotion]}></img>
              <Paragraph
                className={classes.emojiLabel}
                text={language["moodquestion_" + emotion]}
              />
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default EmojiPicker;
