import React, { useRef, useContext, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import classes from "./UserLoginRegisterPages.module.scss";
import { LanguageContext } from "../../../Store/LanguageStore";
import TextField from "../../../Components/Forms/TextField";

import { URLParamsContext } from "../../../Store/URLParamsStore";
import handleError from "../../../lib/handleError";
import useResendConfirmationEmail from "../../../Hooks/useResendConfirmationEmail";
import useParseStringLinks from "../../../Hooks/useParseStringLinks";

const UserRegisterPage = () => {
  const [language] = useContext(LanguageContext);
  const [URLParams] = useContext(URLParamsContext);

  // const qualtricsRef = useRef();
  const emailRef = useRef();
  const passRef = useRef();
  const passRepRef = useRef();

  const confirmationEmailSentMessage = (email) => (
    <Fragment>
      {language.confirmationEmailSent}
      <br />
      {language.cantSeeTheEmail}
      <span
        className={classes.link}
        onClick={() => resendConfirmationEmail(email)}
      >
        {language.resendHere}
      </span>
    </Fragment>
  );

  const [message, setmessage] = useState(undefined);
  const [isError, setisError] = useState(false);
  const [resendConfirmationEmail] = useResendConfirmationEmail(
    setmessage,
    setisError
  );

  const { parseAllLinks } = useParseStringLinks();

  const register = async () => {
    if (!emailValid || !passwordValid || !repPasswordValid) {
      onEmailChange(true);
      onPasswordChange(true);
      passwordRepChange(true);
      return;
    }

    let email = emailRef.current.value;
    let password = passRef.current.value;
    let passwordRep = passRepRef.current.value;
    let qualtrics_id_encrypted = URLParams.code || undefined;

    if (password !== passwordRep) {
      alert(language.passwords_dont_match);
      return;
    }

    let data = {
      email,
      password,
      qualtrics_id_encrypted,
      language: language.language,
    };

    try {
      setisError(false);
      setmessage(<React.Fragment>{language.processing}</React.Fragment>);
      let res = await axios.post(`/user/createAccount`, data);
      setmessage(confirmationEmailSentMessage(email));
    } catch (e) {
      setisError(true);
      switch (e.response.data) {
        case "email registered but unapproved":
          setmessage(
            <React.Fragment>
              {language.yourEmailIsRegisteredButUnconfirmed}
              <br />
              {language.cantSeeTheEmail}
              <span
                className={classes.link}
                onClick={() => resendConfirmationEmail(email)}
              >
                {language.resendHere}
              </span>{" "}
            </React.Fragment>
          );
          break;
        case "email already exists":
          setmessage(
            <React.Fragment>
              {language.emailAlreadyRegistered}
              <Link to={`/login`}>{language.Log_in_here}</Link>
            </React.Fragment>
          );
          break;
        case "Application is closed":
          setmessage(
            <React.Fragment>{language.application_Is_Closed}</React.Fragment>
          );
          break;
        case "missing qualtrics id":
          setmessage(
            <React.Fragment>
              {parseAllLinks(language.missing_qualtrics_id)}
            </React.Fragment>
          );
          break;
        case "Invalid Link":
          setmessage(
            <React.Fragment>{language.invalid_qualtrics_link}</React.Fragment>
          );
          break;
        case "Email doesn't match that on qualtrics":
          setmessage(
            <React.Fragment>{language.invalid_qualtrics_link}</React.Fragment>
          );
          break;
        default:
          handleError(e);
          setmessage(
            <React.Fragment>{language.anErrorOccured}</React.Fragment>
          );
      }
    }
  };

  // const passwordMeetsRequirements = (password) => {
  //   // length >= 6, must contain one upper case letter, one lower case letter and one number
  //   return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/.test(password);
  // };

  const [emailHint, setemailHint] = useState("");
  const [emailValid, setemailValid] = useState(false);
  const onEmailChange = (messageOnEmpty = false) => {
    let email = emailRef.current.value;
    setemailValid(false);

    if (email.length === 0) {
      if (messageOnEmpty) {
        setemailHint({
          isError: true,
          hint: language.please_complete_this_field,
        });
      } else {
        setemailHint("");
      }
      return;
    }

    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(email).toLowerCase())) {
      setemailHint("");
      setemailValid(true);
    } else {
      setemailHint({
        isError: true,
        hint: language.email_invalid,
      });
    }
  };

  const [passwordHint, setpasswordHint] = useState("");
  const [passwordValid, setpasswordValid] = useState(false);
  const onPasswordChange = (messageOnEmpty = false) => {
    let password = passRef.current.value;
    setpasswordValid(false);
    let _hint = "";

    if (password.length === 0) {
      if (messageOnEmpty) {
        setpasswordHint({
          isError: true,
          hint: language.please_complete_this_field,
        });
      } else {
        setpasswordHint(_hint);
      }
      return;
    }

    let lowercase = /[a-z]+/.test(password);
    let uppercase = /[A-Z]+/.test(password);
    let number = /\d+/.test(password);
    let length = password.length > 5;

    if (!lowercase || !uppercase || !number || !length) {
      _hint += language.your_password_must;
      if (!lowercase) _hint += "\n" + language.include_a_lowercase_letter;
      if (!uppercase) _hint += "\n" + language.include_a_uppercase_letter;
      if (!number) _hint += "\n" + language.include_a_number;
      if (!length) _hint += "\n" + language.be_at_least_6_characters;

      _hint = {
        isError: true,
        hint: _hint,
      };
    } else if (password.length !== 0) {
      _hint = {
        isError: false,
        hint: language.password_ok,
      };
      setpasswordValid(true);
    }

    setpasswordHint(_hint);
  };

  const [passwordRepHint, setpasswordRepHint] = useState("");
  const [repPasswordValid, setrepPasswordValid] = useState(false);
  const passwordRepChange = (messageOnEmpty = false) => {
    let password = passRef.current.value;
    let passwordRep = passRepRef.current.value;

    setrepPasswordValid(false);

    if (passwordRep.length === 0) {
      if (messageOnEmpty) {
        setpasswordRepHint({
          isError: true,
          hint: language.please_complete_this_field,
        });
      } else {
        setpasswordRepHint("");
      }
    } else if (passwordRep === password) {
      setpasswordRepHint({
        isError: false,
        hint: language.passwords_match,
      });
      setrepPasswordValid(true);
    } else {
      setpasswordRepHint({
        isError: true,
        hint: language.passwords_domt_match,
      });
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.form}>
          <h1>{language.register}</h1>

          <TextField
            id="registerEmail"
            label={language.email}
            onChange={onEmailChange}
            hint={emailHint}
            ref={emailRef}
          />
          <TextField
            id="registerPassword"
            label={language.password}
            onChange={onPasswordChange}
            hint={passwordHint}
            type="password"
            ref={passRef}
          />
          <TextField
            id="registerPasswordRepeat"
            label={language.confirm_password}
            onChange={passwordRepChange}
            hint={passwordRepHint}
            type="password"
            ref={passRepRef}
          />

          <button className={classes.buttonSubmit} onClick={register}>
            {language.register}
          </button>

          {message && (
            <div
              className={`${classes.message} ${
                isError ? classes.isError : classes.isSuccess
              }`}
            >
              {message}
            </div>
          )}
        </div>
        <div className={classes.wrongViewMessage}>
          {language.already_registered}{" "}
          <Link to={`/login`}>{language.Log_in_here}</Link>
        </div>
      </div>
    </div>
  );
};

export default UserRegisterPage;
