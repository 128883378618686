import React from "react";

const Lighthouse = ({ className, base, baseColor = "#ddf5f5" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      {base && <circle cx="54" cy="54" r="45" fill={baseColor} />}

      <path d="M9,91.66s9-27,45-27,45,27,45,27H9Z" fill="#00759c" />
      <path
        d="M9,91.85s18-9,45-9,45,8.81,45,8.81S83.8,106,54,106,9,91.85,9,91.85Z"
        fill="#fabb26"
      />
      <path
        d="M99,91.66s-18-8.81-45-8.81-45,9-45,9"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />

      <path
        d="M54,35.8H43.44V11.14H54ZM64.56,11.14H54V35.8H64.56Z"
        fill="white"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <polygon
        points="75.11 94.09 32.89 94.09 39.92 23.72 68.08 23.72 75.11 94.09"
        fill="#faf1ee"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M35.7,65.94l1.41-14.07a119.69,119.69,0,0,1,16.89-1,119.69,119.69,0,0,1,16.89,1L72.3,65.94a158.29,158.29,0,0,1-18.3,1A158.29,158.29,0,0,1,35.7,65.94ZM69.48,37.8,68.07,23.72s-4.09-2-14.07-2-14.07,2-14.07,2L38.52,37.8A78.16,78.16,0,0,1,54,35.8,78.16,78.16,0,0,1,69.48,37.8ZM34.3,80,32.89,94.09S38.52,100,54,100s21.11-5.91,21.11-5.91L73.7,80S66.5,82,54,82,34.3,80,34.3,80Z"
        fill="#db6b5e"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M66.32,13.16s-3-2-12.32-2-12.32,2-12.32,2S44.76,3,54,3,66.32,13.16,66.32,13.16Z"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Lighthouse;
