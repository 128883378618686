import React, { Fragment } from 'react';
import { useState } from 'react';
import classes from './BatchSearchResults.module.scss';

const Result = ({result, index}) => {

    const [copied, setcopied] = useState(false);


    const copyToClipboard = (str) => {
        /* Get the text field */
        const el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);

        
        el.select();
        el.setSelectionRange(0, 99999); /*For mobile devices*/
        
        /* Copy the text inside the text field */
        document.execCommand("copy");
        document.body.removeChild(el);

        setcopied(true);
        window.setTimeout(()=>{
            setcopied(false);
        },1500);

      } 


      const copyText = copied ? "Copied" : "Copy to clipboard";

    return (
        <Fragment key={index}>
            <div className={classes.cell}>
                {result.query}
            </div>
            <div className={classes.cell}>
                {result.status}
            </div>
            <div className={classes.cell}>
                {result.email}
            </div>
            <div className={classes.cell}>
                {result.qualtrics_id}
            </div>
            <div className={`${classes.cell}`}>
                {new Date(result.created_at*1000).toLocaleDateString() + " " + new Date(result.created_at*1000).toLocaleTimeString()}
            </div>
            <div className={`${classes.cell}`}>
                {result.current_task}
            </div>
            <div className={classes.cell}>
                { result.confirmationLink ?  <div onClick={!copied ? ()=>copyToClipboard(result.confirmationLink) : ()=>{}} style={{cursor: !copied ? "pointer" : "initial", textDecoration: "underline"}}>{copyText}</div> : ""}
            </div>
        </Fragment>
    )
}

export default Result;
