import React, { useContext } from "react";
import { LanguageContext } from "../../../Store/LanguageStore";

import classes from "./RememberAbc.module.scss";
import Paragraph from "../../SanatizedComponents/Paragraph";
import usePreviousAnswers from "../../../Hooks/usePreviousAnswers";
import PreviousAnswer from "../../PreviousAnswer/PreviousAnswer";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";

const RememberAbc = ({ props }) => {
  const [language] = useContext(LanguageContext);
  const { routines } = language;
  const id = 25;
  const routine = routines[id - 1];

  const [answers, loading] = usePreviousAnswers(
    10,
    3,
    undefined,
    undefined,
    undefined,
    17
  );

  return (
    <LoadingScreen loading={loading}>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <Paragraph text={routine.description.paragraph1} />
          <Paragraph text={routine.description.paragraph2} />
          <PreviousAnswer
            content={answers[0]}
            no_answer_string={
              routine.description.RememberyourABC_NoPreviousAnswer_A
            }
          />
          <Paragraph text={routine.description.paragraph3} />
          <PreviousAnswer
            content={answers[1]}
            no_answer_string={
              routine.description.RememberyourABC_NoPreviousAnswer_B
            }
          />
          <Paragraph text={routine.description.paragraph4} />
          <PreviousAnswer
            content={answers[2]}
            no_answer_string={
              routine.description.RememberyourABC_NoPreviousAnswer_C
            }
          />
        </div>
      </div>
    </LoadingScreen>
  );
};

export default RememberAbc;
