import axios from 'axios';

const showmessage = (message, showAlerts = false) => {
    if(showAlerts)
        alert(message);
}

const handleError = async (e, showAlerts = false, data) => {
    
    console.trace();
    console.error(e);
    let message = "";
    if (e.response) {
        // Request made and server responded
        if(typeof e.response.data === "object"){
            message = e.response.data.message;
        }else{
            message = e.response.data;
        }
        console.log(e.response.data);
        console.log(e.response.status);
        console.log(e.response.headers);
    } else if (e.request) {
        // The request was made but no response was received
        message = "no response recieved";
        console.log(e.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        if(typeof e.message === "object"){
            message = e.message.message;
        }else if(typeof e === "string"){
            message = e;
        }else{
            message = e.message;
        }
        console.log('Error', e.message);
    }
    showmessage(message,showAlerts);
    
    try{
        await axios.post("/user/reportError",{error: message, data});
    }catch(e){
        console.error("Error reporting UI error")
        console.error(e);
    }

}

export default handleError;