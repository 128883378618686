import React from "react";

const Path = ({ className, base, baseColor = "#ddf5f5" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      {base && <circle cx="54" cy="54" r="45" fill={baseColor} />}

      <rect
        x="49.5"
        y="2.5"
        width="9"
        height="103"
        strokeWidth="2"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#404a45"
      />
      <path
        d="M16,77.88a7,7,0,0,1-7-7V19.12a7,7,0,0,1,7-7H82.34a7,7,0,0,1,5.82,3.12l17.25,25.88a7,7,0,0,1,0,7.77L88.16,74.76a7,7,0,0,1-5.82,3.12Z"
        fill="#faf1ee"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M15,70.87V19.13a1,1,0,0,1,1-1H82.34a1,1,0,0,1,.83.44l17.25,25.88a1,1,0,0,1,0,1.1L83.17,71.43a1,1,0,0,1-.83.44H16A1,1,0,0,1,15,70.87Z"
        fill="#a18c73"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <polygon
        points="80.26 22.18 63 22.18 78.22 45 63 67.83 80.26 67.83 95.48 45 80.26 22.18"
        fill="#faf1ee"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path d="M40.69,58.1a17.39,17.39,0,0,0-2.77-5.42L36.65,51a34.84,34.84,0,0,1-8.87,10.72c.1.39.36,1.35.36,1.35v.08a2.3,2.3,0,0,1,.92.86,1.86,1.86,0,0,1,.14,1.61,1.81,1.81,0,0,1-1.14,1.09,3.3,3.3,0,0,1-1,.15c-1.47,0-3.76-.71-4.41-2.29-.25-.47-1.94-3.55-1.94-3.55l-.57-1,.88-.79a33.12,33.12,0,0,0,8.07-10.7l-.29,0c-1.92,0-4.13-1.75-4.39-3.46a2.3,2.3,0,0,1,.91-2.21c1-6.81,4.65-13.92,10.88-15A5,5,0,0,1,36.41,24a4.87,4.87,0,0,1,4.4-2.88h.24a4.8,4.8,0,0,1,4.72,4A4.88,4.88,0,0,1,44.16,30a12.86,12.86,0,0,1,3.15,5.43c1.36,4,4.28,5.43,5.34,5.62l.08-.08.61,0h.1A2,2,0,0,1,55.2,42a3.06,3.06,0,0,1,.29,1.38c0,1.58-.88,3.94-2.76,3.94A2.15,2.15,0,0,1,51.55,47a13.22,13.22,0,0,1-8.29-4.33c-.13,3.23,1,5.7,2.91,8.76,1.33,2.4,1.91,5.76,2.42,8.71.11.65.22,1.27.32,1.84,1.22.12,2.88.3,3,.32H52l.07,0A1.85,1.85,0,0,1,53.52,64c0,.89.06,2.11-7.94,4.84l-1.47.5Z" />
      <path
        d="M42.45,29.26c3.29-1.41,2.13-6.82-1.53-6.68a3.54,3.54,0,0,0-2.79,5.66c.07.09.49.78-.43.89-6.59.17-10.28,7.89-11,14.67-2.87.87,2.5,5.09,3.38,2.19-.62-2.74-.26-6.53,2.86-8.86,0,0,1.49,11.95-10.94,23.16,0,0,1.94,3.53,2,3.65.35,1,2.71,1.63,3.62,1.33a.35.35,0,0,0,.18-.5c-.28-.47-.9-.29-1.11-1.37,0,0-.56-2.06-.58-2.33,0,0,6-3.79,10.3-12.89l2.72,3.6a18.86,18.86,0,0,1,3,5.89l3,9.75s8.89-3,6.52-3.7c-.18,0-3.91-.41-3.95-.41-.69-3-1.15-8.2-2.76-11.12-2.93-4.6-3.95-8-2.54-13.51a12.31,12.31,0,0,0,9.8,6.83c1.39,1.54,2.57-3.21,1.23-3.11-.67.66-5.56-.84-7.53-6.59a11.23,11.23,0,0,0-3.43-5.39c-.25-.21-.82-.77,0-1.16"
        fill="#faf1ee"
        stroke="#000"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default Path;
