import React, { Fragment, useState } from 'react';
import TextField from '../../../Components/Forms/TextField';
import Axios from 'axios';
import { useRef } from 'react';
import handleError from '../../../lib/handleError';

const GetLink = () => {
    const emailRef = useRef();
    const qualtricsRef = useRef();
    const [link, setlink] = useState(undefined);

    const getLink = async () => {
        try{
            let result = await Axios.post('/admin/getlink',{email: emailRef.current.value, id: qualtricsRef.current.value});
            setlink(result.data);
        }catch(e){
            handleError(e);
        }
    }


    return (
        <div>
            <p>Enter the email you wish to make a link for:</p>
            <TextField ref={emailRef}/>
            <p>Enter the users qualtrics id (leave blank for a random id):</p>
            <TextField ref={qualtricsRef}/>
            <button onClick={getLink}>Go</button>
            {
                link ? (
                    <div dangerouslySetInnerHTML={{__html: link}} style={{fontSize: 13, maxWidth: "100%"}}/>
                ) : <Fragment></Fragment>
            }
        </div>
    );
}

export default GetLink;
