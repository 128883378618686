import React from "react";

const Write = ({ className, base, baseColor = "#ddf5f5" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      {base && <circle cx="54" cy="54" r="45" fill={baseColor} />}
      <rect
        x="9.34"
        y="18.91"
        width="49.85"
        height="69.6"
        transform="translate(-7.25 5.39) rotate(-8.13)"
        strokeWidth="2"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#faf1ee"
      />
      <path
        d="M10.36,33.26,2.14,34.43m9.34,6.62L3.25,42.23M4.37,50l8.22-1.17m-7.11,9,8.23-1.18m1.11,7.8L6.59,65.61m1.12,7.8,8.22-1.17M17.05,80,8.82,81.21"
        fill="#faf1ee"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M89.08,71.29c2.65,5.48,4.8,9.93,8.54,8.12s1.59-6.25-1.06-11.74"
        fill="#bfdcc1"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <circle
        cx="83.77"
        cy="50.78"
        r="20.77"
        fill="#bfdcc1"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <circle
        cx="83.77"
        cy="50.78"
        r="15.5"
        fill="#a18c73"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Write;
