import React, { Fragment, useContext } from "react";
import Footer from "./Footer/Footer";

import Heading from "../../Components/SanatizedComponents/Heading";
import styles from "./Patients.module.scss";
import FAQ from "../../Components/FAQ/FAQ";
import Lighthouse from "../../Components/icons/Lighthouse";

import Compass from "../../Components/icons/Compass";
import Mindful from "../../Components/icons/Mindful";
import Binoculars from "../../Components/icons/Binoculars";
import { LanguageContext } from "../../Store/LanguageStore";
import RenderFromKeys from "../../Components/RenderFromKeys/RenderFromKeys";
import PatientsNav from "../../Components/PatientsNav";

const Patients = () => {
  const [language] = useContext(LanguageContext);

  return (
    <Fragment>
      <main>
        <section className={styles.intro}>
          <div className={styles.inner}>
            <Lighthouse className={styles.introIcon} base />
            <Heading text={language.unsuccessfulTreatment.heading1} />
          </div>
        </section>
        <PatientsNav />
        <section className={styles.section}>
          <div className={styles.inner}>
            <h2>{language.unsuccessfulTreatment.heading2}</h2>
            <p>{language.unsuccessfulTreatment.paragraph1}</p>
            <p>{language.unsuccessfulTreatment.paragraph2}</p>
            <ul className={styles.grid}>
              <li>
                <Compass className={styles.gridIcon} base />
                {language.unsuccessfulTreatment.paragraph3}
              </li>
              <li>
                <Mindful className={styles.gridIcon} base />
                {language.unsuccessfulTreatment.paragraph4}
              </li>
              <li>
                <Binoculars className={styles.gridIcon} base />
                {language.unsuccessfulTreatment.paragraph5}
              </li>
            </ul>
            <p>{language.unsuccessfulTreatment.paragraph6}</p>
          </div>
        </section>

        <section className={styles.section}>
          <div className={styles.inner}>
            <RenderFromKeys content={language.unsuccessfulTreatment.TenFacts} />
          </div>
        </section>

        <section className={styles.sectionFaq}>
          <div className={styles.inner}>
            <FAQ faqs={language.unsuccessfulTreatment.FAQ} />
          </div>
        </section>
      </main>
      <div className={styles.footerWrapper}>
        <Footer />
      </div>
    </Fragment>
  );
};

export default Patients;
