import React, { useState, useEffect, useContext } from "react";
import { Switch, Route, Link, useLocation, Redirect } from "react-router-dom";
import {LanguageContext} from '../../Store/LanguageStore';

import classes from "./ActivityWrapper.module.scss";

import useSubmitTask from "../../Hooks/useSubmitTask";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { AccountContext } from "../../Store/AccountStore";
import { RedirectContext } from "../../Store/RedirectTo";
import usePersistedState from "../../Hooks/usePersistedState";
import { useRef } from "react";

import ProgressBar from '../../Components/ProgressBar/ProgressBar'

const ActivityWrapper = (props) => {
  let { match, pages, task_id, attempt } = props;
  const location = useLocation();
  const [language] = useContext(LanguageContext);
  const [user, , loading] = useContext(AccountContext);
  const [redirectTo] = useContext(RedirectContext);
  const wrapperRef = useRef();
  

  if(!loading && user.progress[task_id-1].disabled){
    redirectTo('/user/map');
  }

  console.log(`currentSlide-${task_id}-${attempt}`)
  const [currentIndex, setcurrentIndex, clearStorage] = usePersistedState(`currentSlide-${task_id}-${attempt}`,0);
  

  const [canContinue, setcanContinue] = useState(true);

  const [submitTask] = useSubmitTask();


  const _finished = (e) => {
    clearStorage();
    submitTask(e,task_id);
  }

  const changeSlideIndex = (delta) => {
    if (!canContinue && delta > 0) return;
    wrapperRef.current.scrollIntoView();
    setcurrentIndex(currentIndex + delta);
  };

  let comp = React.cloneElement(pages[currentIndex].component(), {
    allowContinue: () => setcanContinue(true),
    restrictContinue: () => setcanContinue(false),
  });

  return (
    <div className={classes.wrapper} ref={wrapperRef}>
      <div className={classes.content}>
        {comp}
      </div>

      <nav className={classes.nav}>
        {currentIndex > 0 ? (
          <div onClick={() => changeSlideIndex(-1)} style={{ color: "#FFFFFF", cursor: "pointer" }}>
            <FaArrowLeft />
          </div>
        ) : 
          <div />
        }

        <ProgressBar totalPages={pages.length} currentIndex={currentIndex}/>

        {canContinue ? (
          currentIndex < pages.length - 1 ? (
            <div onClick={() => changeSlideIndex(1)} style={{ color: "#FFFFFF", cursor: "pointer" }}>
              <FaArrowRight />
            </div>
          ) : (
            <Link to={`/user/map`}>
              <div onClick={_finished}>{language.submit}</div>
            </Link>
          )
        ) : (
          <div
            style={{ color: "#CCCCCC", cursor: "pointer" }}
            onClick={() => alert(language.unanswered_questions_message)}
          >
            <FaArrowRight />
          </div>
        )}
      </nav>
    </div>
  );
};

export default ActivityWrapper;
