import React, { useContext, useEffect, useState } from "react";

import classes from "./AddToHomeScreenInstructions.module.scss";
import { LanguageContext } from "../../Store/LanguageStore";
import { a2hsContext } from "../../Store/AddToHomeScreenStore";

// import testImage from '../../assets/images/A2HS_Guides/English/Chrome2.png'
//const images = require.context('../../assets/images/A2HS_Guides/', true);

const Addtohomescreeninstructions = (props) => {
  const [language] = useContext(LanguageContext);
  const [platform] = useContext(a2hsContext);

  const [images, setimages] = useState([]);

  useEffect(() => {
    loadImages();
  }, [platform]);

  const loadImages = async () => {
    let browserName = platform.name;

    const _images = [];

    for (let i = 1; i < 5; i++) {
      let imageName = browserName + i.toString();
      try {
        const image1 = await import(
          `../../assets/images/A2HS_Guides/${language.language}/${imageName}.png`
        );
        _images.push(image1.default);
      } catch (e) {
        console.log(e);
        break;
      }
    }

    setimages((prevImages) => [..._images]);
  };

  return (
    <div className={classes.content}>
      <section className={classes.section}>
        <div className={classes.inner}>
          <h2 className={classes.sectionTitle}>
            Add to homescreen: {platform.name}
          </h2>
          <div className={classes.images}>
            {images.map((image) => {
              return (
                <React.Fragment key={image}>
                  <div className={classes.imgContainer}>
                    <img src={image} className={classes.image}></img>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Addtohomescreeninstructions;
