import React, {useContext, useEffect} from 'react';
import { AccountContext } from '../../Store/AccountStore';

import Activity1 from "./Activity1/Activity1"
import Activity2 from "./Activity2/Activity2"
import Activity3 from "./Activity3/Activity3"
import Activity4 from "./Activity4/Activity4"
import Activity5 from "./Activity5/Activity5"
import Activity6 from "./Activity6/Activity6"
import Activity7 from "./Activity7/Activity7"
import Activity8 from "./Activity8/Activity8"
import Activity9 from "./Activity9/Activity9"
import Activity10 from "./Activity10/Activity10"
import useUsageMonitoring from '../../Hooks/useUsageMonitoring';
import LoadingScreen from '../../Components/LoadingScreen/LoadingScreen';

const ActivityPage = (props) => {
    const [user,,loading] = useContext(AccountContext);

    return <LoadingScreen loading={loading}><InnerActivityPage {...props} user={user}/></LoadingScreen>
}

const InnerActivityPage = (props) => {

  const { id, user } = props;

  const attempt = user.progress[id-1].current_attempt;

  if(attempt == undefined || attempt == "undefined") attempt = 1;

  const [beginUsageMonitoring, endUsageMonitoring, clearTimeouts] = useUsageMonitoring(parseFloat(id)+2);

  useEffect(() => {
      beginUsageMonitoring();
      return () => {
        endUsageMonitoring();
        clearTimeouts();
      };
    }, [id]);


  const activities = [
      <Activity1 {...props} attempt={attempt}/>,
      <Activity2 {...props} attempt={attempt}/>,
      <Activity3 {...props} attempt={attempt}/>,
      <Activity4 {...props} attempt={attempt}/>,
      <Activity5 {...props} attempt={attempt}/>,
      <Activity6 {...props} attempt={attempt}/>,
      <Activity7 {...props} attempt={attempt}/>,
      <Activity8 {...props} attempt={attempt}/>,
      <Activity9 {...props} attempt={attempt}/>,
      <Activity10 {...props} attempt={attempt}/>
  ];

  return activities[id-1];
}

export default ActivityPage;
