import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "../../Store/AccountStore";
import { LanguageContext } from "../../Store/LanguageStore";

import useStepsList from "../../lib/useStepsList";

import ActivityPreview from "./ActivityPreview";
import classes from "./SteppingStones.module.scss";
import IconMap from "../../Components/icons/Map";
import Heading from "../../Components/SanatizedComponents/Heading";
import Paragraph from "../../Components/SanatizedComponents/Paragraph";

/*
  possible states:                                : disabled  active  submitted
    disabled                                      : true      false   false
    enabled, havent started                       : false     false   false
    enabled, started but not finished             : false     true    false
    enabled, finished                             : false     false   true
    enabled, finished and new attempt started     : false     true    true

  how to represent this
    disabled: if the user has yet to unlock
    active: if the user has an unfinished attempt
    submitted: if the user has finished before now
*/

const SteppingStones = (props) => {
  const [user] = useContext(AccountContext);
  const [language] = useContext(LanguageContext);
  const steps = useStepsList();

  if (user.progress) {
    for (const task of user.progress) {
      steps[task.task - 1].active = task.active;
      steps[task.task - 1].complete = task.complete;
      steps[task.task - 1].disabled = task.disabled;
      steps[task.task - 1].current_attempt = task.current_attempt;
    }
  }

  return (
    <div>
      <div className={classes.headerWrapper}>
        <header className={classes.header}>
          <IconMap base className={classes.headerIcon} />
          <Heading text={language.map_menu}/>
          <Paragraph text={language.map_description}/>
        </header>
      </div>

      <div className={classes.wrapper}>
        <ol className={classes.list}>
          {steps
            .sort((a, b) => a.index - b.index)
            .map((activity, i) => (
              <li className={classes.item} key={activity.index}>
                <ActivityPreview
                  index={i}
                  {...props}
                  activity={activity}
                  disabled={activity.disabled}
                  active={activity.active}
                  complete={activity.complete}
                  current_attempt={activity.current_attempt}
                  icon={activity.icon}
                  activityIcons={activity.activityIcons}
                />
              </li>
            ))}
        </ol>
      </div>
    </div>
  );
};

export default SteppingStones;
