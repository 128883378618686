import React, { Fragment, useContext, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Header from "./Components/Header/Header";
import UserComponents from "./Pages/UserComponents/UserComponents";
import AdminPage from "./Pages/admin/AdminPage";
import Home from "./Pages/LandingPages/Home";
import About from "./Pages/LandingPages/About";
import NotReady from "./Pages/LandingPages/NotReady";
import TermsOfUse from "./Pages/LandingPages/TermsOfUse";
import Support from "./Pages/LandingPages/Support";
import UserLogin from "./Pages/UserComponents/UserLogin/UserLogin";
import ScrollToTop from "./Components/ScrollToTop";
import Routechangetracker from "./Components/RouteChangeTracker";
import Popup from "./Components/Popup/Popup";
import StudyInfo from "./Pages/LandingPages/StudyInfo";
import SessionUsageMonitor from "./utils/SessionUsageMonitor";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import useParseStringLinks from "./Hooks/useParseStringLinks";
import { LanguageContext } from "./Store/LanguageStore";
import Addtohomescreeninstructions from "./Pages/AddToHomeScreenInstructions/AddToHomeScreenInstructions";

// Focus Group Pages
import Patients from "./Pages/LandingPages/Patients";
import EndingTreatment from "./Pages/LandingPages/EndingTreatment";
import OtherRoutes from "./Pages/LandingPages/OtherRoutes";
import Clinics from "./Pages/LandingPages/Clinics";

const Main = () => {
  const { parseTermsOfUseLinks } = useParseStringLinks();
  const [language] = useContext(LanguageContext);

  return (
    <Fragment>
      {getCookieConsentValue() != "true" ? (
        <CookieConsent
          debug={true}
          style={{ textAlign: "center" }}
          buttonText={language.cookie_notice_button}
        >
          {parseTermsOfUseLinks(language.cookie_notice_message)}
        </CookieConsent>
      ) : (
        <React.Fragment />
      )}
      <ScrollToTop />
      <Routechangetracker />
      <Header />

      <Switch>
        <Route exact path="/" component={() => <Home />} />
        <Route exact path="/about" component={() => <About />} />
        <Route exact path="/not-ready" component={() => <NotReady />} />

        <Route
          exact
          path={["/patients", "/patienten", "/pacientes"]}
          component={() => <Patients />}
        />
        <Route
          exact
          path={[
            "/patients/ending-treatment",
            "/patienten/ending-treatment",
            "/pacientes/ending-treatment",
          ]}
          component={() => <EndingTreatment />}
        />
        <Route
          exact
          path={[
            "/patients/other-routes",
            "/patienten/other-routes",
            "/pacientes/other-routes",
          ]}
          component={() => <OtherRoutes />}
        />

        <Route
          exact
          path={["/clinics", "/kliniken", "/clínicas"]}
          component={() => <Clinics />}
        />

        <Route exact path="/support" component={() => <Support />} />
        <Route exact path="/terms-of-use" component={() => <TermsOfUse />} />
        <Route exact path="/study-info" component={() => <StudyInfo />} />
        <Route path="/admin/" component={(props) => <AdminPage {...props} />} />
        <Route
          path="/user/"
          component={(props) => <UserComponents {...props} />}
        />
        <Route
          path="/addtohomescreen/"
          component={(props) => <Addtohomescreeninstructions {...props} />}
        />
        <UserLogin />
      </Switch>
      <Popup />
    </Fragment>
  );
};

export default Main;
