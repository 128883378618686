import React, { useContext } from "react";
import axios from "axios";
import { LanguageContext } from "../../../Store/LanguageStore";
import handleError from "../../../lib/handleError";

const AdminLogoutButton = ({ getAccountInfo }) => {
  const [language] = useContext(LanguageContext);

  const logout = async () => {
    try {
      let res = await axios.get("/admin/logout");
      alert("logged out");
      getAccountInfo();
    } catch (e) {
      handleError(e, true);
    }
  };

  return (
    <div>
      <button onClick={logout}>{language.top_menu_logout}</button>
    </div>
  );
};

export default AdminLogoutButton;
