import React from "react";

const Binoculars = ({ className, base, baseColor = "#ddf5f5" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      {base && <circle cx="54" cy="54" r="45" fill={baseColor} />}

      <path
        d="M73.36,24.47C65.26,24.47,54,36,54,36S42.74,24.47,34.64,24.47c-12.78,0-23.14,8.46-23.14,18.89S21.86,62.25,34.64,62.25a27.28,27.28,0,0,0,10.36-2s0-7.85.69-9.72A1.2,1.2,0,0,1,48,51v3H60V51a1.2,1.2,0,0,1,2.33-.38c.67,1.83.67,9.65.67,9.65a27.28,27.28,0,0,0,10.36,2c12.78,0,23.14-8.46,23.14-18.89S86.14,24.47,73.36,24.47Z"
        fill="#231f20"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <circle
        cx="54"
        cy="54.18"
        r="6.08"
        fill="#231f20"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <circle
        cx="54"
        cy="54.18"
        r="3.72"
        fill="#db6b5e"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />

      <circle
        cx="83.75"
        cy="58"
        r="21.25"
        fill="#231f20"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <circle
        cx="83.75"
        cy="58"
        r="18.1"
        fill="#faf1ee"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M74.29,48.54A13.38,13.38,0,0,1,93.21,67.46C88,72.68,69.07,53.76,74.29,48.54Z"
        fill="#fff"
      />

      <circle
        cx="24.25"
        cy="58"
        r="21.25"
        fill="#231f20"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <circle
        cx="24.25"
        cy="58"
        r="18.1"
        fill="#faf1ee"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M14.79,48.54A13.38,13.38,0,0,1,33.71,67.46C28.49,72.68,9.57,53.76,14.79,48.54Z"
        fill="#fff"
      />

      <circle
        cx="43.5"
        cy="28.25"
        r="8.5"
        fill="#231f20"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <circle
        cx="64.5"
        cy="28.25"
        r="8.5"
        fill="#231f20"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Binoculars;
