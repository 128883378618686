import React, { useContext, Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer/Footer";
import classes from "./Home.module.scss";
import IconMap from "../../Components/icons/Map";
import IconSteps from "../../Components/icons/Steps";
import IconHeart from "../../Components/icons/Heart";
import IconChairs from "../../Components/icons/Chairs";
import IconCalendar from "../../Components/icons/Calendar";
import IconPlant from "../../Components/icons/Plant";
import IconPath from "../../Components/icons/Path";
import IconTorch from "../../Components/icons/Torch";
import IconWrite from "../../Components/icons/Write";
import IconMindful from "../../Components/icons/Mindful";

import Heading from "../../Components/SanatizedComponents/Heading";
import Paragraph from "../../Components/SanatizedComponents/Paragraph";
import DOMPurify from "dompurify";
import { LanguageContext } from "../../Store/LanguageStore";
import { ApplicationStatusContext } from "../../Store/ApplicationStatusStore";

const LandingPage = () => {
  const [language] = useContext(LanguageContext);
  const [applicationStatus] = useContext(ApplicationStatusContext);
  const strings = language.home;

  return (
    <Fragment>
      <main>
        <section className={classes.intro}>
          <div className={classes.inner}>
            <IconMap className={classes.introIcon} base />
            <Heading text={strings.title} />
          </div>
        </section>

        <section className={classes.helpTo}>
          <div className={classes.inner}>
            <Heading text={strings.help_to_title} level="h2" />
            <ul className={classes.grid}>
              <li>
                <IconHeart className={classes.gridIcon} base />
                {strings.help_to_1}
              </li>
              <li>
                <IconPlant className={classes.gridIcon} base />
                {strings.help_to_2}
              </li>
              <li>
                <IconSteps className={classes.gridIcon} base />
                {strings.help_to_3}
              </li>
            </ul>
          </div>
        </section>

        <section className={classes.ctaSection}>
          <div className={classes.inner}>
            <Link
              className={classes.cta}
              to={applicationStatus.studyRunning ? "/study-info" : "/register"}
            >
              {strings.application_cta}
            </Link>
            <div className={classes.notReady}>
              <Link to="/not-ready">{strings.not_ready_link}</Link>
            </div>
          </div>
        </section>
        <section className={classes.features}>
          <div className={classes.inner}>
            <Heading text={strings.features_title} level="h2" />
            <ul className={classes.grid}>
              <li className={classes.feature}>
                <IconPath
                  base
                  baseColor="#aca4c1"
                  className={classes.gridIcon}
                />
                <Paragraph text={strings.features_1} />
              </li>
              <li className={classes.feature}>
                <IconTorch
                  base
                  baseColor="#aca4c1"
                  className={classes.gridIcon}
                />
                <Paragraph text={strings.features_2} />
              </li>
              <li className={classes.feature}>
                <IconCalendar
                  base
                  baseColor="#aca4c1"
                  className={classes.gridIcon}
                />{" "}
                <Paragraph text={strings.features_3} />
              </li>
              <li className={classes.feature}>
                <IconWrite
                  base
                  baseColor="#aca4c1"
                  className={classes.gridIcon}
                />
                <Paragraph text={strings.features_4} />
              </li>
              <li className={classes.feature}>
                <IconMindful
                  base
                  baseColor="#aca4c1"
                  className={classes.gridIcon}
                />
                <Paragraph text={strings.features_5} />
              </li>
              <li className={classes.feature}>
                <IconChairs
                  base
                  baseColor="#aca4c1"
                  className={classes.gridIcon}
                />
                <Paragraph text={strings.features_6} />
              </li>
            </ul>
          </div>
        </section>
        <section className={classes.testimonialsWrapper}>
          <div className={classes.inner}>
            <Heading text={strings.testimonials_title} level="h2" />
            <ul className={classes.testimonials}>
              <li className={classes.testimonial}>
                <blockquote
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(strings.testimonials_1_quote),
                  }}
                />
                <div className={classes.attribution}>
                  – {strings.testimonials_1_name}
                </div>
              </li>
              <li className={classes.testimonial}>
                <blockquote
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(strings.testimonials_2_quote),
                  }}
                />
                <div className={classes.attribution}>
                  – {strings.testimonials_2_name}
                </div>
              </li>
              <li className={classes.testimonial}>
                <blockquote
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(strings.testimonials_3_quote),
                  }}
                />
                <div className={classes.attribution}>
                  – {strings.testimonials_3_name}
                </div>
              </li>
            </ul>
          </div>
        </section>
        <section className={classes.about}>
          <div className={classes.inner}>
            <Heading text={strings.about_title} level="h2" />
            <ul className={classes.aboutPoints}>
              <li
                className={classes.aboutPoint}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(strings.about_paragraph_1),
                }}
              />
              <li
                className={classes.aboutPoint}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(strings.about_paragraph_2),
                }}
              />
            </ul>
            <Link
              className={classes.aboutLink}
              to="/about"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(strings.about_link),
              }}
            />
          </div>
        </section>
      </main>
      <div className={classes.footerWrapper}>
        <Footer />
      </div>
    </Fragment>
  );
};

export default LandingPage;
