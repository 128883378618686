import React, { Fragment, useContext } from "react";
import { Link } from "react-router-dom";

import Footer from "./Footer/Footer";
import PatientsNav from "../../Components/PatientsNav";

import Heading from "../../Components/SanatizedComponents/Heading";
import YouTubeEmbed from "../../Components/YouTubeEmbed/YouTubeEmbed";

import Map from "../../Components/icons/Map";
import Lighthouse from "../../Components/icons/Lighthouse";
import Steps from "../../Components/icons/Steps";

import styles from "./Patients.module.scss";

import { LanguageContext } from "../../Store/LanguageStore";

const Patients = () => {
  const [language] = useContext(LanguageContext);

  return (
    <Fragment>
      <main>
        <section className={styles.intro}>
          <div className={styles.inner}>
            <YouTubeEmbed src={language.patients.videoURL} />
            <Heading text={language.patients.heading1} />
            <p>{language.patients.paragraph1}</p>
          </div>
        </section>
        <PatientsNav />

        <section className={styles.section}>
          <div className={styles.inner}>
            <Lighthouse className={styles.gridIcon} base />
            <h2>{language.patients.heading2}</h2>
            <p>{language.patients.paragraph2}</p>
            <Link
              className={styles.newRouteLink}
              to="/patients/ending-treatment"
            >
              {language.patients.button1}
            </Link>
          </div>
        </section>
        <section className={styles.section}>
          <div className={styles.inner}>
            <Steps className={styles.gridIcon} base />
            <h2>{language.patients.heading3}</h2>
            <p>{language.patients.paragraph3}</p>
            <Link className={styles.newRouteLink} to="/patients/other-routes">
              {language.patients.button2}
            </Link>
          </div>
        </section>
        <section className={styles.section}>
          <div className={styles.inner}>
            <Map className={styles.gridIcon} base />
            <h2>{language.patients.heading4}</h2>
            <p>{language.patients.paragraph4}</p>
            <Link className={styles.newRouteLink} to="/">
              {language.patients.button3}
            </Link>
          </div>
        </section>
      </main>
      <div className={styles.footerWrapper}>
        <Footer />
      </div>
    </Fragment>
  );
};

export default Patients;
