import React, { Fragment, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { AccountContext } from "../../../Store/AccountStore.js";
import { LanguageContext } from "../../../Store/LanguageStore.js";
import { MdMenu } from "react-icons/md";
import AccountDropdown from "./AccountDropdown";

import classes from "./Navbar.module.scss";
import Modal from "react-modal";

const Navbar = ({ className }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [user] = useContext(AccountContext);
  const [language] = useContext(LanguageContext);

  const Menu = () => (
    <Fragment>
      <NavLink
        exact
        to={`/`}
        className={classes.link}
        activeClassName={classes.linkActive}
        onClick={() => setMenuOpen(false)}
      >
        {language.top_menu_home}
      </NavLink>
      <NavLink
        exact
        to={`/about`}
        className={classes.link}
        activeClassName={classes.linkActive}
        onClick={() => setMenuOpen(false)}
      >
        {language.top_menu_about}
      </NavLink>
      <NavLink
        exact
        to={`/support`}
        className={classes.link}
        activeClassName={classes.linkActive}
        onClick={() => setMenuOpen(false)}
      >
        {language.support_link}
      </NavLink>
      {user.id != null ? (
        <AccountDropdown />
      ) : (
        <Fragment>
          <NavLink
            to={"/login"}
            className={classes.link}
            activeClassName={classes.linkActive}
            onClick={() => setMenuOpen(false)}
          >
            {language.login}
          </NavLink>
        </Fragment>
      )}
    </Fragment>
  );

  return (
    <div className={className}>
      <nav className={classes.navbar}>
        <Menu />
      </nav>
      <button className={classes.toggle} onClick={() => setMenuOpen(true)}>
        <MdMenu size="1.5em" />
      </button>
      <Modal
        className={classes.menuModal}
        overlayClassName={classes.menuModalOverlay}
        isOpen={menuOpen}
        onRequestClose={() => setMenuOpen(false)}
      >
        <Menu />
      </Modal>
    </div>
  );
};

export default Navbar;
