import React from "react";
import classes from "./checkbox.module.scss";
import { BsCheckBox, BsSquare } from "react-icons/bs";
import Paragraph from "../../SanatizedComponents/Paragraph";

const Checkbox = ({ text, checked = true, onCheck }) => {
  return (
    <div className={classes.answer}>
      <div className={classes.checkboxWrapper} onClick={onCheck}>
        {checked ? (
          <BsCheckBox className={classes.checkbox} />
        ) : (
          <BsSquare className={classes.checkbox} />
        )}
      </div>
      <Paragraph text={text} />
    </div>
  );
};

export default Checkbox;
