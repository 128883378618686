import React from "react";
import { FaFacebook, FaTwitter, FaInstagram, FaEnvelope } from "react-icons/fa";
import classes from "./iconStyles.module.scss";

const SocialIcons = () => {
  return (
    <div className="icons">
      <a href="https://www.facebook.com/MyJourney-100860608498653">
        <FaFacebook className={classes.icon} />
      </a>

      <a href="https://twitter.com/MyJourney_MAP">
        <FaTwitter className={classes.icon} />
      </a>
      <a href="https://www.instagram.com/myjourney_map/">
        <FaInstagram className={classes.icon} />
      </a>
      <a href="mailto:myjourney@cardiff.ac.uk">
        <FaEnvelope className={classes.icon} />
      </a>
    </div>
  );
};

export default SocialIcons;
