import React from "react";
import classes from "./FooterLogos.module.scss";

const partners = [
  {
    name: "Cardiff University",
    imgSrc: require("../../../assets/images/cardiff-uni.svg"),
  },
  {
    name: "Associação Portuguesa de Fertilidade",
    imgSrc: require("../../../assets/images/apf.svg"),
  },
  {
    name: "Economic and Social Research Council",
    imgSrc: require("../../../assets/images/esrc.svg"),
  },
];

const FooterLogos = () => {
  return (
    <div className={classes.wrapper}>
      <ul className={classes.logos}>
        {partners.map((partner, index) => (
          <img
            className={classes.logo}
            alt={partner.name}
            src={partner.imgSrc}
            key={index}
          />
        ))}
      </ul>
    </div>
  );
};

export default FooterLogos;
