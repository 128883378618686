import React, { Fragment, useState, useContext } from "react";
import Footer from "./Footer/Footer";

import Heading from "../../Components/SanatizedComponents/Heading";
import styles from "./Clinics.module.scss";

import FAQ from "../../Components/FAQ/FAQ";
import Chairs from "../../Components/icons/Chairs";

import RenderFromKeys from "../../Components/RenderFromKeys/RenderFromKeys";

import { LanguageContext } from "../../Store/LanguageStore";
import OrderedList from "../../Components/SanatizedComponents/OrderedList";
import Paragraph from "../../Components/SanatizedComponents/Paragraph";
import Resource from "../../Components/Resources/Resource";

const Clinics = () => {
	const [language] = useContext(LanguageContext);

	const resources = language.clinics.resourcesBlock.resources;

	const [activeTab, setActiveTab] = useState(1);

	return (
		<Fragment>
			<main>
				<section className={styles.intro}>
					<div className={styles.inner}>
						<Chairs
							className={styles.introIcon}
							base
						/>
						<Heading text={language.clinics.title} />
						<Paragraph text={language.clinics.paragraph1} />
					</div>
				</section>

				<section className={styles.section}>
					<div className={styles.inner}>
						<RenderFromKeys content={language.clinics.why} />
					</div>
				</section>

				<section className={styles.section}>
					<div className={styles.inner}>
						<RenderFromKeys content={language.clinics.when} />
					</div>
				</section>

				<section className={styles.section}>
					<div className={styles.innerForTabs}>
						<Heading text={language.clinics.how.title} />
						<div className={styles.tabWrapper}>
							<div className={styles.tabBar}>
								<button
									className={activeTab === 1 ? styles.tabActive : styles.tab}
									onClick={() => setActiveTab(1)}
								>
									{language.clinics.how.beforeTreatmentEnds.header}
								</button>
								<button
									className={activeTab === 2 ? styles.tabActive : styles.tab}
									onClick={() => setActiveTab(2)}
								>
									{language.clinics.how.afterTreatmentEnds.header}
								</button>
							</div>
							<div className={styles.tabContent}>
								{activeTab === 1 && (
									<div>
										<RenderFromKeys
											content={language.clinics.how.beforeTreatmentEnds.content}
										/>
									</div>
								)}
								{activeTab === 2 && (
									<div>
										<RenderFromKeys
											content={language.clinics.how.afterTreatmentEnds.content}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				</section>

				<section className={styles.sectionTenThings}>
					<div className={styles.inner}>
						<RenderFromKeys content={language.clinics.TenFacts} />
					</div>
				</section>

				<section className={styles.sectionFaq}>
					<div className={styles.inner}>
						<FAQ faqs={language.clinics.FAQ} />
					</div>
				</section>

				<section className={styles.sectionResources}>
					<h2 className={styles.sectionResourcesTitle}>
						{language.clinics.resourcesBlock.title}
					</h2>

					{resources && (
						<div className={styles.resources}>
							{resources.map((resource, i) => (
								<Resource
									key={i}
									resource={resource}
								/>
							))}
						</div>
					)}
				</section>
				<section
					id="research"
					className={styles.section}
				>
					<div className={styles.inner}>
						<h2>{language.clinics.supportingResearch.title}</h2>
						<OrderedList
							items={language.clinics.supportingResearch.items.sort(function (
								a,
								b
							) {
								if (a.toLowerCase() < b.toLowerCase()) return -1;
								if (a.toLowerCase() > b.toLowerCase()) return 1;
								return 0;
							})}
						/>
					</div>
				</section>
			</main>
			<div className={styles.footerWrapper}>
				<Footer />
			</div>
		</Fragment>
	);
};

export default Clinics;
