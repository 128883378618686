import React, { useContext, useState } from "react";
import axios from "axios";
import { AccountContext } from "../Store/AccountStore.js";
import { PopupContext } from "../Store/PopupStore";

import { LanguageContext } from "../Store/LanguageStore";
import Paragraph from "../Components/SanatizedComponents/Paragraph.jsx";
import UnorderedList from "../Components/SanatizedComponents/UnorderedList.jsx";
import handleError from "../lib/handleError.js";

const useSubmitTask = () => {
  const [user, setuser] = useContext(AccountContext);
  const [openPopup] = useContext(PopupContext);
  const [language] = useContext(LanguageContext);

  const submitTask = async (event, task_id) => {
    try {
      await axios.post("/user/progress/submit_task", { task_id });

      if (user.progress[task_id - 1].complete == 0) {
        const content = language[`steppingstone${task_id}_finished_content`];

        if (typeof content == "string") {
          openPopup(
            <div>
              <Paragraph text={content} />
            </div>
          );
        } else if (typeof content == "object") {
          const isParagraph = /paragraph\d/g;
          const isBullets = /bullets\d/g;

          openPopup(
            <div>
              {Object.keys(content).map((key) => {
                if (isParagraph.test(key)) {
                  return <Paragraph text={content[key]} />;
                } else if (isBullets.test(key)) {
                  return <UnorderedList items={content[key]} />;
                } else {
                  throw `Key Format inccorect for 'steppingstone${task_id}_finished_content'`;
                }
              })}
            </div>
          );
        }
      }

      let _user = { ...user };
      _user.progress[task_id - 1].complete = 1;
      _user.progress[task_id - 1].active = 0;
      if (task_id < _user.progress.length) _user.progress[task_id].disabled = 0;
      setuser(_user);
    } catch (e) {
      event.stopPropagation();
      handleError(e, true);
    }
  };

  return [submitTask];
};

export default useSubmitTask;
