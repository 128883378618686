import React, { useContext } from "react";
import TextQuestion from "../../../Components/Questions/TextQuestion/TextQuestion";
import utils from "../../../utils/utils";
import usePreviousAnswers from "../../../Hooks/usePreviousAnswers";

import Paragraph from "../../../Components/SanatizedComponents/Paragraph";
import { LanguageContext } from "../../../Store/LanguageStore";
import LoadingScreen from "../../../Components/LoadingScreen/LoadingScreen";

const Activity7_main_1 = ({ attempt }) => {
  const [language] = useContext(LanguageContext);
  const task_id = 7;

  const _submitAnswer = async (question_id, answer) => {
    utils.submitAnswer(task_id, question_id, answer, attempt);
  };
  const questionCount = 2;
  const firstQuestionIndex = 0;
  const [answers, loading] = usePreviousAnswers(
    task_id,
    questionCount,
    attempt,
    null,
    null,
    firstQuestionIndex
  );

  return (
    <LoadingScreen loading={loading}>
      <div>
        <Paragraph text={language.steppingstone7_paragraph3} />
        <TextQuestion
          initialValue={answers[0]}
          submitAnswer={(answer) => _submitAnswer(1, answer)}
          question={language.steppingstone7_exercise_a}
          hint={language.steppingstone7_exercise_a_hint}
        />
        <Paragraph text={language.steppingstone7_paragraph4} />
        <TextQuestion
          initialValue={answers[1]}
          submitAnswer={(answer) => _submitAnswer(2, answer)}
          question={language.steppingstone7_exercise_b}
          hint={language.steppingstone7_exercise_b_hint}
        />
      </div>
    </LoadingScreen>
  );
};

export default Activity7_main_1;
