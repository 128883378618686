import React, {useEffect, useState, useContext} from 'react';
import axios from 'axios'
import handleError from '../lib/handleError';
import { AccountContext } from './AccountStore';

export const URLParamsContext = React.createContext();

const URLParamsStore = ({children}) => {
    let queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let _params = {};
    for(const [key, value] of urlParams){
        _params[key] = value;
    }
    const [params, setParams] = useState(_params);
    const [user] = useContext(AccountContext);
    

    useEffect(() => {
        // if params has param with key "code" ensure the user is logged out
        const logout = async () => {
            try {
                let res = await axios.get('/user/logout');
            }
            catch(e){
                handleError(e, true)
            }
        }

        if('code' in params && user.id != null){
            logout();
        }

    }, []);

    return (
        <URLParamsContext.Provider value={[params]}>
            {children}
        </URLParamsContext.Provider>
    )
}

export default URLParamsStore;
