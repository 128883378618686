import React, { useState, useEffect, useContext } from 'react';
import TextQuestion from "../../../Components/Questions/TextQuestion/TextQuestion"
import utils from "../../../utils/utils"
import LoadingScreen from '../../../Components/LoadingScreen/LoadingScreen';
import usePreviousAnswers from '../../../Hooks/usePreviousAnswers';
import Heading from '../../../Components/SanatizedComponents/Heading';
import {LanguageContext} from '../../../Store/LanguageStore'
import Paragraph from '../../../Components/SanatizedComponents/Paragraph';

const Activity5_main_1 = ({attempt}) => {
    const [language] = useContext(LanguageContext);
    const task_id = 5;

    const questionCount = 4;
    const firstQuestionIndex = 0;
    const [answers, loading] = usePreviousAnswers(task_id, questionCount, attempt, null, null, firstQuestionIndex);

    const _submitAnswer = async (question_id, answer) => {
        utils.submitAnswer(task_id, question_id, answer, attempt);
    }

    return (
        <LoadingScreen loading={loading}>
            <div>

                <Paragraph text={language.steppingstone5_paragraph3} />
                <Paragraph text={language.steppingstone5_paragraph4} />

                <Heading level="h2" text={language.steppingstone5_exercise_1} />
                <TextQuestion initialValue={answers[0]} submitAnswer={(answer) => _submitAnswer(1, answer)} question={language.steppingstone5_exercise_1a} hint={language.steppingstone5_exercise_1a_hint} height="s"/>
                <TextQuestion initialValue={answers[1]} submitAnswer={(answer) => _submitAnswer(2, answer)} question={language.steppingstone5_exercise_1b} hint={language.steppingstone5_exercise_1b_hint} height="s"/>
                <TextQuestion initialValue={answers[2]} submitAnswer={(answer) => _submitAnswer(3, answer)} question={language.steppingstone5_exercise_1c} hint={language.steppingstone5_exercise_1c_hint} height="s"/>
                <TextQuestion initialValue={answers[3]} submitAnswer={(answer) => _submitAnswer(4, answer)} question={language.steppingstone5_exercise_1d} hint={language.steppingstone5_exercise_1d_hint} height="s"/>

            </div>
        </LoadingScreen>
    );
}

export default Activity5_main_1;
