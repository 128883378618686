import React from "react";
import DOMPurify from "dompurify";
import classes from "./Quote.module.scss";

const Quote = ({ text, className }) => {
  return (
    <blockquote className={className}>
      <div className={classes.fancyQuote}>
        <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}></p>
      </div>
    </blockquote>
  );
};

export default Quote;
