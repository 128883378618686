import React from "react";

const Compass = ({ className, base, baseColor = "#ddf5f5" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      {base && <circle cx="54" cy="54" r="45" fill={baseColor} />}

      <circle
        cx="54"
        cy="53.95"
        r="36"
        fill="#80918b"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <circle
        cx="54"
        cy="53.95"
        r="27.69"
        fill="#ddf5f5"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M57.44,26.29,54,33.18l-3.44-6.89A3.85,3.85,0,0,1,54,20.72h0A3.85,3.85,0,0,1,57.44,26.29Z"
        fill="#fabb26"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M57.44,81.6,54,74.72,50.56,81.6A3.86,3.86,0,0,0,54,87.18h0A3.86,3.86,0,0,0,57.44,81.6Z"
        fill="#fabb26"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M81.66,50.5,74.77,54l6.89,3.44A3.85,3.85,0,0,0,87.23,54h0A3.85,3.85,0,0,0,81.66,50.5Z"
        fill="#fabb26"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M26.34,57.39,33.23,54,26.34,50.5A3.85,3.85,0,0,0,20.77,54h0A3.85,3.85,0,0,0,26.34,57.39Z"
        fill="#fabb26"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <polygon
        points="73.58 34.37 56.94 56.88 51.06 51.01 73.58 34.37"
        fill="#db6b5e"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <polygon
        points="56.94 56.88 34.42 73.53 51.06 51.01 56.94 56.88"
        fill="#fabb26"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <circle
        cx="54"
        cy="53.95"
        r="4.15"
        fill="#fabb26"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Compass;
