import React, { useState, useRef, useContext } from "react";
import { MdArrowDropDown } from "react-icons/md";

import classes from "./AccountDropdown.module.scss";

import { NavLink } from "react-router-dom";
import LogoutBtn from "../../buttons/LogoutBtn";

import { LanguageContext } from "../../../Store/LanguageStore";

const AccountDropdown = () => {
  const [language] = useContext(LanguageContext);

  const [open, setopen] = useState(false);
  const dropdownRef = useRef();

  const openDropdown = () => {
    setopen(true);
    window.addEventListener("mouseup", windowClick);
  };

  const windowClick = (e) => {
    if (e.target != dropdownRef.current) {
      setopen(false);
      window.removeEventListener("mouseup", windowClick);
    }
  };

  return (
    <div className={classes.wrapper}>
      <ul className={classes.items}>
        <li className={classes.topItem}>
          <NavLink
            to="/user/map"
            className={classes.link}
            activeClassName={classes.linkActive}
          >
            Account
          </NavLink>
          <button
            className={classes.toggleSubItems}
            onClick={openDropdown}
            onMouseOver={() => setopen(true)}
          >
            <MdArrowDropDown />
          </button>
          <ul
            onMouseLeave={() => setopen(false)}
            ref={dropdownRef}
            className={classes.subItems}
            style={{ display: open ? "block" : "none" }}
          >
            <li className={classes.subItem}>
              <NavLink
                to={`/user/settings`}
                className={classes.link}
                activeClassName={classes.linkActive}
              >
                {language.top_menu_settings}
              </NavLink>
            </li>
            <li className={classes.subItem}>
              <LogoutBtn className={classes.link}>
                {language.top_menu_logout}
              </LogoutBtn>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default AccountDropdown;
