import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import AllStores from "./Store/AllStores";
import Main from "./Main";
import ErrorBoundary from "./Components/ErrorBoundary";

import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#00759c",
    },
    secondary: {
      main: "#468f79",
    },
  },
});

function App() {

  return (
    <div className="App">
      <CacheBuster
        currentVersion={version}
        isEnabled={true} //If false, the library is disabled.
        isVerboseMode={true} //If true, the library writes verbose logs to console.
        metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
      >
        <ErrorBoundary>
          <ThemeProvider theme={muiTheme}>
            <Router>
              <AllStores>
                <Main/>
              </AllStores>
            </Router>
          </ThemeProvider>
        </ErrorBoundary>
      </CacheBuster>
    </div>
  );
}

export default App;
