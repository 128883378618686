import React, { Fragment } from "react";
import classes from "./PreviousAnswer.module.scss";
import DOMPurify from "dompurify";

const PreviousAnswer = ({
  children,
  title = "",
  content = false,
  no_answer_string = "no previous answer",
}) => {
  return (
    <div
      className={content ? classes.previousAnswer : classes.noPreviousAnswer}
    >
      {title && <div className={classes.title}>{title}</div>}
      {content && <div className={classes.content}>{content}</div>}
      {!content && (
        <div
          className={classes.content}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(no_answer_string),
          }}
        ></div>
      )}
    </div>
  );
};

export default PreviousAnswer;
