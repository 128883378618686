import React, { Fragment } from "react";
import RenderFromKeys from "../../Components/RenderFromKeys/RenderFromKeys";
import { useContext } from "react";
import { LanguageContext } from "../../Store/LanguageStore";
import classes from "./studyInfo.module.scss";

import IconCalendar from "../../Components/icons/Calendar";

import Footer from "./Footer/Footer";
import Paragraph from "../../Components/SanatizedComponents/Paragraph";
import Heading from "../../Components/SanatizedComponents/Heading";

const StudyInfo = () => {
  const [language] = useContext(LanguageContext);
  const strings = language.studyInfo;

  return (
    <Fragment>
      <main>
        <section className={classes.intro}>
          <div className={classes.inner}>
            <IconCalendar className={classes.introIcon} base />
            <Paragraph text={strings.paragraph1} />
            <Heading text={strings.subHeader1} />
          </div>
        </section>
        <section className={classes.info}>
          <div className={classes.inner}>
            <Paragraph text={strings.paragraph2} />
            <Paragraph text={strings.paragraph3} />
            <Paragraph text={strings.paragraph4} />
            <Paragraph text={strings.paragraph5} />
            <Heading text={strings.subHeader2} level="h2" />
            <Paragraph text={strings.paragraph6} />
          </div>
        </section>
      </main>
      <div className={classes.footerWrapper}>
        <Footer />
      </div>
    </Fragment>
  );
};

export default StudyInfo;
