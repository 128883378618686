import React, { useState, useEffect, Fragment } from 'react';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import { useContext } from 'react';
import { LanguageContext } from '../../Store/LanguageStore';
import classes from './Audio.module.scss';

import {FaPlay, FaPause, FaVolumeUp, FaVolumeMute} from 'react-icons/fa';


const AudioPlayer = ({ className, mp3 }) => {

    const [language] = useContext(LanguageContext);

    //const [audio, setaudio] = useState(undefined);
    const [loading, setloading] = useState(true);
    const [playing, setplaying] = useState(false);
    const [player, setplayer] = useState(undefined);
    const [timeout, settimeout] = useState(undefined);
    const [currentTime, setcurrentTime] = useState(0);
    const [duration, setduration] = useState(0);
    const [volume, setvolume] = useState(100);
    const [lastVolume, setlastVolume] = useState(100);

    const [_hasError, set_hasError] = useState(false);
    const [_error, set_error] = useState("");

    let _player;

    useEffect(() => {
        import(`../../assets/audio/${language.language}/${mp3}`).then(_audio=>{
            _player = new Audio(_audio.default);
            setplayer(_player);
            _player.addEventListener('loadedmetadata',getDuration(_player))
        }).catch(err => {
            console.log(err);
            set_hasError(true);
            set_error(err);
        })

        return () => {
            if(_player){
                _player.pause();
            }
        }
    }, []);

    const getDuration = (_player, depth = 0) => {
        if(depth > 5){
            console.log(_player);
            throw "Error getting audio duration";
        }


        window.setTimeout(()=>{
            if(_player.duration){
                if(!isNaN(_player.duration)){
                    setduration(_player.duration);
                    setloading(false)
                }else{
                    getDuration(_player, depth+1)
                }
            }
            else
                getDuration(_player, depth+1);
        }, 50 * depth)
    }

    const formatTime = (sec_num) => {

        console.log("formatting time of: ", sec_num)

        sec_num = Math.round(sec_num);
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);
    
        

        if (hours   < 10) {hours = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        
        if(hours != "00") 
            return hours+':'+minutes+':'+seconds;
        else 
            return minutes+':'+seconds;
        
    }

    const audioPlayerTimerStart = () => {
        let _timeout = window.setInterval(()=>{
            setcurrentTime(player.currentTime);
        },100)

        settimeout(_timeout);
    }

    const audioPlayerTimerStop = () => {
        clearInterval(timeout);
    }

    const playPauseClick = () => {
        if(playing){
            player.pause();
            audioPlayerTimerStop();
        }else{
            player.play();
            audioPlayerTimerStart();
        }
        setplaying(!playing);
    }

    const handleTimeSliderChange = (e) => {
        let time = e.target.value * duration / 1000;
        player.currentTime = time;
        setcurrentTime(time);
    }

    const handleVolumeSliderChange = (e) => {
        player.volume = e.target.value / 100;
        setvolume(e.target.value);
    }

    const muteClick = () => {
        if(volume > 0){
            setlastVolume(volume);
            setvolume(0);
            player.volume = 0
        }else{
            setvolume(lastVolume);
            player.volume = lastVolume / 100;
        }
    }

    if(_hasError){
        return <h1>{_error.toString()}</h1>
    }
    else if(loading){
        return <LoadingScreen loading={loading}/>
    }else{
        return (
            <div className={classes.container}>
                <div className={classes.main}>
                    <div className={classes.leftContainer}>
                        {playing ? <FaPause className={classes.button} onClick={playPauseClick}/> : <FaPlay className={classes.button} onClick={playPauseClick}/>}
                        <div className={classes.timerContainer}>
                            <div className={classes.timer}>
                                {formatTime(currentTime)} / {formatTime(duration)}
                            </div>
                        </div>
                    </div>
                    <div className={classes.volumeControls}>
                        {
                            volume == 0 ? <FaVolumeMute className={classes.button} onClick={muteClick}/> : <FaVolumeUp className={classes.button} onClick={muteClick}/>
                        }
                        <input className={classes.volumeSlider} type="range" id="volumeSeek" value={volume} max="100" onChange={handleVolumeSliderChange}/>        
                    </div>
                </div>
                <div className={classes.seekSliderWrapper} >
                    <input 
                        className={classes.seekSlider} 
                        type="range" 
                        id="timeSeek" 
                        value={(Math.round((currentTime*10000/duration))/10)} 
                        max="1000" 
                        onChange={handleTimeSliderChange} 
                    />
                </div>
            </div>
        )

        // return (
        //     <audio className={className} controls>
        //         <source src={audiofile.default} type="audio/mpeg" />
        //             Your browser does not support the audio element.
        //     </audio>
        // );
    }

    
}

export default AudioPlayer;
