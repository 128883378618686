import React from "react";
import DOMPurify from "dompurify";
import useParseStringLinks from "../../Hooks/useParseStringLinks";

const UnorderedList = ({ items, className }) => {
  const { parseSupportLinks } = useParseStringLinks();

  return (
    <ul className={className}>
      {items.map((item, index) => {
        return <li key={index}>{parseSupportLinks(item, true)}</li>;
      })}
    </ul>
  );
};

export default UnorderedList;
