import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import classes from "./FooterNav.module.scss";
import { LanguageContext } from "../../../Store/LanguageStore";


const FooterNav = () => {

  const [language] = useContext(LanguageContext);

  return (
    <nav>
      <ul className={classes.links}>
        <li>
          <NavLink activeClassName={classes.current} exact to="/">
            {language.home_link}
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName={classes.current} exact to="/about">
            {language.about_link}
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName={classes.current} exact to="/not-ready">
            {language.not_ready_link}
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName={classes.current} exact to="/support">
            {language.support_link}
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName={classes.current} exact to="/terms-of-use">
            {language.terms_of_use_link}
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default FooterNav;
