import React from "react";
import styles from "./FAQ.module.scss";

const FAQ = ({ faqs }) => {
  return (
    <div className={styles.wrapper}>
      {faqs.map((faq, i) => (
        <div key={i}>
          {faq.title && <h2 dangerouslySetInnerHTML={{ __html: faq.title }} />}
          {faq.description && (
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: faq.description }}
            />
          )}
          <div className={styles.questions}>
            {faq.questions?.length > 0 &&
              faq.questions.map((question, i) => (
                <details key={i} className={styles.questionWrapper}>
                  <summary
                    className={styles.question}
                    dangerouslySetInnerHTML={{ __html: question.question }}
                  />
                  <div
                    className={styles.answer}
                    dangerouslySetInnerHTML={{ __html: question.answer }}
                  />
                </details>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQ;
