import React, { useState, useContext } from 'react';
import SliderQuestion from "../Questions/SliderQuestion/SliderQuestion"
import axios from 'axios';

import Heading from '../SanatizedComponents/Heading'
import classes from "./WHO5.module.scss"
import { useEffect } from 'react';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import Paragraph from '../SanatizedComponents/Paragraph';
import { LanguageContext } from "../../Store/LanguageStore";
import handleError from '../../lib/handleError';


const WHO5 = ({task_id, attempt = 1, allowContinue, restrictContinue}) => {
    const [language] = useContext(LanguageContext);

    const who_answers = [
        {
            value: 0,
            label: language.WHO_5_response1,
        },
        {
            value: 1,
            label: language.WHO_5_response2,
        },
        {
            value: 2,
            label: language.WHO_5_response3,
        },
        {
            value: 3,
            label: language.WHO_5_response4,
        },
        {
            value: 4,
            label: language.WHO_5_response5,
        },
        {
            value: 5,
            label: language.WHO_5_response6
        },
    ];
    
    const satisfaction_answers = [
        {
            label: "1 - " + language.satisfactionwithlifescale_answer1,
            value: 0
        },
        {
            label: "2",
            value: 1
        },
        {
            label: "3",
            value: 2
        },
        {
            label: "4",
            value: 3
        },
        {
            label: "5",
            value: 4
        },
        {
            label: "6",
            value: 5
        },
        {
            label: "7",
            value: 6
        },
        {
            label: "8",
            value: 7
        },
        {
            label: "9",
            value: 8
        },
        {
            label: "10 - " + language.satisfactionwithlifescale_answer5,
            value: 9
        },
    ];
    
    const happiness_answers = [
        {
            label: "1 - " + language.happinessscale_answer1,
            value: 0
        },
        {
            label: "2",
            value: 1
        },
        {
            label: "3",
            value: 2
        },
        {
            label: "4",
            value: 3
        },
        {
            label: "5",
            value: 4
        },
        {
            label: "6",
            value: 5
        },
        {
            label: "7",
            value: 6
        },
        {
            label: "8",
            value: 7
        },
        {
            label: "9",
            value: 8
        },
        {
            label: "10 - " + language.happinessscale_answer5,
            value: 9
        }
    ];
    
    const who_questions = [
        {
            question: language.WHO_5_item1,
            answers: who_answers,
            scale_size: 5
        },
        {
            question: language.WHO_5_item2,
            answers: who_answers,
            scale_size: 5
        },
        {
            question: language.WHO_5_item3,
            answers: who_answers,
            scale_size: 5
        },
        {
            question: language.WHO_5_item4,
            answers: who_answers,
            scale_size: 5
        },
        {
            question: language.WHO_5_item5,
            answers: who_answers,
            scale_size: 5
        }
        
    ]
    
    const howDoYouSeeYourLife_questions = [
        {
            question: language.satisfactionwithlifescale,
            answers: satisfaction_answers,
            scale_size: 9
        },
        {
            question: language.happinessscale,
            answers: happiness_answers,
            scale_size: 9
        }
    ]
    
    const questionCount = 7;
    const [answers, setanswers] = useState(Array(questionCount));
    
    const setAnswer = (index, answer) => {
        let _answers = [...answers];
        _answers[index] = answer;
        if(!_answers.includes(undefined))
        allowContinue()
        setanswers(_answers);
    }
    
    
    let source;
    const [loading, setloading] = useState(true);
    
    useEffect(() => {
        restrictContinue();
        source = axios.CancelToken.source();
        const getAnswersFromCurrentAttempt = async () => {
            
            try{
                let _answers = Array(questionCount);
                let promises = [];
                for(let i = 0; i < questionCount; i++){
                    promises.push(axios.post('/user/who5/answer/get',{task_id, question_id: i+1, attempt},{cancelToken: source.token}));
                }
                
                let results = await Promise.all(promises);
                for(let i = 0; i < results.length; i++){
                    if(results[i].data.found){
                        _answers[i]=results[i].data.answer.answer;
                    }
                }

                if(!_answers.includes(undefined)){
                    allowContinue()
                }

                setanswers(_answers);
                setloading(false);
                
            }catch(e){
                if(!axios.isCancel(e)){
                    handleError(e);
                    setloading(false);
                }
            }
        }

        getAnswersFromCurrentAttempt();

        return () => {
            source.cancel();
        };
    }, []);
    const submitWho5 = async (answer,question_id) => {
        try {
            const data = {
                task_id,
                question_id,
                answer,
                attempt
            }
            setAnswer(question_id-1,answer);
            await axios.post('/user/who5/answer/set', data);
            
        }
        catch (e) {
            handleError(e, true);
        }
    }

    return (
        <div className={classes.who5}>
            <Heading text={language.who_5_title} />
            <Paragraph text={language.WHO_5_instructions} />
            <LoadingScreen loading={loading}>
                <div className={classes.wrapper}>
                    {who_questions.map((question, index) => {
                        return <SliderQuestion
                            initialValue={answers[index]}
                            answers={question.answers}
                            question={question.question}
                            submitAnswer={(answer) => submitWho5(answer, index+1)}
                            key={`who-${index}`}
                            saveDelay={0}
                            max={question.scale_size} 
                            marks={question.answers}    
                        />
                            
                    })}
                </div>
                <Heading text={language.how_do_you_see_your_life_title}/>
                <div className={classes.wrapper}>
                    {howDoYouSeeYourLife_questions.map((question, index) => {
                        return <SliderQuestion
                            initialValue={answers[index+5]}
                            answers={question.answers}
                            question={question.question}
                            submitAnswer={(answer) => submitWho5(answer, index+6)}
                            key={`${index+5}`}
                            index={`${index+5}`}
                            saveDelay={0}
                            max={question.scale_size} 
                            marks={question.answers}  
                        />
                    })}
                </div>
            </LoadingScreen>
        </div>
    );
}

export default WHO5;
