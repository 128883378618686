import React from "react";

const Headphones = ({ className, base, baseColor = "#ddf5f5" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      {base && <circle cx="54" cy="54" r="45" fill={baseColor} />}

      <polyline
        points="75 80.11 75 77.15 75 75"
        fill="none"
        stroke="#231f20"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M75,85.81c0-2.85,4,0,4-2.85s-4,0-4-2.85"
        fill="none"
        stroke="#231f20"
        strokeLinejoin="round"
        strokeWidth="2"
      />

      <path
        d="M75,85.81c0,2.84,4,0,4,2.84s-4,0-4,2.85"
        fill="none"
        stroke="#231f20"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M75,91.5c0,2.85,4,0,4,2.85s-4,0-4,2.84"
        fill="none"
        stroke="#231f20"
        strokeLinejoin="round"
        strokeWidth="2"
      />

      <polyline
        points="75 102.89 75 105.85 75 108"
        fill="none"
        stroke="#231f20"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M75,97.19c0,2.85,4,0,4,2.85s-4,0-4,2.85"
        fill="none"
        stroke="#231f20"
        strokeLinejoin="round"
        strokeWidth="2"
      />

      <path
        d="M54,2C9,2,18.8,64.08,19.61,68.05,20.67,73.27,27,77,27,77s10.84-2.64,10.34-5.46L34.11,53.51A5.23,5.23,0,0,0,28,49.31L24,50C22.68,39,27,7.18,54,7.18S85.32,39,84,50l-4-.69a5.23,5.23,0,0,0-6.07,4.2L70.66,71.56C70.16,74.38,81,77,81,77s6.33-3.75,7.39-9C89.2,64.08,99,2,54,2Z"
        fill="#fabb26"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M28.26,77.92l9.34-.37a1.3,1.3,0,0,0,1.32-1.33L37.09,48.54a1.4,1.4,0,0,0-1.5-1.21l-9.34.37A1.3,1.3,0,0,0,24.93,49l1.83,27.68A1.4,1.4,0,0,0,28.26,77.92Zm52.9-1.21L83,49a1.32,1.32,0,0,0-1.33-1.33l-9.34-.37a1.38,1.38,0,0,0-1.49,1.21L69,76.22a1.31,1.31,0,0,0,1.33,1.33l9.34.37A1.38,1.38,0,0,0,81.16,76.71Z"
        fill="#231f20"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Headphones;
