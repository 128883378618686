import React, { useContext } from "react";
import { Link } from "react-router-dom";

import Activity8_intro from "./Activity8_intro";
import Activity8_main from "./Activity8_main";


import ActivityAssessment from "../../../Pages/ActivityAssessment/ActivityAssessment";
import ActivityWrapper from "../ActivityWrapper";
import classes from "../Activity.module.scss";
import IconCampfire from "../../../Components/icons/Campfire";

import Heading from "../../../Components/SanatizedComponents/Heading";
import Paragraph from "../../../Components/SanatizedComponents/Paragraph";
import { LanguageContext } from "../../../Store/LanguageStore";
import WHO5 from "../../../Components/WHO5/WHO5";
import WHO5Display from "../../../Components/WHO5/WHO5Display";
import RenderFromKeys from "../../../Components/RenderFromKeys/RenderFromKeys";
import Activity8_summary from "./Activity8_summary";

const Activity8 = (props) => {
  const [language] = useContext(LanguageContext);
  const task_id = 8;
  const { attempt } = props;
  const pages = [
    {
      link: "/who",
      component: () => <WHO5 task_id={task_id} attempt={attempt} />,
    },
    {
      link: "/who/display",
      component: () => <WHO5Display task_id={task_id} attempt={attempt} />,
    },
    { link: "/introduction", component: () => <Activity8_intro /> },
    {
      link: "/activity",
      component: () => <Activity8_main task_id={task_id} attempt={attempt} />,
    },
    {
      link: "/summary",
      component: () => <Activity8_summary />,
    },
    {
      link: "/activityassessment",
      component: () => (
        <ActivityAssessment task_id={task_id} attempt={attempt} />
      ),
    },
    
  ];

  return (
    <div>
      <div className={classes.headerWrapper}>
        <header className={classes.header}>
          <IconCampfire base className={classes.icon} />
          <div>
            <Heading text={language.steppingstone8_title} />
            <Paragraph text={language.steppingstone8_aim} />
          </div>
        </header>
      </div>
      <nav className={classes.breadcrumbs}>
        <Link to="/user/map">{language.map_menu}</Link>
        {" > "}
        <span dangerouslySetInnerHTML={{__html: language.steppingstone8_title}}></span>
      </nav>
      <ActivityWrapper {...props} pages={pages} task_id={task_id} />
    </div>
  );
};

export default Activity8;
