import React, { Fragment, useState, useEffect, useContext } from 'react';
import { AccountContext } from '../Store/AccountStore';
import usageActions from '../lib/usageActions.json';
import useUsageMonitoring from '../Hooks/useUsageMonitoring';

const keepAliveTime = 1000 * 60;

const SessionUsageMonitor = () => {
    
    const [account] = useContext(AccountContext);
    const [beginUsageMonitoring, endUsageMonitoring,clearTimeouts] = useUsageMonitoring(usageActions.WEB_APP);

  

    useEffect(()=>{
        if(account.id){
            beginUsageMonitoring();
        }

        return () => {
            //endUsageMonitoring(); // this should be called before the user logs out
            clearTimeouts();
        }
    },[account.id])

    return <Fragment/>
}

export default SessionUsageMonitor;
