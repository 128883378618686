import React, { useState, useContext } from "react";
import classes from "./StatementCard.module.scss";
import Dropdown from "../../../Components/Forms/Dropdown/DropdownInput";
import CloseButton from "../../../Components/buttons/CloseButton/CloseButton";
import { LanguageContext } from "../../../Store/LanguageStore";
import useValueStatmentStrings from "../../../Hooks/useValueStatmentStrings";

// todo: this component is at risk of js injection

const StatementCard = ({ answer, index, area, onDelete, updateCard, isDragging }) => {
  const [language] = useContext(LanguageContext);

  const [editorOpen, seteditorOpen] = useState(false);

  const lifeAreas = useValueStatmentStrings();

  return (
    <div className={`${classes.card} ${lifeAreas[area].className} ${isDragging ? classes.isDragging : ""}`}>
      <div className={classes.inner}>
        <div className={classes.content}>
          <div>
            <span>{index}: </span>
            {lifeAreas[area].area}
          </div>
          <h2 className={classes.valueStatement}>{answer}</h2>
        </div>
      </div>

      <div className={classes.actions}>
        <div>
          <button
            className={classes.actionButton}
            onClick={() => {
              seteditorOpen(!editorOpen);
            }}
          >
            {language.edit}
          </button>
          <button className={classes.actionDelete} onClick={onDelete}>
            {language.delete}
          </button>
        </div>
      </div>

      {editorOpen && (
        <div className={classes.modalWrapper}>
          <div className={classes.modal}>
            <div className={classes.modalHeader}>
              <h2>{language.edit_your_value}</h2>
              <CloseButton
                className={classes.modalClose}
                onClick={() => {
                  seteditorOpen(!editorOpen);
                }}
              />
            </div>
            <div className={classes.modalContent}>
              <div className={classes.fieldWrapper}>
                <Dropdown
                  value={area}
                  options={lifeAreas}
                  label={language.life_area}
                  onChange={(value) => updateCard(answer, value)}
                />
              </div>
              <div className={classes.fieldWrapper}>
              <label>{language.your_value}</label>
                <input
                  type="text"
                  className={classes.input}
                  value={answer}
                  onChange={(e) => updateCard(e.target.value, area)}
                />
              </div>
              <div className={classes.fieldWrapper}>
                <button
                  className={classes.buttonSubmit}
                  onClick={() => {
                    seteditorOpen(!editorOpen);
                  }}
                >
                  {language.close}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StatementCard;
