import React from 'react';
import { useContext } from 'react';
import { LanguageContext } from '../../../Store/LanguageStore';
import RenderFromKeys from '../../../Components/RenderFromKeys/RenderFromKeys';

const Activity8_summary = () => {

    const [language] = useContext(LanguageContext)

    const _content = {
        paragraph1: language.steppingstone8_paragraph5,
        paragraph2: language.steppingstone8_paragraph6,
    }

    return (
        <div>
            <RenderFromKeys content={_content} />
        </div>
    );
}

export default Activity8_summary;
