import React, { useContext, useState, Fragment } from "react";
import axios from "axios";

import { AccountContext } from "../../Store/AccountStore";
import { LanguageContext } from "../../Store/LanguageStore";
import { RedirectContext } from "../../Store/RedirectTo";
import { Redirect } from "react-router-dom";
import handleError from "../../lib/handleError";

const LogoutBtn = ({ children, className }) => {
  const [, getAccountInfo] = useContext(AccountContext);
  const [redirectTo] = useContext(RedirectContext);
  const [language] = useContext(LanguageContext);

  const logout = async () => {
    try {
      let res = await axios.get("/user/logout");
      await getAccountInfo();
      redirectTo("/");
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <button onClick={logout} className={className}>
      {children ? children : <Fragment>{language.top_menu_logout}</Fragment>}
    </button>
  );
};

export default LogoutBtn;
