import React from "react";

const Mindful = ({ className, color = "#000", weight = 3 }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      <rect
        x="9.34"
        y="18.91"
        width="49.85"
        height="69.6"
        transform="translate(-7.25 5.39) rotate(-8.13)"
        strokeWidth={weight}
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <line
        x1="10.36"
        y1="33.26"
        x2="2.14"
        y2="34.43"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <line
        x1="11.48"
        y1="41.05"
        x2="3.25"
        y2="42.23"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <line
        x1="4.37"
        y1="50.02"
        x2="12.59"
        y2="48.85"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <line
        x1="13.71"
        y1="56.64"
        x2="5.48"
        y2="57.82"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <line
        x1="14.82"
        y1="64.44"
        x2="6.59"
        y2="65.61"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <line
        x1="7.71"
        y1="73.41"
        x2="15.93"
        y2="72.24"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <line
        x1="17.05"
        y1="80.03"
        x2="8.82"
        y2="81.21"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <circle
        cx="83.77"
        cy="50.78"
        r="20.77"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <circle
        cx="83.77"
        cy="50.78"
        r="15.5"
        stroke={color}
        fill={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <path
        d="M89.08,71.29c2.65,5.48,4.8,9.93,8.54,8.12s1.59-6.25-1.06-11.74"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
    </svg>
  );
};

export default Mindful;
