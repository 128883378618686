import { useEffect, useState } from "react";
import axios from "axios";
import handleError from "../lib/handleError";

const usePreviousAnswers = (
  task_id,
  questionCount,
  attempt,
  allowContinue,
  restrictContinue,
  firstQuestionIndex = 0,
) => {
  let source;
  const [answers, setanswers] = useState(Array(questionCount));
  const [loading, setloading] = useState(true);

  useEffect(() => {
    if (restrictContinue) restrictContinue();

    source = axios.CancelToken.source();
    const getAnswersFromCurrentAttempt = async () => {
      try {
        let _answers = Array(questionCount);
        let promises = [];
        for (let i = firstQuestionIndex; i < questionCount + firstQuestionIndex; i++) {
          promises.push(
            axios.post("/user/answer/get", {
              task_id,
              question_id: i + 1,
              attempt,
            },{cancelToken: source.token})
          );
        }

        let results = await Promise.all(promises);
        for (let i = 0; i < results.length; i++) {
          if (results[i].data.found) {
            _answers[i] = results[i].data.answer;
          }
        }

        if (!_answers.includes(undefined) && allowContinue !== undefined && allowContinue !== null)
          allowContinue();

        console.log(_answers)

        setanswers(_answers);
        setloading(false);
      } catch (e) {
        if(!axios.isCancel(e)){
          handleError(e)
          setloading(false);
        }

      }
    };

    getAnswersFromCurrentAttempt();

    return () => {
      source.cancel();
    };
  }, []);

  return [answers, loading, setanswers];
};

export default usePreviousAnswers;
