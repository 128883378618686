import React from "react";

const RightTurn = ({ className, base, baseColor = "#ddf5f5" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      {base && <circle cx="54" cy="54" r="45" fill={baseColor} />}

      <rect
        x="49.5"
        y="2.5"
        width="9"
        height="103"
        strokeWidth="2"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#404a45"
      />
      <circle
        cx="54"
        cy="45"
        r="36"
        fill="#faf1ee"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M54,14A31,31,0,1,0,85,45,31,31,0,0,0,54,14Z"
        fill="#00759c"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <polygon
        points="66.5 27 54 27 61.99 38.5 29 38.5 29 51.5 61.99 51.5 54 63 66.5 63 79 45 66.5 27"
        fill="#faf1ee"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default RightTurn;
