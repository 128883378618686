import React, { useEffect, useState, useContext } from "react";
import TextQuestion from "../../../Components/Questions/TextQuestion/TextQuestion";
import SliderQuestion from "../../../Components/Questions/SliderQuestion/SliderQuestion";
import axios from "axios";
import utils from "../../../utils/utils";
import usePreviousAnswers from "../../../Hooks/usePreviousAnswers";
import LoadingScreen from "../../../Components/LoadingScreen/LoadingScreen";

import Paragraph from "../../../Components/SanatizedComponents/Paragraph";
import { LanguageContext } from "../../../Store/LanguageStore";
import PreviousAnswer from "../../../Components/PreviousAnswer/PreviousAnswer";
import useActivityAssessmentAnswers from "../../../Hooks/useActivityAssessmentAnswers";
import handleError from "../../../lib/handleError";

const Activity10_main = ({ task_id, attempt }) => {
  const [language] = useContext(LanguageContext);
  const activity_answers = useActivityAssessmentAnswers();

  const questionCount = 6;
  const startIndex = 0;

  const [pastAnswers, setpastAnswers] = useState([
    { question_id: 1, area: "emotions", answer: "" },
    { question_id: 2, area: "bodilySensations", answer: "" },
  ]);

  const [answers, loading] = usePreviousAnswers(
    task_id,
    questionCount,
    attempt,
    null,
    null,
    startIndex
  );
  const [loadingPastAnswers, setloadingPastAnswers] = useState(true);

  let source;
  useEffect(() => {
    source = axios.CancelToken.source();

    const getPastAnswers = async () => {
      try {
        let _pastAnswers = [...pastAnswers];
        for (let i = 0; i < _pastAnswers.length; i++) {
          const answer_object = pastAnswers[i];
          try {
            let answer = await axios.post(
              "/user/answer/get",
              {
                task_id: 1,
                question_id: answer_object.question_id,
              },
              { cancelToken: source.token }
            );

            if (answer.data.found) {
              _pastAnswers[i].answer = answer.data.answer;
            } else {
              console.error("answer for " + answer_object.area + " not found");
            }
          } catch (e) {
            if (!axios.isCancel(e)) {
              handleError(e);
            }
          }
        }
        setpastAnswers(_pastAnswers);
        setloadingPastAnswers(false);
      } catch (e) {
        if (!axios.isCancel(e)) {
          handleError(e);
          setloadingPastAnswers(false);
        }
      }
    };
    getPastAnswers();

    return () => {
      source.cancel();
    };
  }, []);

  const _submitAnswer = async (question_id, answer) => {
    utils.submitAnswer(task_id, question_id, answer, attempt);
  };

  return (
    <LoadingScreen loading={loading || loadingPastAnswers}>
      <div>
        <Paragraph text={language.steppingstone10_paragraph2} />
        <PreviousAnswer
          content={pastAnswers[0].answer}
          no_answer_string={
            language.steppingstone10_paragraph2_NoPreviousAnswerEmotions
          }
        />
        <PreviousAnswer
          content={pastAnswers[1].answer}
          no_answer_string={
            language.steppingstone10_paragraph2_NoPreviousAnswerBodilySensations
          }
        />
        <TextQuestion
          initialValue={answers[0]}
          submitAnswer={(answer) => _submitAnswer(1, answer)}
          question={language.steppingstone10_question1}
          hint={language.steppingstone10_question1_hint}
        />
        <TextQuestion
          initialValue={answers[1]}
          submitAnswer={(answer) => _submitAnswer(2, answer)}
          question={language.steppingstone10_question2}
          hint={language.steppingstone10_question2_hint}
        />

        <Paragraph text={language.steppingstone10_paragraph3} />

        <SliderQuestion
          initialValue={answers[2]}
          question={
            language.steppingstone10_valueStatement1 +
            "<br>" +
            language.steppingstone10_valueStatementEnd
          }
          min={1}
          max={5}
          answers={activity_answers}
          marks={activity_answers}
          submitAnswer={(answer) => _submitAnswer(3, answer)}
        />
        <SliderQuestion
          initialValue={answers[3]}
          question={
            language.steppingstone10_valueStatement2 +
            "<br>" +
            language.steppingstone10_valueStatementEnd
          }
          min={1}
          max={5}
          answers={activity_answers}
          marks={activity_answers}
          submitAnswer={(answer) => _submitAnswer(4, answer)}
        />
        <SliderQuestion
          initialValue={answers[4]}
          question={
            language.steppingstone10_valueStatement3 +
            "<br>" +
            language.steppingstone10_valueStatementEnd
          }
          min={1}
          max={5}
          answers={activity_answers}
          marks={activity_answers}
          submitAnswer={(answer) => _submitAnswer(5, answer)}
        />

        <TextQuestion
          initialValue={answers[5]}
          submitAnswer={(answer) => _submitAnswer(6, answer)}
          question={language.steppingstone10_question3}
          hint={language.steppingstone10_question3_hint}
        />
      </div>
    </LoadingScreen>
  );
};

export default Activity10_main;
