import React, { useState, useEffect, useContext } from 'react';
import SliderQuestion from '../../Components/Questions/SliderQuestion/SliderQuestion'
import EmojiPicker from './EmojiPicker';
import axios from 'axios';
import Heading from '../../Components/SanatizedComponents/Heading';

import classes from './ActivityAssessment.module.scss';
import {LanguageContext} from '../../Store/LanguageStore'
import LoadingScreen from '../../Components/LoadingScreen/LoadingScreen';
import handleError from '../../lib/handleError';


const ActivityAssessment = ({ task_id, attempt, allowContinue, restrictContinue }) => {
    const [language] = useContext(LanguageContext);
    const activity_answers = [
        {
            label: language.steppingstoneevaluation_answer1,
            value: 1,
        },
        {
            label: language.steppingstoneevaluation_answer2,
            value: 2,
        },
        {
            label: language.steppingstoneevaluation_answer3,
            value: 3,
        },
        {
            label: language.steppingstoneevaluation_answer4,
            value: 4,
        },
        {
            label: language.steppingstoneevaluation_answer5,
            value: 5,
        },
    ]
    let source;
    const [how_useful, set_how_useful] = useState(undefined);
    const [how_challenging, set_how_challenging] = useState(undefined);
    const [emotions, set_emotions] = useState([]);
    const [loading, setloading] = useState(true);


    useEffect(()=>{
        restrictContinue();

        source = axios.CancelToken.source();
        const getAnswersFromCurrentAttempt = async () => {
            
            try{
                let result = await axios.post('/user/activityassessment/get',{task_id, attempt},{cancelToken: source.token});

                if(result.data.found){
                    const {how_useful, how_challenging, emotions} = result.data.answer;
    
                    if(how_useful && how_challenging){
                        allowContinue();
                    }
    
    
                    set_how_useful(how_useful);
                    set_how_challenging(how_challenging);
                    set_emotions(emotions);
                }

                setloading(false);
                
            }catch(e){
               if(!axios.isCancel(e)){
                    handleError(e)
                    setloading(false);
               }
            }
        }

        getAnswersFromCurrentAttempt();

        return () => {
            source.cancel();
        };
    },[])

    

    const submitAssessment = async (_how_useful, _how_challenging, _emotions) => {
        
        if(_how_useful && _how_challenging){
            allowContinue();
        }
        
        set_how_useful(_how_useful)
        set_how_challenging(_how_challenging)
        set_emotions(_emotions)

        if(!_how_challenging || !_how_useful || !_emotions){
            return;
        }

        try {
            const data = {
                task_id,
                how_useful: _how_useful,
                how_challenging: _how_challenging,
                emotions: _emotions,
                attempt
            }
            
            

            await axios.post('/user/activityassessment/submit', data);
        }
        catch (e) {
            console.log(e);
            if (e.response) {
                // Request made and server responded
                if(typeof e.response.data === "object"){
            alert(e.response.data.message);
          }else{
              alert(e.response.data);
          }
                console.log(e.response.data);
                console.log(e.response.status);
                console.log(e.response.headers);
            } else if (e.request) {
                // The request was made but no response was received
alert("no response recieved")
                console.log(e.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', e.message);
                if(typeof e.message === "object"){
    alert(e.message.message);
}else{
    alert(e.message);
}
            }
        }
    }

    return (
        <LoadingScreen loading={loading}>
            <div className={classes.wrapper}>
                <Heading level="h2" text={language.steppingstoneevaluation} />
                <div className={classes.slidersWrapper}>
                    <SliderQuestion
                        question={language.usefulsteppingstone}
                        min={1}
                        max={5}
                        initialValue={how_useful}
                        answers={activity_answers}
                        submitAnswer={(answer) => submitAssessment(answer, how_challenging, emotions)}
                        saveDelay={0}
                        marks={activity_answers}
                        key={"slider1"}
                    />
                    
                    <SliderQuestion
                        question={language.challengingsteppingstone}
                        min={1}
                        max={5}
                        initialValue={how_challenging}
                        answers={activity_answers}
                        submitAnswer={(answer) => submitAssessment(how_useful, answer, emotions)}
                        saveDelay={0}
                        marks={activity_answers}
                        key={"slider2"}
                    />
                </div>

                <EmojiPicker
                    submitAnswer={(answer) => submitAssessment(how_useful, how_challenging, answer)}
                    selected={emotions}
                />

                
            </div>
        </LoadingScreen>
    );
}

export default ActivityAssessment;
