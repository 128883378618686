import React, { useContext, useState, useRef } from 'react';
import { LanguageContext } from '../../../../Store/LanguageStore';
import TextField from '../../../../Components/Forms/TextField';
import classes from './changePassword.module.scss'
import Axios from 'axios';
import handleError from '../../../../lib/handleError';

const ChangePassword = () => {

    const [language] = useContext(LanguageContext)

    const [message, setmessage] = useState(undefined);
    const [isError, setisError] = useState(false);

    const passRef = useRef();
    const newPassRef = useRef();
    const repNewPassRef = useRef();


    const [passwordHint, setpasswordHint] = useState("");
    const [passwordValid, setpasswordValid] = useState(false);
    const passwordChange = (messageOnEmpty = false) => {
        let password = newPassRef.current.value;
        setpasswordValid(false);
        let _hint = "";

        if(password.length == 0){
            if(messageOnEmpty){
                setpasswordHint({
                isError: true,
                hint: language.please_complete_this_field
                });
            }else{
                setpasswordHint(_hint);
            }
            return;
        }
        
        let lowercase = /[a-z]+/.test(password);
        let uppercase = /[A-Z]+/.test(password);
        let number = /\d+/.test(password);
        let length = password.length > 5;
        
        if(!lowercase || !uppercase || !number || !length){
        _hint += language.your_password_must;
        if(!lowercase)
            _hint += "\n" + language.include_a_lowercase_letter;
        if(!uppercase)
            _hint += "\n" + language.include_a_uppercase_letter;
        if(!number)
            _hint += "\n" + language.include_a_number;
        if(!length)
            _hint += "\n" + language.be_at_least_6_characters;
        
            _hint = {
            isError: true,
            hint: _hint
            }
        }else if(password.length != 0){
        _hint = {
            isError: false,
            hint: language.password_ok
        }
        setpasswordValid(true);
        }

        setpasswordHint(_hint);
    }

    const [passwordRepHint, setpasswordRepHint] = useState("");
    const [repPasswordValid, setrepPasswordValid] = useState(false);
    const passwordRepChange = (messageOnEmpty = false) => {
        let password = newPassRef.current.value;
        let passwordRep = repNewPassRef.current.value;

        setrepPasswordValid(false);

        if(passwordRep.length == 0){
        if(messageOnEmpty){
            setpasswordRepHint({
            isError: true,
            hint: language.please_complete_this_field
            });
        }else{
            setpasswordRepHint("");
        }
        }else if(passwordRep == password){
        setpasswordRepHint({
            isError: false,
            hint: language.passwords_match
        });
        setrepPasswordValid(true);
        }else{
        setpasswordRepHint({
            isError: true,
            hint: language.passwords_domt_match
        });
        }
    }

    const save = async () => {

        // check if their new password is valid
        const password = passRef.current.value;
        const new_password = newPassRef.current.value;

        if(passwordValid && repPasswordValid){
            try{
                setisError(false);
                setmessage(<React.Fragment>{language.processing}</React.Fragment>)
                await Axios.post('/user/password/change',{password, new_password});
                setmessage(<React.Fragment>{language.success}</React.Fragment>)
                window.setTimeout(()=>setmessage(null),3000);
                passRef.current.value = "";
                newPassRef.current.value = "";
                repNewPassRef.current.value = "";
            }catch(e){
                setisError(true);
                switch(e.response.data){
                    case "incorrect password":
                        setmessage(<React.Fragment>{language.incorrect_password}</React.Fragment>)
                        break;
                    default:
                        handleError(e);
                        setmessage(<React.Fragment>{language.anErrorOccured}</React.Fragment>)
                }
            }
        }
    }

    return (
        <div>
            <TextField
              label={language.password}
              ref={passRef}
              type="password"
            />
            <TextField
              label={language.new_password}
              ref={newPassRef}
              hint={passwordHint}
              onChange={passwordChange}
              type="password"
            />
            <TextField
              label={language.repeat_new_password}
              ref={repNewPassRef}
              hint={passwordRepHint}
              onChange={passwordRepChange}
              type="password"
            />
            <button className={classes.buttonSubmit} onClick={save}>
                {language.save}
            </button>
            {
                message && (
                    <div className={`${classes.message} ${isError ? classes.isError : classes.isSuccess}`}>
                        {message}
                    </div>
                )
            }
        </div>
    );
}

export default ChangePassword;
