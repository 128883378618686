import React, {useContext} from 'react';
import { LanguageContext } from '../Store/LanguageStore';
import classes from './useResendConfirmationEmail.module.scss'
import axios from 'axios';
import { Link } from 'react-router-dom';
import handleError from '../lib/handleError';

const useResendConfirmationEmail = (setmessage, setisError) => {

    const [language] = useContext(LanguageContext);

    const resendConfirmationEmail = async (_email) => {
        try{
          setisError(false);
          setmessage(<React.Fragment>{language.processing}</React.Fragment>)
          await axios.post('/user/resend/confirmationEmail',{email: _email, language: language.language});
          setmessage(<React.Fragment>{language.confirmationEmailSent}</React.Fragment>)
        }catch(e){
            handleResendConfirmationEmailError(e.response.data,_email);
        }
      }
    
    const handleResendConfirmationEmailError = (error, email) => {
        setisError(true);
            switch(error){
                case "you haven't registered":
                    setmessage(<React.Fragment>{language.yourEmailIsRegisteredButUnconfirmed}<br/>{language.cantSeeTheEmail}<span className={classes.link} onClick={()=>resendConfirmationEmail(email)}>{language.resendHere}</span> </React.Fragment>)
                    break;
                case "already confirmed email":
                    setmessage(<React.Fragment>{language.emailAlreadyRegistered}<Link to={`/login`}>{language.Log_in_here}</Link></React.Fragment>)
                    break;
                default:
                    handleError(error);
                    setmessage(<React.Fragment>{language.anErrorOccured}</React.Fragment>)
            }
    }
    
    return [resendConfirmationEmail];
}

export default useResendConfirmationEmail;
