import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { LanguageContext } from "../../Store/LanguageStore.js";

import styles from "./patientsNav.module.scss";

const PatientsNav = () => {
  const [language] = useContext(LanguageContext);

  return (
    <nav className={styles.nav}>
      <NavLink
        exact
        className={styles.link}
        activeClassName={styles.active}
        to="/patients"
      >
        {language.patientsNav.home}
      </NavLink>
      <NavLink
        exact
        className={styles.link}
        activeClassName={styles.active}
        to="/patients/ending-treatment"
      >
        {language.patientsNav.endingTreatment}
      </NavLink>
      <NavLink
        exact
        className={styles.link}
        activeClassName={styles.active}
        to="/patients/other-routes"
      >
        {language.patientsNav.otherRoutes}
      </NavLink>
    </nav>
  );
};

export default PatientsNav;
