import React, { useState } from "react";
import Slider from "@material-ui/core/Slider";
import classes from "./SliderQuestion.module.scss";
import DOMPurify from "dompurify";

const SliderQuestion = ({
  submitAnswer,
  answers,
  min = 0,
  max = 5,
  step = 1,
  initialValue,
  question,
  saveDelay = 1000,
  index,
  marks
}) => {
  const [clicked, setclicked] = useState(initialValue !== undefined);
  
  initialValue =
    initialValue !== undefined ? parseInt(initialValue) : (max - min) / 2 + min;

  const [currentSelection, setcurrentSelection] = useState(initialValue);
  const [timer, settimer] = useState(null);

  const valuetext = (value) => {
    if (value % 1 != 0) return "";
    return value;
  };

  let savedValue = -1;
  const onChange = (e, _value) => {
    setclicked(true)
    if (currentSelection === _value && clicked) return;
    setcurrentSelection(_value);

    if (_value !== savedValue) {
      clearTimeout(timer);
      settimer(setTimeout(() => saveValue(_value), saveDelay));
    } else {
      clearTimeout(timer);
    }
  };

  const saveValue = async (_value) => {
    submitAnswer(_value);
    savedValue = _value;
    clearTimeout(timer);
  };

  const scale = (num, in_min, in_max, out_min, out_max) => {
    return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
  };

  let sliderText = <React.Fragment></React.Fragment>;
  if (answers && currentSelection % 1 === 0) {
    const index = Math.round(
      scale(currentSelection, min, max, 0, answers.length - 1)
    );
    sliderText = (
      <div
        className={classes.sliderText}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(answers[index]) }}
      ></div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <div
        id={index}
        className={classes.question}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question) }}
      />
      <div className={classes.root}>
        <Slider
          classes={{ root: classes.slider, colorPrimary: classes.sliderEnabled, colorSecondary: classes.sliderDisabled}}
          color={!clicked ? "secondary" : "primary"}
          value={currentSelection}
          aria-labelledby={index}
          step={step}
          marks={marks}
          min={min}
          max={max}
          
          onChange={onChange}
          orientation="vertical"
        />
      </div>
    </div>
  );
};

export default SliderQuestion;
