import React from "react";
import classes from "./ProgressBar.module.scss";

const ProgressBar = ({ totalPages, currentIndex }) => {
  return (
    <div className={classes.ProgressBar}>
      {[...Array(totalPages)].map((e, i) => {
        if (i < currentIndex)
          return <div key={i} className={classes.barComplete} />;
        else if (i === currentIndex)
          return <div key={i} className={classes.barActive} />;
        else return <div key={i} className={classes.barIncomplete} />;
      })}
    </div>
  );
};

export default ProgressBar;
