import React, { useState, useEffect, useContext } from "react";
import ValueStatementDraggableList from "./ValueStatementDraggableList";
import axios from "axios";
import ValueStatementInput from "./ValueStatementInput";

import usePreviousValueStatements from "../../../Hooks/usePreviousValueStatements";

import utils from "../../../utils/utils";
import LoadingScreen from "../../../Components/LoadingScreen/LoadingScreen";
import Paragraph from "../../../Components/SanatizedComponents/Paragraph";

import { LanguageContext } from "../../../Store/LanguageStore";
import handleError from "../../../lib/handleError";
const Activity4_main = ({ attempt, allowContinue, restrictContinue }) => {
  const [language] = useContext(LanguageContext);
  const task_id = 4;
  const questionCount = 1;

  const [
    valueStatements,
    loading,
    setvalueStatements,
  ] = usePreviousValueStatements(attempt, null, allowContinue);

  const submitTextAnswer = (answer, area) => {
    let _valueStatements = [...valueStatements];

    let statment = {
      id: _valueStatements.length,
      answer_text: answer,
      area,
      rank: _valueStatements.length + 1,
    };
    _valueStatements.push(statment);
    setvalueStatements(_valueStatements);

    submitAnswers(_valueStatements);
  };

  // ToDo optimise this so that the card placement is quicker
  const submitRankAnswer = (cards) => {
    cards.forEach((card, index) => {
      card.rank = index + 1;
    });

    setvalueStatements(cards);
    submitAnswers(cards);
  };

  const deleteCard = (_id) => {
    let _cards = [...valueStatements];
    let newCards = [];
    let rank = 1;
    _cards.forEach((card, index) => {
      if (card.id != _id) {
        card.rank = rank;
        newCards.push(card);
        rank++;
      }
    });
    setvalueStatements(newCards);
    submitAnswers(newCards);
  };

  const [timer, settimer] = useState(null);
  const updateCard = (_id, answer, area) => {
    console.log(_id, answer, area);
    let _cards = [...valueStatements];
    let rank = 1;
    _cards.forEach((card, index) => {
      if (card.id == _id) {
        if (answer != null) {
          card.answer_text = answer;
        }
        if (area != null && area != undefined) {
          card.area = area;
        }
      }
    });
    setvalueStatements(_cards);
    console.log(_cards);

    clearTimeout(timer);
    settimer(setTimeout(() => submitAnswers(_cards), 1000));
  };

  const submitAnswers = async (_valueStatements, question_id = 1) => {
    clearTimeout(timer);
    let cards;
    if (!_valueStatements) cards = valueStatements;
    else cards = _valueStatements;

    let answers = [];

    console.log(cards.length);

    for (let i = 0; i < cards.length; i++) {
      const { answer_text, rank, area } = cards[i];
      answers.push({ answer_text, rank, life_area: parseInt(area) + 1 });
    }

    if (answers.length > 0) allowContinue();
    //else restrictContinue();

    const data = {
      task_id,
      question_id,
      answer: answers,
      attempt,
    };

    try {
      await axios.post("/user/answer/valuestatements/set", data);
    } catch (e) {
      handleError(e, true);
    }
  };

  return (
    <LoadingScreen loading={loading}>
      <div>
        <Paragraph text={language.steppingstone4_paragraph3} />

        <ValueStatementDraggableList
          valueStatements={valueStatements}
          submitRankAnswer={submitRankAnswer}
          deleteCard={deleteCard}
          updateCard={updateCard}
        />
        <ValueStatementInput submitAnswer={submitTextAnswer} />
      </div>
    </LoadingScreen>
  );
};

export default Activity4_main;
