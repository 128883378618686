import React, { useState, useContext, useEffect } from 'react';
import IconMobile from "../../Components/icons/Mobile";
import { Link } from "react-router-dom";
import classes from "./A2HS-popup.module.scss";
import { a2hsContext } from '../../Store/AddToHomeScreenStore';
import { LanguageContext } from '../../Store/LanguageStore';

const A2hsPopup = () => {

    /*
        if yes is clicked,
            either initiate add to homescreen event or take them to the add to home screen instructions page
        if not now is clicked
            hide the popup for the remainder of the session
        if never is clicked
            save a session variable to perminantly block this popup on this device

            * note should also have the add to home screen button in the settings page, so if they accidently click never they can still add it to the homescreen
    */

    const [platform,installEvent, initialized] = useContext(a2hsContext)
    const [language] = useContext(LanguageContext);
    const [hidden, sethidden] = useState(true);

    let session;

    useEffect(() => {
        // if platform is available and no session variable is saved
        session = JSON.parse(localStorage.getItem("addToHomeScreenSession"));

        if(session == undefined){
            session = {
                neverShowPopup: false,
                installed: false,
                notNowLastClick: 0
            }
        }

        console.log(getTimestamp() -  60)
        console.log(session.notNowLastClick)
        console.log(session.notNowLastClick < getTimestamp() -  60)

        console.log(installEvent);

        if(
            initialized
            &&
            (true || platform.isCompatible) 
            && 
            (!session.neverShowPopup && !session.installed && session.notNowLastClick < getTimestamp() - 24 * 60 * 60)
        ){
            sethidden(false);
        }
    }, [platform, installEvent, initialized]);

    const getTimestamp = () => {
        return Date.now() / 1000;
    }

    const installApp = () => {
        installEvent.prompt();
    }

    const notNowClick = () => {
        session = JSON.parse(localStorage.getItem("addToHomeScreenSession"));

        if(session == undefined){
            session = {
                neverShowPopup: false,
                installed: false,
                notNowLastClick: 0
            }
        }

        session.notNowLastClick = getTimestamp();
        localStorage.setItem("addToHomeScreenSession",JSON.stringify(session));
        sethidden(true);
    }

    const neverClick = () => {

        session = JSON.parse(localStorage.getItem("addToHomeScreenSession"));

        if(session == undefined){
            session = {
                neverShowPopup: false,
                installed: false,
                notNowLastClick: 0
            }
        }
        
        session.neverShowPopup = true;
        localStorage.setItem("addToHomeScreenSession",JSON.stringify(session));
        sethidden(true);
    }

    return <React.Fragment></React.Fragment>

    return (
        // translation not yet correctly done for this, and device detection not amazing so for now leaving this hidden
        <div className={classes.A2HSPopup} style={{display: hidden && true ? "none" : "block"}}> 
            <IconMobile className={classes.icon}/>
            <div className={classes.A2HSContent}>
                <div>
                    Add To HomeScreen
                </div>
                <div className={classes.A2HSButtons}>
                    {
                        installEvent ? 
                        <div className={`${classes.button} ${classes.greenButton}`} onClick={()=>installApp()}>{language.step_fields_recommended_yes}</div>
                        :
                        <Link className={`${classes.button} ${classes.greenButton}`}  to="/addtohomescreen">{language.step_fields_recommended_yes}</Link>
                    }
                    <div className={`${classes.button} ${classes.greyButton}`} onClick={()=>notNowClick()}>{language.never}</div>
                    <div className={`${classes.button} ${classes.greyButton}`} onClick={()=>neverClick()}>{language.not_now}</div>
                </div>
            </div>
            
                
            
        </div>
    );
}

export default A2hsPopup;
