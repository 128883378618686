import React, {useContext} from 'react';
import { LanguageContext } from '../Store/LanguageStore';
import classes from "../Pages/Activities/Activity4/StatementCard.module.scss";

const useValueStatmentStrings = () => {

    const [language] = useContext(LanguageContext);

    return [
        {
            id: 0,
            area: language.steppingstone4_lifedomain1 || "",
            propt: language.steppingstone4_valuestatements1 || "",
            hint: language.steppingstone4_valuestatements1_hint || "",
            className: classes.relationships
        },
        {
            id: 1,
            area: language.steppingstone4_lifedomain2 || "",
            propt: language.steppingstone4_valuestatements2 || "",
            hint: language.steppingstone4_valuestatements2_hint || "",
            className: classes.work
        },
        {
            id: 2,
            area: language.steppingstone4_lifedomain3 || "",
            propt: language.steppingstone4_valuestatements3 || "",
            hint: language.steppingstone4_valuestatements3_hint || "",
            className: classes.growth
        },
        {
            id: 3,
            area: language.steppingstone4_lifedomain4 || "",
            propt: language.steppingstone4_valuestatements4 || "",
            hint: language.steppingstone4_valuestatements4_hint || "",
            className: classes.health
        },
        {
            id: 4,
            area: language.steppingstone4_lifedomain5,
            propt: language.steppingstone4_valuestatements5,
            hint: language.steppingstone4_valuestatements5_hint,
            className: classes.other
        },
      ]
}

export default useValueStatmentStrings;
