import React, { useContext } from 'react';
import { LanguageContext } from '../../../../Store/LanguageStore';


const DemographicsWarning = () => {

    const [language] = useContext(LanguageContext);

    return (
        <div>
            <h1>{language.please_complete_the_following}</h1>
        </div>
    );
}

export default DemographicsWarning;
