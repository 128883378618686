import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

import DemographicsWarning from "./DemographicsWarning.jsx";
import { AccountContext } from "../../../../Store/AccountStore";
import { LanguageContext } from "../../../../Store/LanguageStore";
import { RedirectContext } from "../../../../Store/RedirectTo.jsx";
import TextField from "../../../../Components/Forms/TextField";
import Dropdown from "../../../../Components/Forms/Dropdown/DropdownInput";
import Heading from "../../../../Components/SanatizedComponents/Heading.jsx";
import Paragraph from "../../../../Components/SanatizedComponents/Paragraph.jsx";

import classes from "./UserInfo.module.scss";
import EmailInput from "./EmailInput.jsx";
import LoadingScreen from "../../../../Components/LoadingScreen/LoadingScreen.jsx";
import handleError from "../../../../lib/handleError.js";

const UserInfo = (props) => {

  console.log("rerender info")

  const [, getAccountInfo] = useContext(AccountContext);
  const [language] = useContext(LanguageContext);
  const [redirectTo] = useContext(RedirectContext);

  const [showWarnings, setshowWarnings] = useState(false);
  const [loading, setloading] = useState(true);

  const [message, setmessage] = useState("");
  const [isError, setisError] = useState(false);

  const [user, setuser] = useState({
    email: "",
    first_name: "",
    last_name: "",
    gender: undefined,
    age: undefined,
    relationship_status: undefined,
    parental_status: undefined,
    journey_status: undefined,
    education_achieved: undefined,
    country: undefined,
  });
  const [user_saved, set_user_saved] = useState(undefined);

  const [dropdownOptions, setdropdownOptions] = useState({
    relationship_status: [],
    parental_status: [],
    journey_status: [],
    genders: [],
    education_achieved: [],
    countries: [],
  });

  useEffect(() => {
    getUserDemographics();
    getDropdownOptions();
  }, []);

  const unsavedChanges = () => {


    for(let key in user){
      if(user[key] != user_saved[key] && (user[key] || user_saved[key])){      
        return true;
      } 
    }
    return false;
  }

  const getUserDemographics = async () => {
    try{
      let res = await axios.get("/user/demographics");
      let _user = { ...res.data };
  
      _user.email = _user.email || "";
      _user.first_name = _user.first_name || "";
      _user.last_name = _user.last_name || "";
      _user.gender = _user.gender;
      _user.age = _user.age;
      _user.relationship_status = _user.relationship_status;
      _user.parental_status = _user.parental_status;
      _user.journey_status = _user.journey_status;
      _user.education_achieved = _user.education_achieved;
      _user.country = _user.country;
  
      setuser(_user);
      set_user_saved(_user);
      setloading(false);
    }
    catch(e){
      setisError(true);
      setmessage(<React.Fragment>{language.anErrorOccured}</React.Fragment>)
      handleError(e);
    }
  };

  const getDropdownOptions = async () => {
    try{
      const res = await axios.get("/utils/demographics/dropdownOptions");
      setdropdownOptions(res.data);
    }
    catch(e){
      handleError(e);
    }
  };



  // get years of birth and also the selected value


  const updateUser = (demographic, value) => {
    setmessage(undefined);
    let _user = { ...user };
    _user[demographic] = value;
    setuser(_user);
  };


  const saveUser = async () => {
    try {
      await axios.post("/user/demographics/update", { user });
      setshowWarnings(true);
      set_user_saved(user);
      setisError(false);
      await getAccountInfo();
      setmessage(<React.Fragment>{language.saved_successfully}</React.Fragment>)
    } catch (e) {
      setisError(true);
      setmessage(<React.Fragment>{language.anErrorOccured}</React.Fragment>)
      handleError(e);
    }
  };
  
  return (
    <LoadingScreen loading={loading}>
      <UserInfoForm 
        user={user}
        showWarnings={true}
        updateUser={updateUser}
        unsavedChanges={unsavedChanges}
        saveUser={saveUser}
        message={message}
        isError={isError}
      />
    </LoadingScreen>
  )
  


};

const UserInfoForm = ({user, showWarnings, updateUser, unsavedChanges, saveUser, message, isError}) => {

  const [language] = useContext(LanguageContext);

  let demographicsWarning = <React.Fragment></React.Fragment>;
  if (user && !user.demographicsComplete) {
    demographicsWarning = <DemographicsWarning />;
  }

  const getOptions = (demographic) => {
    let options = [];
    for (const index in language.demographics[demographic].answers) {
      options.push({
        id: parseInt(index) + 1,
        value: language.demographics[demographic].answers[index],
      });
    }
    return options;
  };

  let yearsOfBirth = [];
  let selectedYear = 0;
  const year = new Date().getFullYear();
  for (let i = 0; i < 120; i++) {
    yearsOfBirth.push({ id: i+1, value: year - i });
    if (year - i == user.age) {
      selectedYear = i+1;
    }
  }

  const placeholder = ` - ${language.please_select_a_value} -`;

  const hint = showWarnings ? {
    isError: true,
    hint: language.please_complete_this_field
  } : undefined;

  if(user.qualtrics_id){
    return (
      <div className={classes.demographics}>
        {demographicsWarning}
        <Paragraph text={language.demographics_explainer}/>
        <div className={classes.inputsWrapper}>
          <EmailInput initialValue={user.email}/>
          <div className={classes.inputSplit}>
            <TextField
              label={`* ${language.first_name}`}
              value={user.first_name}
              hint={user.first_name == "" ? hint : undefined}
              onChange={(e) => updateUser("first_name", e.target.value)}
            />
            <TextField
              label={language.last_name}
              value={user.last_name}
              onChange={(e) => updateUser("last_name", e.target.value)}
            />
          </div>
          <div className={classes.inputSplit}>
            <Dropdown
              placeholder={placeholder}
              label={`* ${language.demographics.country.question}`}
              value={user.country}
              onChange={(value) => updateUser("country", value)}
              options={getOptions("country")}
              hint={!user.country ? hint : undefined}
            />
            <Dropdown
              placeholder={placeholder}
              label={`* ${language.demographics.gender.question}`}
              value={user.gender}
              onChange={(value) => updateUser("gender", value)}
              options={getOptions("gender")}
              hint={!user.gender ? hint : undefined}
            />
          </div>
        </div>
        {
          unsavedChanges() ? <button className={classes.buttonSubmit} onClick={saveUser}>
                              {language.save}
                            </button>
          : message ? message : <React.Fragment/>
        }
      </div>
    );
  }else{
    return (
      <div className={classes.demographics}>
        <Heading text={language.your_info} level="h2" />
        {demographicsWarning}
        <Paragraph text={language.demographics_explainer}/>
        <div className={classes.inputsWrapper}>
          <EmailInput initialValue={user.email}/>
          <div className={classes.inputSplit}>
            <TextField
              label={`* ${language.first_name}`}
              value={user.first_name}
              onChange={(e) => updateUser("first_name", e.target.value)}
              hint={!user.first_name ? hint : undefined}
            />
            <TextField
              label={language.last_name}
              value={user.last_name}
              onChange={(e) => updateUser("last_name", e.target.value)}
            />
          </div>
          <Dropdown
            placeholder={placeholder}
            label={`* ${language.demographics.education.question}`}
            value={user.education_achieved}
            onChange={(value) => updateUser("education_achieved", value)}
            options={getOptions("education")}
            hint={!user.education_achieved ? hint : undefined}
          />
          <Dropdown
            placeholder={placeholder}
            label={`* ${language.demographics.country.question}`}
            value={user.country}
            onChange={(value) => updateUser("country", value)}
            options={getOptions("country")}
            hint={!user.country ? hint : undefined}
          />
          <div className={classes.inputSplit}>
            <Dropdown
              placeholder={placeholder}
              label={language.demographics.year_of_birth.question}
              value={selectedYear}
              onChange={(value) => updateUser("age", yearsOfBirth.find(year=>year.id==value).value)}
              options={yearsOfBirth}
              hint={!selectedYear ? hint : undefined}
            />
            <Dropdown
              placeholder={placeholder}
              label={`* ${language.demographics.gender.question}`}
              value={user.gender}
              onChange={(value) => updateUser("gender", value)}
              options={getOptions("gender")}
              hint={!user.gender ? hint : undefined}
            />
          </div>
          <div className={classes.inputSplit}>
            <Dropdown
              placeholder={placeholder}
              label={language.demographics.relationship_status.question}
              value={user.relationship_status}
              onChange={(value) => updateUser("relationship_status", value)}
              options={getOptions("relationship_status")}
              hint={!user.relationship_status ? hint : undefined}
            />
            <Dropdown
              placeholder={placeholder}
              label={language.demographics.parental_status.question}
              value={user.parental_status}
              onChange={(value) => updateUser("parental_status", value)}
              options={getOptions("parental_status")}
              hint={!user.parental_status ? hint : undefined}
            />
          </div>
          <Dropdown
            placeholder={placeholder}
            label={language.demographics.journey_status.question}
            value={user.journey_status}
            onChange={(value) => updateUser("journey_status", value)}
            options={getOptions("journey_status")}
            hint={!user.journey_status ? hint : undefined}
          />
        </div>
        {
          unsavedChanges() ? <button className={classes.buttonSubmit} onClick={saveUser}>
                              {language.save}
                            </button>
          : <React.Fragment/>
        }
        {
          message && (
            <div className={`${classes.message} ${isError ? classes.isError : classes.isSuccess}`}>
                {message}
            </div>
          ) 
        }
      </div>
    );
  }
}

export default UserInfo;
