import React from "react";
import FooterNav from "./FooterNav";
import FooterLogos from "./FooterLogos";
import classes from "./Footer.module.scss";
import SocialIcons from "./SocialIcons/SocialIcons";

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <div>
        <FooterNav />
        <SocialIcons />
      </div>
      <FooterLogos />
    </footer>
  );
};

export default Footer;
