import React from 'react';
import classes from './forms.module.scss';
import nl2br from 'react-nl2br';

const TextField = React.forwardRef(({id="placeholder", label, value, onChange, hint, type="text"}, ref) => {
    
    let isErrorHint;
    if(typeof hint == 'object'){
        isErrorHint = hint.isError;
        hint = hint.hint;
    }

    const hintClass = typeof isErrorHint == 'undefined' ? "" : isErrorHint ? classes.helpRed : classes.helpGreen;

    return (
        <div className={classes.field}>
            <label htmlFor={id} className={classes.label}>{label}</label>
            <input id={id} value={value} onChange={onChange} type={type} ref={ref}></input>
            {hint &&
                <div className={`${classes.help} ${hintClass}`}>
                    {nl2br(hint)}
                </div>
            }
        </div>
    )
});

export default TextField;
