import React from 'react';
import DOMPurify from 'dompurify'


const Heading = ({ text, level, className }) => {

    const innerHTML = { __html: DOMPurify.sanitize(text) };

    switch (level) {
        case 'h5':
            return <h5 className={className} dangerouslySetInnerHTML={innerHTML}></h5>;
        case 'h4':
            return <h4 className={className} dangerouslySetInnerHTML={innerHTML}></h4>;
        case 'h3':
            return <h3 className={className} dangerouslySetInnerHTML={innerHTML}></h3>;
        case 'h2':
            return <h2 className={className} dangerouslySetInnerHTML={innerHTML}></h2>;
        default:
            return <h1 className={className} dangerouslySetInnerHTML={innerHTML}></h1>
    }


}

export default Heading;
