import axios from "axios";
import handleError from "../lib/handleError";

const utils = {
    submitAnswer: async (task_id, question_id, answer, attempt = 1) => {
        const data = {
            task_id,
            question_id,
            answer,
            attempt
        }

        try{
            await axios.post("/user/answer/set",data)
        }
        catch(e){
            handleError(e, true);
        }
    },

    getAnswer: async (task_id, question_id, attempt = -1) => {
        try{
            let answer = await axios.post('/user/answer/get',{task_id, question_id})
            if(answer.data.found){
                return answer.data.answer;
            }
            else{
                return false;
            }
        }catch(e){
            handleError(e);
            return false;
        }
    },

    addAnswerToState: (index, answer, answers, setAnswers, allowContinue) => {
        let _answers = [...answers];
        _answers[index] = answer;
        if(!_answers.includes(undefined)) allowContinue();
        setAnswers(_answers)
    }
}

export default utils;