import React, { useEffect, useRef, useState, Fragment } from "react";
import classes from "./dropdown.module.scss";
import nl2br from "react-nl2br";
import Dropdown from "./Dropdown";

const DropdownInput = ({
  label,
  value,
  onChange,
  options,
  placeholder,
  reverse,
  hint,
}) => {
  // ToDo: this should be a custom dropdown

  let isErrorHint;
  if (typeof hint == "object") {
    isErrorHint = hint.isError;
    hint = hint.hint;
  }

  const hintClass =
    typeof isErrorHint == "undefined"
      ? ""
      : isErrorHint
      ? classes.helpRed
      : classes.helpGreen;

  return (
    <div className={classes.field}>
      <label className={classes.label}>{label}</label>
      <Dropdown
        onChange={onChange}
        options={options}
        value={value}
        placeholder={placeholder}
      />

      {hint && (
        <div className={`${classes.help} ${hintClass}`}>{nl2br(hint)}</div>
      )}
    </div>
  );
};

export default DropdownInput;
