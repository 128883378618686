import React from "react";

const Plan = ({ className, base, baseColor = "#ddf5f5" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      {base && <circle cx="54" cy="54" r="45" fill={baseColor} />}

      <path
        d="M54,4S76.43,28.73,76.43,53.45s-44.87,24.73-44.87,0S54,4,54,4Z"
        fill="#db6b5e"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M54,72c-4.82,0-12.47-2.34-12.47-8.6,0-12.77,6.57-27,12.47-37,5.89,9.94,12.46,24.2,12.46,37C66.46,69.66,58.82,72,54,72Z"
        fill="#df8951"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M54,72c-2.86,0-7.39-1.38-7.39-5.1,0-7.56,3.89-16,7.39-21.9,3.49,5.89,7.38,14.34,7.38,21.9C61.38,70.62,56.85,72,54,72Z"
        fill="#fabb26"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />

      <path
        d="M95.44,70c2.4-.66,3.28-5.07,2-9.87S93.1,52,90.7,52.66L12.56,74,17.3,91.34Z"
        fill="#a18c73"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <ellipse
        cx="14.93"
        cy="82.66"
        rx="4.5"
        ry="9"
        transform="translate(-21.22 6.84) rotate(-15.26)"
        strokeWidth="2"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#d6bb9a"
      />

      <path
        d="M12.56,70c-2.4-.66-3.28-5.07-2-9.87S14.9,52,17.3,52.66L95.44,74,90.7,91.34Z"
        fill="#a18c73"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <ellipse
        cx="93.07"
        cy="82.66"
        rx="9"
        ry="4.5"
        transform="translate(-11.16 150.7) rotate(-74.74)"
        strokeWidth="2"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#d6bb9a"
      />
      <circle
        cx="54"
        cy="90"
        r="9"
        fill="#d6bb9a"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Plan;
