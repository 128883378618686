import React, { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer/Footer";
import classes from "./NotReady.module.scss";
import IconCalendar from "../../Components/icons/Calendar";
import IconBridge from "../../Components/icons/Bridge";

import Heading from "../../Components/SanatizedComponents/Heading";
import Paragraph from "../../Components/SanatizedComponents/Paragraph";
import { LanguageContext } from "../../Store/LanguageStore";

import { ApplicationStatusContext } from "../../Store/ApplicationStatusStore";

const NotReady = () => {
  const [language] = useContext(LanguageContext);
  const [applicationStatus] = useContext(ApplicationStatusContext);

  const strings = language.not_ready;
  return (
    <Fragment>
      <main>
        <section className={classes.intro}>
          <div className={classes.inner}>
            <IconBridge className={classes.introIcon} base />
            <Heading text={strings.title} />
            <Paragraph text={strings.intro} />
          </div>
        </section>

        <section className={classes.notReadyConsider}>
          <div className={classes.inner}>
            <Heading text={strings.consider_title} level="h2" />
            <Paragraph text={strings.consider_paragraph_1} />
            <Paragraph text={strings.consider_paragraph_2} />

            <ul className={classes.grid}>
              <li
                dangerouslySetInnerHTML={{
                  __html: strings.consider_paragraph_3,
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: strings.consider_paragraph_4,
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: strings.consider_paragraph_5,
                }}
              />
            </ul>
          </div>
        </section>

        <section className={classes.ctaSection}>
          <div className={classes.inner}>
            <Link
              className={classes.cta}
              to={applicationStatus.studyRunning ? "/study-info" : "/register"}
              dangerouslySetInnerHTML={{ __html: strings.trial_cta }}
            />
          </div>
        </section>

        <section className={classes.notReadyReminder}>
          <div className={classes.inner}>
            <IconCalendar base className={classes.gridIcon} />
            <Heading text={strings.not_yet_title} level="h2" />
            <Paragraph text={strings.not_yet_content} />
          </div>
        </section>
      </main>
      <div className={classes.footerWrapper}>
        <Footer />
      </div>
    </Fragment>
  );
};

export default NotReady;
