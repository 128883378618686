import React from "react";
import styles from "./YouTubeEmbed.module.scss";
const YouTubeEmbed = ({ src, title = "video" }) => {
  return (
    <div className={styles.wrapper}>
      <iframe
        id="ytplayer"
        type="text/html"
        className={styles.video}
        width="1280"
        height="720"
        src={src}
        title={title}
        frameBorder="0"
        allowFullScreen
        color="white"
        modestbranding="1"
      ></iframe>
    </div>
  );
};

export default YouTubeEmbed;
