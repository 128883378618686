import React, { Fragment } from 'react';

import AccountStore from "./AccountStore";
import RedirectTo from "./RedirectTo";
import LanguageStore from "./LanguageStore";
import PopupStore from "./PopupStore";
import ApplicationStatusStore from "./ApplicationStatusStore";
import ListenForParams from "./URLParamsStore";
import SessionUsageMonitor from '../utils/SessionUsageMonitor';
import Addtohomescreenstore from './AddToHomeScreenStore';

const AllStores = ({children}) => {
    return (
        <Fragment>

            <Addtohomescreenstore>
                <ApplicationStatusStore>
                    <RedirectTo>

                        <AccountStore>
                            <ListenForParams>
                                <SessionUsageMonitor/>

                                <LanguageStore>
                                    <PopupStore>
                                            {children}
                                    </PopupStore>
                                </LanguageStore>

                            </ListenForParams>
                        </AccountStore>

                    </RedirectTo>
                </ApplicationStatusStore>
            </Addtohomescreenstore>

        </Fragment>
    );
}

export default AllStores;
