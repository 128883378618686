import React, {useRef, useState} from 'react';
import Paragraph from '../../../../Components/SanatizedComponents/Paragraph';
import TextField from '../../../../Components/Forms/TextField';
import FileDownload from 'js-file-download';
import Axios from 'axios';
import handleError from '../../../../lib/handleError';

const BatchSearch = () => {

    const inputRef = useRef()
    const [result, setresult] = useState(undefined);


    const search = async () => {

        let queries = inputRef.current.value.trim().split(/[\s\t\n]*,[\s\t\n]*/g);
        if(queries.length == 1 && queries[0] == ""){
            queries = [];
        }
        if(queries[queries.length-1] == ""){
            queries.pop();
        }

        console.log(queries);

        Axios.post('/admin/getUserData',{search_queries: queries}, {responseType: 'arraybuffer'}).then((res)=>{
            var data = res.data; //from server
            var blob = new Blob([data], { type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml;charset=UTF-8"});
            var link = document.createElement('a');
            link.href = URL.createObjectURL(blob);

            let name = "myJourneyUserData.xlsx";

            if(queries.length == 1){
                let name = "myJourney_" + queries[0] + "_data.xlsx";
            }

            link.download = name;
            link.click();
        }).catch((e)=>{
            console.log(e);
            alert("an error occured, please check the system logs");
        });
        

    }

    return (
        <div>
            <Paragraph text="Enter the user emails or qualtrics ids seperated by commas"/>
            <textarea
                ref={inputRef}
                style={{height: 200, fontSize: 16}}
            />
            <button  onClick={search}>
                Search
            </button>
            
            
        </div>
    );
}

export default BatchSearch;
