import React from "react";

const Mindful = ({ className, color = "black", weight = 3 }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      <path
        d="M507.92,13.91V45.29L490.5,54V90.29l5.81,2.9V65.61l11.61,5.81V96.1l5.81,2.9,5.8-2.9V71.42l11.62-5.81V93.19l5.8-2.9V24.47a10,10,0,0,0-5.53-8.94l-15.91-8a4,4,0,0,0-3.57,0l-.15.08A7,7,0,0,0,507.92,13.91Z"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <path
        d="M458.27,99l5.81-2.9V71.42l11.61-5.81V93.19l5.81-2.9V54l-17.42-8.71V13.91a7,7,0,0,0-3.87-6.26l-.15-.08a4,4,0,0,0-3.57,0l-15.91,8a10,10,0,0,0-5.53,8.94V90.29l5.8,2.9V65.61l11.62,5.81V96.1l5.8,2.9Z"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />

      <rect width="108" height="108" fill="none" />
      <path
        d="M80.5,54C80.5,72,66,90,54,90S27.5,72,27.5,54"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />

      <polyline
        points="82 70.42 82 67.28 82 65"
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <path
        d="M82,76.45c0-3,4,0,4-3s-4,0-4-3"
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={weight}
      />

      <path
        d="M82,76.45c0,3,4,0,4,3s-4,0-4,3"
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <path
        d="M82,82.48c0,3,4,0,4,3s-4,0-4,3"
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <path
        d="M82,88.52c0,3,4,0,4,3s-4,0-4,3"
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={weight}
      />

      <polyline
        points="82 100.58 82 103.72 82 106"
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <path
        d="M82,94.55c0,3,4,0,4,3s-4,0-4,3"
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={weight}
      />

      <path
        d="M60,54s1.2,3,6,3,6-3,6-3"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <path
        d="M36,54s1.2,3,6,3,6-3,6-3"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <path
        d="M47.5,67A14.46,14.46,0,0,0,54,69a14.46,14.46,0,0,0,6.5-2"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
      <path
        d="M64.5,74A64.34,64.34,0,0,1,54,75a64.34,64.34,0,0,1-10.5-1"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />

      <path
        d="M25,45.75c29-5.5,29-23.5,29-23.5s0,18,29,23.5"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />

      <path
        d="M21,62A1.76,1.76,0,0,1,18,61c-1-12.38-1-49.38,36-49.38S91.06,48.61,90,61A1.76,1.76,0,0,1,87,62H81c.67-6.77,7.48-25.85-2.46-37.56C73,17.9,64.72,14.57,54,14.57S35,17.9,29.46,24.45C19.52,36.17,26.33,55.24,27,62Z"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />

      <path
        d="M83.88,40.6a48.11,48.11,0,0,1-.16,4.82l-.72.33-2-.41L79.72,45a53.39,53.39,0,0,1,.78,9,38.53,38.53,0,0,1-2.38,13H81C91.94,67,92.9,44.55,83.88,40.6Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
        fill={color}
      />
      <path
        d="M28.28,45c-1.06.25-2.14.49-3.28.71l-.72-.28c-.13-1.6-.19-3.23-.16-4.87C15.1,44.55,16.06,67,27,67h2.88A38.53,38.53,0,0,1,27.5,54,53.39,53.39,0,0,1,28.28,45Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight}
      />
    </svg>
  );
};

export default Mindful;
