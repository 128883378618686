import React, { useState } from 'react';
import nl2br from 'react-nl2br';
import DOMPurify from 'dompurify';
import classes from "./TextQuestion.module.scss";

const TextQuestion = ({ question, hint, submitAnswer, initialValue = "", delay = 1000, height = "m" }) => {

    // save on unfocus and also save after 2 seconds of not typing

    const [value, setvalue] = useState(initialValue);
    const [timer, settimer] = useState(null);
    const [helpOpen, sethelpOpen] = useState(false);

    // let savedValue = "";
    const onChange = (e) => {
        let _value = e.target.value;
        setvalue(_value);
        clearTimeout(timer);
        settimer(setTimeout(() => saveValue(_value), delay));
    }

    const saveValue = async (_value) => {
        submitAnswer(_value);
        //savedValue = _value;
        clearTimeout(timer);
    }

    let boxHeight = 225;
    switch(height){
        case "xl":
            boxHeight = "16em";
            break;
        case "l":
            boxHeight = "12em";
            break;
        case "m":
            boxHeight = "8em";
            break;
        case "s":
            boxHeight = "4.5em";
            break;
        default:
            console.error("textarea size not recognised, should be [xl,l,m,s]");
    }


    return (
        <div className={classes.wrapper}>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question) }} />
            <textarea value={value} onChange={onChange} style={{height: boxHeight}}></textarea>
            {hint &&
                <div className={classes.help}>
                    {hint}
                </div>
            }
        </div>
    );
}

export default TextQuestion;
