import React from "react";
import classes from "./CloseButton.module.scss";

const noOnClick = () => console.log("No onClick assigned");

const Cross = ({ className }) => (
  <svg className={className} viewBox="0 0 10 10">
    <polygon points="2.2,0.8 0.8,2.2 7.8,9.2 9.2,7.8 2.2,0.8" />
    <polygon points="7.8,0.8 0.8,7.8 2.2,9.2 9.2,2.2 7.8,0.8" />
  </svg>
);

const CloseButton = ({
  className,
  label = "Close",
  type = "button",
  onClick = noOnClick,
  icon,
}) => {
  return (
    <button className={className} onClick={onClick}>
      <Cross className={classes.icon} />
      <span className={classes.screenReadrText}>{label}</span>
    </button>
  );
};

export default CloseButton;
