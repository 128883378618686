import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { AccountContext } from "./AccountStore";
import handleError from "../lib/handleError";
import { URLParamsContext } from "./URLParamsStore";
import LoadingScreen from "../Components/LoadingScreen/LoadingScreen";

export const LanguageContext = React.createContext();

const defaultLanguage = "English";

const LanguageStore = ({ children }) => {
  const [user, , loading] = useContext(AccountContext);
  const [params] = useContext(URLParamsContext);

  return (
    <LoadingScreen loading={loading}>
      <InnerLanguageStore user={user} params={params}>
        {children}
      </InnerLanguageStore>
    </LoadingScreen>
  );
};

const InnerLanguageStore = React.memo(
  ({ user, params, children }) => {
    const [state, setstate] = useState(undefined);
    const [loading, setloading] = useState(true);
    const [languages, setlanguages] = useState([]);

    const languageShorthand = {
      pt: "Portuguese",
      en: "English",
      de: "German",
      es: "Spanish (Europe)",
      "es-419": "Spanish (Latin America)",
    };

    useEffect(() => {
      getLanguages();

      if (params.language) {
        if (languageShorthand[params.language.toLowerCase()] !== null) {
          changeLanguage(languageShorthand[params.language]);
        }
      } else if (localStorage.getItem("Language")) {
        const _language = localStorage.getItem("Language");
        changeLanguage(_language);
      } else if (user.language) {
        changeLanguage(user.language);
      } 
      // else if (Object.keys(languageShorthand).includes(navigator.language) && languageShorthand[navigator.language] != null) {
      //   changeLanguage(languageShorthand[navigator.language]);
      // } 
      else {
        changeLanguage(defaultLanguage);
      }
    }, [params]);

    const getLanguages = async () => {
      try {
        const result = await axios.post("/utils/languages/available");
        setlanguages(result.data);
      } catch (e) {
        handleError(e);
      }
    };

    const changeLanguage = async (language) => {
      if (
        state === undefined ||
        state.language !== language ||
        user.language !== language
      ) {
        localStorage.setItem("Language", language);
        try {
          const result = await axios.post("/utils/language/get", { language });
          setstate(result.data);
          if (user.id) {
            await axios.post("/user/language/update", { language: language });
          }
        } catch (e) {
          handleError(e, false);
        }
      }
      setloading(false);
    };

    return (
      <LoadingScreen loading={loading}>
        <LanguageContext.Provider
          value={[state, changeLanguage, languages, loading]}
        >
          {children}
        </LanguageContext.Provider>
      </LoadingScreen>
    );
  },
  (prev, next) => {
    if (
      (prev.user.language || next.user.language) &&
      prev.user.language !== next.user.language
    )
      return false;

    if ((prev.user.id || next.user.id) && prev.user.id !== next.user.id)
      return false;

    if (
      (prev.params.language || next.params.language) &&
      prev.params.language !== next.params.language
    )
      return false;

    return true;
  }
);

export default LanguageStore;
