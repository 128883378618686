import React from 'react';

import RoutinePage from '../../Components/Routine/RoutinePages/RoutinePage';


const RoutineHub = (props) => {
    const {match} = props;
    const {params: {routineId}} = match;

    return <RoutinePage id={routineId} {...props}/>;
}

export default RoutineHub;
