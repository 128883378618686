import React from "react";
import YouTubeEmbed from "../YouTubeEmbed/YouTubeEmbed";

import styles from "./Resource.module.scss";

const Resource = ({ resource }) => {
	console.log("resource");
	return (
		<div
			className={
				resource.type === "video" ? styles.wrapperVideo : styles.wrapper
			}
		>
			{resource.type === "video" ? (
				<div className={styles.video}>
					<YouTubeEmbed
						className={styles.video}
						src={resource.video}
					/>
				</div>
			) : (
				<img
					alt={resource.title}
					className={styles.image}
					src={resource.image}
				/>
			)}
			<div>
				<h3 className={styles.title}>{resource.title}</h3>
				<div dangerouslySetInnerHTML={{ __html: resource.description }} />
			</div>
			{resource.links && (
				<ul className={styles.links}>
					{resource.links.map((link, i) => (
						<li key={i}>
							<a
								target="_blank"
								rel="noopener noreferrer"
								className={styles.link}
								href={link.url}
							>
								{link.name}
							</a>
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default Resource;
