import React, { useContext, useState, useRef } from "react";
import { MdLanguage } from "react-icons/md";
import Modal from "react-modal";

import classes from "./LanguageSelection.module.scss";

import { LanguageContext } from "../../../Store/LanguageStore";

const LanguageSelection = ({ className }) => {
  const [language, setLanguage, languages] = useContext(LanguageContext);

  const [modalOpen, setModalOpen] = useState(false);
  const dropdownRef = useRef();

  return (
    <div className={className}>
      <button
        className={classes.languagesButton}
        onClick={() => setModalOpen(true)}
      >
        <MdLanguage size="2em" />
      </button>
      <Modal
        className={classes.modal}
        overlayClassName={classes.modalOverlay}
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
      >
        <ul ref={dropdownRef} className={classes.languages}>
          {languages.map((thisLang, index) => {
            return (
              <li key={index}>
                <button
                  className={
                    thisLang.language === language.language
                      ? classes.button
                      : classes.buttonPrimary
                  }
                  onClick={() => {
                    setLanguage(thisLang.language);
                    setModalOpen(false);
                  }}
                >
                  {thisLang.native}
                </button>
              </li>
            );
          })}
        </ul>
      </Modal>
    </div>
  );
};

export default LanguageSelection;
