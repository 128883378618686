import React, { useState, useEffect, useContext } from "react";
import classes from "../Activity.module.scss";

import PreviousAnswer from "../../../Components/PreviousAnswer/PreviousAnswer";
import TextQuestion from "../../../Components/Questions/TextQuestion/TextQuestion";

import utils from "../../../utils/utils";
import LoadingScreen from "../../../Components/LoadingScreen/LoadingScreen";

import usePreviousAnswers from "../../../Hooks/usePreviousAnswers";

import { LanguageContext } from "../../../Store/LanguageStore";
import Heading from "../../../Components/SanatizedComponents/Heading";
import Paragraph from "../../../Components/SanatizedComponents/Paragraph";
import UnorderedList from "../../../Components/SanatizedComponents/UnorderedList";

const Activity3_main_1 = ({ attempt, allowContinue, restrictContinue }) => {
  const [language] = useContext(LanguageContext);
  const task_id = 3;

  const questionCount = 1;
  const [answers, loading, setAnswers] = usePreviousAnswers(
    task_id,
    questionCount,
    attempt,
    allowContinue,
    null
  );
  const [task1_answers, loading1] = usePreviousAnswers(
    1,
    1,
    null,
    null,
    null,
    2
  );

  const _submitAnswer = async (question_id, answer) => {
    utils.submitAnswer(task_id, question_id, answer, attempt);
    utils.addAnswerToState(
      question_id - 1,
      answer,
      answers,
      setAnswers,
      allowContinue
    );
  };

  return (
    <LoadingScreen loading={loading && loading1}>
      <div className={classes.questions}>
        <Paragraph text={language.steppingstone3_paragraph3} />
        <Paragraph text={language.steppingstone3_exercise_a_part_1} />
        <PreviousAnswer
          content={task1_answers[0]}
          no_answer_string={
            language.ssteppingstone3_exercise_a_part_1_NoPreviousAnswer
          }
        />
        <TextQuestion
          initialValue={answers[0]}
          submitAnswer={(answer) => _submitAnswer(1, answer)}
          question={language.steppingstone3_exercise_a_part_2}
          hint={language.steppingstone3_exercise_a_part_2_hint}
        />
        <Paragraph text={language.steppingstone3_exercise_b} />
        <Paragraph text={language.steppingstone3_exercise_c} />
      </div>
    </LoadingScreen>
  );
};

export default Activity3_main_1;
