import React, {useContext, useRef} from 'react';
import { Link } from "react-router-dom";

import classes from "./UserLoginRegisterPages.module.scss";
import { LanguageContext } from '../../../Store/LanguageStore';
import { URLParamsContext } from '../../../Store/URLParamsStore';
import { RedirectContext } from '../../../Store/RedirectTo';
import { useState } from 'react';
import Axios from 'axios';
import handleError from '../../../lib/handleError';
import TextField from '../../../Components/Forms/TextField';

const ChangePassword = () => {

    const [language] = useContext(LanguageContext);
    const [params] = useContext(URLParamsContext);
    const [redirect] = useContext(RedirectContext);
    const [message, setmessage] = useState("")
    const passRef = useRef()
    const passRepRef = useRef()
    

    const [passwordHint, setpasswordHint] = useState("");
    const [passwordValid, setpasswordValid] = useState(false);
    const onPasswordChange = (messageOnEmpty = false) => {
        let password = passRef.current.value;
        setpasswordValid(false);
        let _hint = "";

        if(password.length == 0){
        if(messageOnEmpty){
            setpasswordHint({
            isError: true,
            hint: language.please_complete_this_field
            });
        }else{
            setpasswordHint(_hint);
        }
        return;
        }
        
        let lowercase = /[a-z]+/.test(password);
        let uppercase = /[A-Z]+/.test(password);
        let number = /\d+/.test(password);
        let length = password.length > 5;
        
        if(!lowercase || !uppercase || !number || !length){
        _hint += language.your_password_must;
        if(!lowercase)
            _hint += "\n" + language.include_a_lowercase_letter;
        if(!uppercase)
            _hint += "\n" + language.include_a_uppercase_letter;
        if(!number)
            _hint += "\n" + language.include_a_number;
        if(!length)
            _hint += "\n" + language.be_at_least_6_characters;
        
            _hint = {
            isError: true,
            hint: _hint
            }
        }else if(password.length != 0){
        _hint = {
            isError: false,
            hint: language.password_ok
        }
        setpasswordValid(true);
        }

        setpasswordHint(_hint);
    }

    const [passwordRepHint, setpasswordRepHint] = useState("");
    const [repPasswordValid, setrepPasswordValid] = useState(false);
    const passwordRepChange = (messageOnEmpty = false) => {
        let password = passRef.current.value;
        let passwordRep = passRepRef.current.value;

        setrepPasswordValid(false);

        if(passwordRep.length == 0){
        if(messageOnEmpty){
            setpasswordRepHint({
            isError: true,
            hint: language.please_complete_this_field
            });
        }else{
            setpasswordRepHint("");
        }
        }else if(passwordRep == password){
        setpasswordRepHint({
            isError: false,
            hint: language.passwords_match
        });
        setrepPasswordValid(true);
        }else{
        setpasswordRepHint({
            isError: true,
            hint: language.passwords_domt_match
        });
        }
    }

    const submit = async () => {
        
        if(!passwordValid || !repPasswordValid){
            onPasswordChange(true);
            passwordRepChange(true);
            return
          }
        
        const code = params.password_token;
        const password = passRef.current.value;

        try{
            await Axios.post('/user/password/reset/submit', {code, password});
            alert(language.password_updated_successfully);
            redirect('/login');
            
        }catch(e){
            handleError(e, true);
        }


        // get and varify password
        // submit
        
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.content}>
                <div className={classes.form}>
                    <h1>{language.changeYourPassword}</h1>
                    <TextField
                        id="registerPassword"
                        label={language.password}
                        onChange={onPasswordChange}
                        hint={passwordHint}
                        type="password"
                        ref={passRef}
                    />
                    <TextField
                        id="registerPasswordRepeat"
                        label={language.confirm_password}
                        onChange={passwordRepChange}
                        hint={passwordRepHint}
                        type="password"
                        ref={passRepRef}
                    />
                    <button className={classes.buttonSubmit} onClick={submit}>{language.changeYourPassword}</button>
                </div >
                <div className={classes.wrongViewMessage}>
                    {message}
                </div>
            </div>
        </div>
    );
}

export default ChangePassword;
