import React from "react";

const Alert = ({ className, base, baseColor = "#ddf5f5" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
    >
      {base && <circle cx="54" cy="54" r="45" fill={baseColor} />}
      <rect
        x="49.5"
        y="2.87"
        width="9"
        height="103"
        strokeWidth="2"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#404a45"
      />
      <path
        d="M58.24,11.46,93.8,73.69A4.89,4.89,0,0,1,89.56,81H18.44a4.89,4.89,0,0,1-4.24-7.31L49.76,11.46A4.88,4.88,0,0,1,58.24,11.46Z"
        fill="#db6b5e"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <polygon
        points="21.89 74.11 54 17.92 86.11 74.11 21.89 74.11"
        fill="#faf1ee"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <polygon
        points="56.24 56.57 51.76 56.57 49.52 34.17 58.48 34.17 56.24 56.57"
        fill="#231f20"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <circle
        cx="54"
        cy="65.53"
        r="4.48"
        fill="#231f20"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Alert;
