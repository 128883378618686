import React, {useContext} from 'react';

import UnorderedList from '../../../Components/SanatizedComponents/UnorderedList';
import Quote from '../../../Components/SanatizedComponents/Quote';
import Paragraph from '../../../Components/SanatizedComponents/Paragraph';
import {LanguageContext} from '../../../Store/LanguageStore'

const Activity8_intro = () => {
    const [language] = useContext(LanguageContext);
    return (
        <div>
            <Paragraph text={language.steppingstone8_paragraph1}/>
            <Paragraph text={language.steppingstone8_paragraph2}/>
            <UnorderedList items={language.steppingstone8_paragraph2_bullets1}/>
            <Paragraph text={language.steppingstone8_paragraph2_end}/>
            <Quote text={language.steppingstone8_quote1}/>
           
        </div>
    );
}

export default Activity8_intro;
