import React, { useState, useEffect, useContext } from "react";
import classes from "../Activity.module.scss";

import TextQuestion from "../../../Components/Questions/TextQuestion/TextQuestion";
import SliderQuestion from "../../../Components/Questions/SliderQuestion/SliderQuestion";
import LoadingScreen from "../../../Components/LoadingScreen/LoadingScreen";

import usePreviousAnswers from "../../../Hooks/usePreviousAnswers";

import { LanguageContext } from "../../../Store/LanguageStore";
import Heading from "../../../Components/SanatizedComponents/Heading";
import Paragraph from "../../../Components/SanatizedComponents/Paragraph";

import utils from "../../../utils/utils";
import PreviousAnswer from "../../../Components/PreviousAnswer/PreviousAnswer";

const Activity2_main_2 = ({
  task_id,
  attempt,
  allowContinue,
  restrictContinue,
}) => {
  const [language] = useContext(LanguageContext);

  const questionCount = 2;
  const firstQuestionIndex = 2;
  const [answers, loading, setAnswers] = usePreviousAnswers(
    task_id,
    questionCount,
    attempt,
    allowContinue,
    null,
    firstQuestionIndex
  );
  const [task1_answers, loading1] = usePreviousAnswers(1, 3);

  console.log(answers);

  const submitAnswer = async (question_id, answer) => {
    utils.submitAnswer(task_id, question_id, answer, attempt);
    utils.addAnswerToState(
      question_id - 1 - firstQuestionIndex,
      answer,
      answers,
      setAnswers,
      allowContinue
    );
  };

  return (
    <LoadingScreen loading={loading && loading1}>
      <div className={classes.questions}>
        <Paragraph text={language.steppingstone2_exercise_b_part_1} />
        <PreviousAnswer
          content={task1_answers[0]}
          no_answer_string={
            language.steppingstone2_exercise_b_part_1_NoPreviousAnswer_emotions
          }
        />
        <PreviousAnswer
          content={task1_answers[2]}
          no_answer_string={
            language.steppingstone2_exercise_b_part_1_NoPreviousAnswer_thoughts
          }
        />
        <TextQuestion
          initialValue={answers[0]}
          submitAnswer={(answer) => submitAnswer(3, answer)}
          question={language.steppingstone2_exercise_b_part_2}
          hint={language.steppingstone2_exercise_b_part_2_hint}
        />
        <TextQuestion
          initialValue={answers[1]}
          submitAnswer={(answer) => submitAnswer(4, answer)}
          question={language.steppingstone2_exercise_c}
          hint={language.steppingstone2_exercise_c_hint}
        />
      </div>
    </LoadingScreen>
  );
};

export default Activity2_main_2;
