import React, { Fragment, useContext } from "react";

import { Switch, Route, NavLink, useLocation } from "react-router-dom";
import UserSettings from "./UserSettings/UserSettings.jsx";
import Map from "../Map/Map.jsx";
import Backpack from "../Backpack/Backpack.jsx";
import IconMap from "../../Components/icons/Map";
import IconBackPack from "../../Components/icons/BackPack";

import classes from "./UserComponents.module.scss";

import { LanguageContext } from "../../Store/LanguageStore";
import { RedirectContext } from "../../Store/RedirectTo.jsx";
import { AccountContext } from "../../Store/AccountStore.js";
import A2hsPopup from "../../Components/Add-To-Homescreen-Popup/A2HS-popup.jsx";

const UserComponents = (props) => {
  const [language] = useContext(LanguageContext);
  const [user, , loadingUser] = useContext(AccountContext);
  const [redirectTo, redirectAfterLogin] = useContext(RedirectContext);
  const { pathname } = useLocation();

  if (!loadingUser && !user.id) {
    console.log("redirecting user to login as not logged in");
    redirectAfterLogin(pathname);
    return <Fragment />;
  }

  return (
    <InnerUserComponents
      language={language}
      user={user}
      loadingUser={loadingUser}
      match={props.match}
    />
  );
};

const InnerUserComponents = React.memo(
  ({ language, user, loadingUser, match }) => {
    console.log("rerender user comps");

    return (
      <Fragment>
        <div className={`userNav ${classes.navWrapper}`}>
          <A2hsPopup />
          <nav className={classes.nav}>
            <NavLink
              className={classes.link}
              activeClassName={classes.active}
              to={`${match.url}/map`}
            >
              <IconMap className={classes.icon} />
              {language.map_menu}
            </NavLink>
            <NavLink
              className={classes.link}
              activeClassName={classes.active}
              to={`${match.url}/backpack`}
            >
              <IconBackPack className={classes.icon} />
              {language.backpack_menu}
            </NavLink>

            {/* <NavLink
            className={classes.link}
            activeClassName={classes.active}
            to={`${match.url}/kit`}
          >
            <IconFirstAid className={classes.icon} />
            First Aid (NT)
          </NavLink> */}
          </nav>
        </div>
        <div className="userContent">
          <Switch>
            <Route
              path={`${match.url}/map`}
              component={(props) => <Map {...props} />}
            />
            <Route
              path={`${match.url}/backpack`}
              component={(props) => <Backpack {...props} />}
            />
            {/* <Route
            path={`${match.url}/kit`}
            component={(props) => <FirstAidKit {...props} />}
          /> */}
            <Route
              path={`${match.url}/settings`}
              component={(props) => <UserSettings {...props} />}
            />
          </Switch>
        </div>
      </Fragment>
    );
  },
  (prev, next) => {
    if ((prev.user.id || next.user.id) && prev.user.id != next.user.id)
      return false;

    if (
      (prev.language.language || next.language.language) &&
      prev.language.language != next.language.language
    )
      return false;

    return true;
  }
);

export default UserComponents;
