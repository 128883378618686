import React, {useContext} from 'react';
import TextQuestion from '../../../Components/Questions/TextQuestion/TextQuestion';
import utils from "../../../utils/utils"

import Paragraph from '../../../Components/SanatizedComponents/Paragraph';
import Heading from '../../../Components/SanatizedComponents/Heading';
import Quote from '../../../Components/SanatizedComponents/Quote';
import UnorderedList from '../../../Components/SanatizedComponents/UnorderedList';
import {LanguageContext} from '../../../Store/LanguageStore'

const Activity7_main_3 = ({attempt}) => {
    const [language] = useContext(LanguageContext);
    const task_id = 7;


    return (
        <div>
            <Heading text={language.steppingstone7_statement1} level="h2"/>
            <Paragraph text={language.steppingstone7_paragraph5}/>
            <UnorderedList items={language.steppingstone7_paragraph5_bullets1}/>
            <Paragraph text={language.steppingstone7_paragraph6}/>
            
        </div>
    );
}

export default Activity7_main_3;
