import React, { useContext } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Activity1_intro from "./Activity1_intro";
import WHO5 from "../../../Components/WHO5/WHO5";
import AudioWithText from "../../../Components/AudioPlayer/AudioWithText";
import Activity1_questions_1 from "./Activity1_questions_1";
import Activity1_questions_2 from "./Activity1_questions_2";
import Activity1_questions_3 from "./Activity1_questions_3";
import Activity1_questions_4 from "./Activity1_questions_4";
import Activity1_questions_5 from "./Activity1_questions_5";

import ActivityAssessment from "../../../Pages/ActivityAssessment/ActivityAssessment";
import ActivityWrapper from "../ActivityWrapper";

import { LanguageContext } from "../../../Store/LanguageStore";
import Ticket from "../../../Components/icons/Ticket";

import Heading from "../../../Components/SanatizedComponents/Heading";
import Paragraph from "../../../Components/SanatizedComponents/Paragraph";
import classes from "../Activity.module.scss";
import WHO5Display from "../../../Components/WHO5/WHO5Display";
import { AccountContext } from "../../../Store/AccountStore";

const Activity1 = (props) => {
  const [language] = useContext(LanguageContext);
  const [user] = useContext(AccountContext);

  const { match, attempt } = props;
  const task_id = 1;
  const pages = [
    { link: "/introduction", component: () => <Activity1_intro /> },
    {
      link: "/who",
      component: () => <WHO5 task_id={task_id} attempt={attempt} />,
    },
    {
      link: "/who/display",
      component: () => <WHO5Display task_id={task_id} attempt={attempt} />,
    },
    {
      link: "/audio/1",
      component: () => (
        <AudioWithText
          title={language.steppingstone1_meditation1_title}
          text={language.steppingstone1_meditation1_description}
          audio={
            language.steppingstone1_meditation1_audio[user.gender || "Female"]
          }
        />
      ),
    },
    {
      link: "/activity1",
      component: () => <Activity1_questions_1 attempt={attempt} />,
    },
    {
      link: "/activity2",
      component: () => <Activity1_questions_2 attempt={attempt} />,
    },
    {
      link: "/activity3",
      component: () => <Activity1_questions_3 attempt={attempt} />,
    },
    {
      link: "/activity4",
      component: () => <Activity1_questions_4 attempt={attempt} />,
    },
    {
      link: "/activity5",
      component: () => <Activity1_questions_5 attempt={attempt} />,
    },
    {
      link: "/audio/2",
      component: () => (
        <AudioWithText
          title={language.steppingstone1_meditation2_title}
          text={language.steppingstone1_meditation2_description}
          audio={
            language.steppingstone1_meditation2_audio[user.gender || "Female"]
          }
        />
      ),
    },
    {
      link: "/activityassessment",
      component: () => (
        <ActivityAssessment task_id={task_id} attempt={attempt} />
      ),
    },
  ];

  return (
    <div>
      <div className={classes.headerWrapper}>
        <header className={classes.header}>
          <Ticket base className={classes.icon} />
          <div>
            <Heading
              className={classes.title}
              text={language.steppingstone1_title}
            />
            <Paragraph text={language.steppingstone1_aim} />
          </div>
        </header>
      </div>
      <nav className={classes.breadcrumbs}>
        <Link to="/user/map">{language.map_menu}</Link>
        {" > "}
        <span
          dangerouslySetInnerHTML={{ __html: language.steppingstone1_title }}
        ></span>
      </nav>
      <ActivityWrapper {...props} pages={pages} task_id={task_id} />
    </div>
  );
};

export default Activity1;
