import React, {useContext} from 'react';

import {LanguageContext} from '../../../Store/LanguageStore'
import Quote from '../../../Components/SanatizedComponents/Quote'
import Paragraph from '../../../Components/SanatizedComponents/Paragraph'

const Activity4_intro = () => {
    const [language] = useContext(LanguageContext);
    return (
        <div>
            <Paragraph text={language.steppingstone4_paragraph1} />
            <Paragraph text={language.steppingstone4_paragraph2} />
            <Quote text={language.steppingstone4_quote1} />
        </div>
    );
}

export default Activity4_intro;
