import React, {useRef, useState} from 'react';
import Paragraph from '../../../../Components/SanatizedComponents/Paragraph';
import TextField from '../../../../Components/Forms/TextField';
import BatchSearchResults from './BatchSearchResults';
import Axios from 'axios';
import handleError from '../../../../lib/handleError';

const BatchSearch = () => {

    const inputRef = useRef()
    const [result, setresult] = useState(undefined);


    const search = async () => {

        let queries = inputRef.current.value.trim().split(/[\s\t\n]*,[\s\t\n]*/g);
        if(queries.length == 1 && queries[0] == ""){
            queries = [];
        }
        if(queries[queries.length-1] == ""){
            queries.pop();
        }

        try{
            let _result = await Axios.post('/admin/users/search',{search_queries: queries});
            setresult(_result.data);
            console.log(_result)
        }catch(e){
            handleError(e, true);
        }

    }

    return (
        <div>
            <Paragraph text="Enter the user emails or qualtrics ids seperated by commas"/>
            <textarea
                ref={inputRef}
                style={{height: 200, fontSize: 16}}
            />
            <button  onClick={search}>
                Search
            </button>
            <BatchSearchResults results={result}/>
            
        </div>
    );
}

export default BatchSearch;
