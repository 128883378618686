import React, { useRef, useState } from "react";
import { Draggable } from "react-beautiful-dnd";

const DraggableItemWrapper = ({ id, index, children }) => {
  return (
    <Draggable draggableId={id.toString()} index={index}>
      {(provided, snapshot) => (
        <li
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {React.cloneElement(children,{isDragging:snapshot.isDragging})}
        </li>
      )}
    </Draggable>
  );
};

export default DraggableItemWrapper;
