import React, {useEffect, useState, useContext} from 'react';

import Heading from '../../../Components/SanatizedComponents/Heading';
import Paragraph from '../../../Components/SanatizedComponents/Paragraph';
import UnorderedList from '../../../Components/SanatizedComponents/UnorderedList';
import {LanguageContext} from '../../../Store/LanguageStore'

const task_id = 9;
const Activity9_main_1 = ({attempt}) => {
    const [language] = useContext(LanguageContext);
    

    return (

        <div>

            <Paragraph text={language.steppingstone9_statement2}/>
            <Paragraph text={language.steppingstone9_paragraph4}/>
            <UnorderedList items={[
                language.steppingstone9_paragraph4_list1,
                language.steppingstone9_paragraph4_list2
            ]}/>
            <Paragraph text={language.steppingstone9_paragraph4_end}/>
        </div>

    );
}

export default Activity9_main_1;
