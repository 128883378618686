import React, {useState} from 'react';
import classes from "./MultipleChoiceQuestion.module.scss";
import Checkbox from '../Checkbox/Checkbox';
import DOMPurify from 'dompurify';

const MultipleChoiceQuestion = ({question, answers, submit, checked}) => {

    

    const clickBox = (index) => {
        let _checked = [...checked];
        _checked[index] = !_checked[index];
        if(submit)
            submit(_checked);
    }

    

    return (
        <div className={classes.wrapper}>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question) }} />
            <div className={classes.answers}>
                {
                    answers.map((answer, index)=>{
                        return <Checkbox key={index} text={answer} checked={checked[index]} onCheck={()=>clickBox(index)}/>
                    })
                }
            </div>
        </div>
    );
}

export default MultipleChoiceQuestion;
