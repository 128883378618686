import React, {useContext} from 'react';
import {LanguageContext} from '../../../Store/LanguageStore';
import Paragraph from '../../../Components/SanatizedComponents/Paragraph'

const Activity1_into = () => {

    const [language] = useContext(LanguageContext);

    return (
        <div>
            <Paragraph text={language.steppingstone1_paragraph1}/>
        </div>
    );
}

export default Activity1_into;
